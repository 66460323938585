import React from 'react'
import Profile from '../Profile/Profile'
import '../Profile/Profile.css'


function Dashboard() {
  return (
    <div>      
     <Profile/> 
    </div>
  )
}

export default Dashboard
