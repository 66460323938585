import React from 'react';
import About from '../About/AboutMain/About';
import Contact from '../Contact/ContactMain/Contact';
import OurPartners from '../HappyClient/HappyClient';
import Header from '../Header/HeaderMain/Header';
import Services from '../Services/ServiceMain/Services';
import CalciForHome from './Calculator/CalciMain/CalciForHome';
import Footer from '../Footer/FooterMain/Footer';

const Home = () => {
    return (
        <>
        <main style={{paddingTop:'100px',margin:'0 1rem',overflowX:'hidden'}}>
            <Header/> 
            <About/>
            <Services/>
          <CalciForHome/>
          <br/>
          <OurPartners/>
            <Contact/> 
        </main>
        <Footer/>
          </>
    );
};

export default Home;