import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import Img from "../../../../Assets/loan1921.webp";

const BlogNav = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Navbar
      className={`navbar navbar-expand-lg navbar-light ${
        isSticky ? "navStyleFor_Blog" : "navDefaultForm_Blog"
      }`}
      expand="lg"
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
          <img src={Img} className="brnIcon" alt="blog" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mainNavFor_Blog" activeKey="/home">
            <Nav.Item className="social-iconFor-Blog">
              <Nav.Link href="https://instagram.com/uponly.technologies?igshid=YmMyMTA2M2Y=">
                <FontAwesomeIcon icon={faInstagram} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="social-iconFor-Blog">
              <Nav.Link href="https://www.facebook.com/profile.php?id=100090455449775">
                <FontAwesomeIcon icon={faFacebook} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="social-iconFor-Blog">
              <Nav.Link href="https://www.linkedin.com/company/uponly-technologies/?viewAsMember=true">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                className="nav-link"
                onClick={scrollTop}
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact" className="nav-link">
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/eligibility/Offers">
                <button className="applyBtnblog">APPLY NOW</button>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BlogNav;
