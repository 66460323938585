import * as React from "react";
import  Form  from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { FaBuilding } from "react-icons/fa";
import Fade from "react-reveal/Fade";
 import InputAdornment from "@mui/material/InputAdornment";

import "../index.css";
import { Input } from "@mui/material";
const NameofBuilder = ({
  prevStep,
  nextStep,
  handleFormData,
  values: value,
  right
}) => {
  // const options = ["Home", "Loan", "Data"];
  const { handleSubmit,values,handleChange } = useFormik({
    initialValues: { ...value },

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        nameofBuilder: formValues.nameofBuilder,
      });
      nextStep();
    },
  });

  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)"  }}>
    <Fade duration={1000} right={right} left={!right}>
  <Box>
      <h4 style={{ fontFamily: "poppins"}}>
        Name of builder & project(optional)
      </h4>
      <div style={{ marginTop: "20px",fontFamily:'poppins' }}>
        <Form>
          <Form.Group>
            <Input
              fullWidth
              style={{
                fontSize: "15px",
                fontFamily: "poppins",
                padding: "0.6rem 0.6rem",
              }}
              startAdornment={
                <InputAdornment position="start">
                   <FaBuilding className="formicon" />
                </InputAdornment>
              } 
            
              id="custom-input-demo"
              placeholder="type here.."
              name="nameofBuilder"
              value={values.nameofBuilder}
              onChange={handleChange}
              
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <button type="button" className="btn-prevforbuilder" onClick={prevStep}>
              &#8592; Previous
            </button>

            <button
              className="btn-oneforcity"
              type="submit"
              onClick={handleSubmit}
            >
              Next &#8594;
            </button>
          </div>
        </Form>
      </div>
    </Box>
    </Fade>
  
    //  </motion.div>
  );
};
export default NameofBuilder;
