import React, { useEffect } from "react";
import  Form  from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Img1 from "../../../../../../../../Assets/Bangalore.png";
import Img1forphone from "../../../../../../../../Assets/bangalore.webp";
import Img2 from "../../../../../../../../Assets/chennail.webp";
import Img2forphone from "../../../../../../../../Assets/chennai.webp";
import Img3 from "../../../../../../../../Assets/Mumbai.png";
import Img3forphone from "../../../../../../../../Assets/bombay.png";
import Img4 from "../../../../../../../../Assets/new_delhi.webp";
import Img4forphone from "../../../../../../../../Assets/new-delhi.webp";
import Img5 from "../../../../../../../../Assets/Others.webp";

import { useFormik } from "formik";
import Select from 'react-select'
import Fade from "react-reveal/Fade";
import "../index.css";
import axios from "axios";
import { Propertyvalidate } from "../../../../../Forms/FormsStructure/Schemas";


const PropertyBasedCity = ({
  nextStep,
  prevStep,
  values: value,
  handleFormData,
  right
}) => {
  const data = [];
 
  useEffect(()=>{
    axios.get('https://www.loanloom.com/loan_phpForm/city_api.php').then((res)=>{
    res.data.map(({city }) => {
      if(!data.includes({ label: city, value: city }))
     data.push({ label: city, value: city });
    });
   })
  })
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: Propertyvalidate,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        propertyBasedCity: formValues.propertyBasedCity,
      });
      nextStep();
    },
  });
  return (
   // <motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
   <Fade duration={1000} right={right} left={!right}>
     <Box>
        <h4 style={{ fontFamily: "poppins" }}>City in which property based?</h4>
        <div style={{ marginTop: "20px" }}>
          <Form>
            <label>
              <img src={Img1} className="imagesforcity" alt="Property" />
              <img src={Img1forphone} className="imagesforcityphone" alt="Property" />
              <input
                type="radio"
                name="propertyBasedCity"
                value="bangalore"
                readOnly={values.propertyBasedCity === "bangalore"}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h6 className="type">Bangalore</h6>
            </label>

            <label>
              <img src={Img2} className="imagesforcity" alt="Property" />
              <img src={Img2forphone} className="imagesforcityphone" alt="Property" />
              <input
                type="radio"
                name="propertyBasedCity"
                value="chennai"
                readOnly={values.propertyBasedCity === "chennai"}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h6 className="type">Chennai</h6>
            </label>
            <label>
              <img src={Img3} className="imagesforcity" alt="Property" />
              <img src={Img3forphone} className="imagesforcityphone" alt="Property" />
              <input
                type="radio"
                name="propertyBasedCity"
                value="mumbai"
                readOnly={values.propertyBasedCity === "mumbai"}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h6 className="type">Mumbai</h6>
            </label>
            <label>
              <img src={Img4} className="imagesforcity" alt="Property" />
              <img src={Img4forphone} className="imagesforcityphone" alt="Property" />
              <input
                type="radio"
                name="propertyBasedCity"
                value="delhi"
                readOnly={values.propertyBasedCity === "delhi"}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <h6 className="type">New Delhi</h6>
            </label>
            <label>
              <img src={Img5} className="imagesforothercity" alt="Property" />
              <h6 className="type">Other city</h6>
              <Select
                isClearable
                className="othercitySelect"
                name="propertyBasedCity"
                placeholder="select city"
                options={data}
                onChange={(option) => {
                  setFieldValue("propertyBasedCity", option?.value);
                }}
              />
            </label>
            {errors.propertyBasedCity ? (
              <p
                style={{
                  color: "red",
                  fontSize: "14.5px",
                  fontFamily: "Comic Sans",
                  marginTop: "12px",
                }}
              >
                {errors.propertyBasedCity}
              </p>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <button type="button" className="btn-prevforcity" onClick={prevStep}>
                &#8592; Previous
              </button>

              <button
                className="btn-oneforcity"
                type="submit"
                onClick={handleSubmit}
              >
                Next &#8594;
              </button>
            </div>
          </Form>
        </div>
      </Box>
      </Fade>
   // </motion.div>
  );
};

export default PropertyBasedCity;
