import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Input from "@mui/material/Input/Input";
import Box from "@mui/material/Box";
import { MdOutlineMail } from "react-icons/md";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EducationRegex } from "../../../Forms/FormsStructure/Schemas";

const SecondStep = ({ nextStep, handleFormData, values: value, right, mobile }) => {
  const data = [];

  useEffect(() => {
    axios.get("https://www.loanloom.com/loan_phpForm/country_api.php").then((res) => {
      res.data.map(({ country }) => {
        if (!data.includes({ label: country, value: country }))
          data.push({ label: country, value: country });
      });
    });
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#white" : "#white",
      color: state.isSelected ? "black" : "black",
      padding: 10,
      "&:hover": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 10,
      border: "none",
      borderBottom: "1px solid #989696",
      fontSize: 15,
      "&:hover": {
        borderColor: "#989696",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontSize: 16,
      fontFamily: "poppins",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#989696",
      fontSize: 16,
      fontFamily: "poppins",
    }),
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: EducationRegex,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        email: formValues.email,
        countryStudy: formValues.countryStudy,
      });

      axios
        .post(`https://www.loanloom.com/loan_phpForm/educationloan_step2.php/${mobile}`, formValues)
        .then((res) => console.log(res.data));

      nextStep();
    },
  });

  const navigate=useNavigate();

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <MdOutlineMail className="formicon" />
                    </InputAdornment>
                  }
                  value={values.email}
                  onBlur={handleBlur}
                  name="email"
                  type="text"
                  placeholder="Your email"
                  onChange={handleChange}
                />
                {errors.email && touched.email ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.email}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} sm={12} md={6} className="mt-3">
              <Select
                fullWidth
                variant="standard"
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem",
                }}
                styles={customStyles}
                id="other"
                isClearable
                options={data}
                name="countryStudy"
                onChange={(option) => {
                  setFieldValue("countryStudy", option?.value);
                }}
                placeholder="country of study"
              />
              {errors.countryStudy && touched.countryStudy ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "12px",
                  }}
                >
                  {errors.countryStudy}
                </p>
              ) : null}
            </Col>
          </Row>
          {/* onClick={handleSubmit} */}
          <button className="btn-one1" type="submit" onClick={()=>navigate('/Educationloan_Thankyou')} >
            Submit &#8594;
          </button>
        </Form>
      </Box>
    </Fade>
  );
};

export default SecondStep;
