import * as React from "react";
import { styled } from "@mui/material/styles";
import  Form  from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import { Slider } from "@mui/material";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";
import "../index.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { HomeValidate } from "../../../../../Forms/FormsStructure/Schemas";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 20000000, label: "> ₹ 2cr" },
  ],
};

const CostofHome = ({handleFormData,values:value,mobile,nextStep,right}) => {
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: HomeValidate,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        costofplot: formValues.costofplot,
      });
      axios.post(`https://www.loanloom.com/loan_phpForm//homeloan_step4.php/${mobile}`,formValues).then((res)=>console.log(res.data))
      axios.post(`https://www.loanloom.com/loan_phpForm/offers_api_step2.php/${mobile}`,formValues).then((res)=>console.log(res.data));
     nextStep();
    },
  });

  const navigate=useNavigate();
  
  return (
   <Fade duration={1000} right={right} left={!right}>
      <Form>
        <Box style={{ marginTop:"30px" }}>
          <h4 style={{ fontFamily: "poppins" }}>Cost of home/flat</h4>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "20px",fontFamily:'poppins' }}
          >
            <Grid item xs={12}>
              <strong>&#8377; </strong>
              <Input
                name="costofplot"
                style={{fontFamily:'poppins'}}
                size="small"
                value={values.costofplot}
                onChange={(e) => setFieldValue("costofplot", e.target.value)}
                onBlur={handleBlur}
                inputProps={{
                  step: 1000,
                  min: 0,
                  max: 20000000,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }} p={1}>
              <Slider
               sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
                marks={IncomeSlider.marksAmt}
                value={values.costofplot}
                max={20000000}
                onChange={(e, newValue) =>
                  setFieldValue("costofplot", newValue)
                }
                aria-labelledby="input-slider"
              ></Slider>
            </Grid>
          </Grid>
          {errors.costofplot ? (
            <p
              style={{
                color: "red",
                fontSize: "14.5px",
                fontFamily: "Comic Sans",
              }}
            >
              {errors.costofplot}
            </p>
          ) : null}
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >

{/* onClick={handleSubmit} */}
          <button type="submit"
            className="btn-oneforcity" onClick={()=>navigate('/Homeloan_Thankyou')}>
            Submit
          </button>
        </div>
      </Form>
      </Fade>
  );
};
export default CostofHome;
