import React from "react";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import { BussinessRegex } from "../../../Forms/FormsStructure/Schemas";

const options = [
  { value: "3 Lakhs-15 Lakhs", label: "3 Lakhs-15 Lakhs" },
  { value: "15 Lakhs-50 Lakhs", label: "15 Lakhs-50 Lakhs" },
  { value: "50 Lakhs-1 Cr", label: "50 Lakhs-1 Cr" },
  { value: "1 Cr-3 Cr", label: "1 Cr-3 Cr" },
  { value: "3Cr-Above", label: "3Cr-Above" }
];

const options1 = [
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Trading", label: "Trading" },
  { value: "Services", label: "Services" },
  { value: "Retail", label: "Retail" },
  { value: "Others", label: "Others" }
];

const BussinessStep1 = ({ nextStep, handleFormData, values: value, right }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#white" : "#white",
      color: state.isSelected ? "black" : "black",
      padding: 10,
      "&:hover": {
        backgroundColor: "white",
        color: "black"
      }
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 10,
      boxShadow: "none",
      border: "none",
      borderBottom: "1px solid #989696",
      fontSize: 15,
      "&:hover": {
        borderColor: "#989696"
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontSize: 16,
      fontFamily: "poppins"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#989696",
      fontSize: 15,
      fontFamily: "poppins"
    })
  };

  const {
    values,
    errors,
    handleSubmit,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: { ...value },
    validationSchema: BussinessRegex,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        companyTurnOver: formValues.companyTurnOver,
        natureOfBussiness: formValues.natureOfBussiness
      });

      nextStep();
    }
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col lg={6} md={6} className="mt-3">
              <Select
                fullWidth
                variant="standard"
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem"
                }}
                styles={customStyles}
                id="other"
                isClearable
                options={options}
                name="companyTurnOver"
                onChange={(option) => {
                  setFieldValue("companyTurnOver", option?.value);
                }}
                placeholder="Company Turnover"
              />
              {errors.companyTurnOver && touched.companyTurnOver ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "12px"
                  }}
                >
                  {errors.companyTurnOver}
                </p>
              ) : null}
            </Col>
            <Col lg={6} sm={12} md={6} className="mt-3">
              <Select
                fullWidth
                variant="standard"
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem"
                }}
                styles={customStyles}
                id="other"
                isClearable
                options={options1}
                name="natureOfBussiness"
                onChange={(option) => {
                  setFieldValue("natureOfBussiness", option?.value);
                }}
                placeholder="Nature of Business"
              />
              {errors.natureOfBussiness && touched.natureOfBussiness ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "12px"
                  }}
                >
                  {errors.natureOfBussiness}
                </p>
              ) : null}
            </Col>
          </Row>
          <button className="btn-one1" type="submit" onClick={handleSubmit}>
            Next &#8594;
          </button>
        </Form>
      </Box>
    </Fade>
  );
};

export default BussinessStep1;
