import { Link } from "react-router-dom";

const Post = ({ post }) => {
  const scrollTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });

  return (
    <div className="post">
      {post.photo && <img className="postImg" src={post.photo} alt="post" />}

      <div className="postInfo">
        <div className="postCats">
          <Link to="/eligibility/Offers" className="link" onClick={scrollTop}>
            <span className="postCat">Get the best offers for you here</span>
          </Link>
        </div>

        <span className="postTitle">{post.title} Loan</span>

        <span className="postDate">
          {new Date(post.createdAt).toDateString()}
        </span>
      </div>

      <p className="postDesc">{post.desc}</p>
      <Link to={`/post/${post.id}`} style={{ margin: "auto" }} className="link">
        <button className="btnblog" onClick={scrollTop}>
          Read Full
        </button>
      </Link>
    </div>
  );
};

export default Post;
