import React from "react";
import { Form } from "react-bootstrap";
import { Input } from "@mui/material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { ImMobile } from "react-icons/im";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";
import { PancardRegx } from "../../../Forms/FormsStructure/Schemas";

const Pancard = ({
  nextStep,
  handleFormData,
  prevStep,
  values: value,
  right,
}) => {
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: { ...value },
      validationSchema: PancardRegx,

      onSubmit: (formValues) => {
        handleFormData({
          ...value,
          pancard_No: formValues.pancard_No,
        });
        nextStep();
      },
    });
  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <h4 style={{ fontFamily: "poppins" }}>Your pancard number?</h4>
        <div style={{ marginTop: "20px" }}>
          <Form>
            <Form.Group className="mb-3">
              <Input
                // inputProps={{style: {textTransform: 'uppercase'}}} 
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <ImMobile className="formicon" alt="" />
                  </InputAdornment>
                }
                name="pancard_No"
                value={values.pancard_No}
                onBlur={handleBlur}
                onChange={handleChange}
                type="otp"
                placeholder="Your Pancard No."
                autoComplete="off"
              />
              {errors.pancard_No && touched.pancard_No ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "16px",
                  }}
                >
                  {errors.pancard_No}
                </p>
              ) : null}
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button type="button" className="btn-prev1" onClick={prevStep}>
                &#8592; Previous
              </button>

              <button className="btn-one1" type="submit" onClick={handleSubmit}>
                Next &#8594;
              </button>
            </div>
          </Form>
        </div>
      </Box>
    </Fade>
  );
};

export default Pancard;
