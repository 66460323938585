import React from "react";
import { styled } from "@mui/material/styles";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Slider } from "@mui/material";
import MuiInput from "@mui/material/Input";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 10000000, label: "> ₹ 1cr" },
  ],
};

const Profit = ({ nextStep, handleFormData, values: value, prevStep, right }) => {
  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } =
    useFormik({
      initialValues: { ...value },

      onSubmit: (formValues) => {
        handleFormData({
          ...value,
          profit: formValues.profit,
        });
        nextStep();
      },
    });

  return (
    <>
      <Fade duration={1000} right={right} left={!right}>
        <Form>
          <Box>
            <h4 style={{ fontFamily: "poppins" }}>Latest year's profit after tax</h4>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "20px", fontFamily: "poppins" }}
            >
              <Grid item xs={12}>
                <strong>&#8377; </strong>
                <Input
                  value={values.profit}
                  style={{ fontFamily: "poppins" }}
                  size="small"
                  onChange={(e) => setFieldValue("profit", e.target.value)}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 100,
                    min: 0,
                    max: 10000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                {errors.Income && touched.Income ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "poppins",
                      marginTop: "20px",
                    }}
                  >
                    {errors.Income}
                  </p>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                p={1}
                style={{ marginTop: "10px", fontFamily: "poppins" }}
              >
                <Slider
                  sx={{
                    color: '#480082',
                    '& .MuiSlider-thumb': {
                      border: "2px solid #F68712",
                      background: "#FFF",
                    },
                    '& .MuiSlider-rail':{
                      backgroundColor: "#bfbfbf"
                    }
                  }}
                  value={values.profit || 0}
                  marks={IncomeSlider.marksAmt}
                  max={10000000}
                  onChange={(e, newValue) => setFieldValue("profit", newValue)}
                  aria-labelledby="input-slider"
                ></Slider>
              </Grid>
            </Grid>
          </Box>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button type="button" className="btn-prev1" onClick={prevStep}>
              &#8592; Previous
            </button>
            <button className="btn-one1" type="submit" onClick={handleSubmit}>
              Next &#8594;
            </button>
          </div>
        </Form>
      </Fade>
    </>
  );
};

export default Profit;
