import NotFoundImg from '../../Assets/404.svg';

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
        <img src={`${NotFoundImg}`} alt="notfounf" className='notfoundImage'/>
    </div>
    )
}

export default NotFound
