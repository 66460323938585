import React from "react";
import Col  from "react-bootstrap/Col";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Latest_Profit from "./Latest_Profit";
import Pincode from "../Salaried/Pincode";
import App from "../Salaried/HowToUseLoan/App";

const SelfEmployed = ({prevStep:prevPreviousStep,handleFormData:setFormData,values:formData,mobile,right:selfEmployeeRight}) => {
  //state for steps
  const [step, setstep] = useState(1);
  const [right, setRight] = useState(true);

  const nextStep = () => {
    setRight(true)
    setstep(step + 1);
  };

  const prevStep = () => {
    setRight(false)
    if(step === 1){
      prevPreviousStep();
    }
    else{
    setstep(step - 1);
    }
  };

  switch (step) {
    case 1:
      return (
        <div>
          <Col md={{ span: 6, offset: 3 }}>
            <Latest_Profit
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={selfEmployeeRight}
            />
          </Col>
        </div>
      );
      case 2:
        return (
          <div>
            <Col md={{ span: 6, offset: 3 }}>
              <Pincode
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
            </Col>
          </div>
        );
        case 3:
        return (
          <div>
            <Col >
              <App
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
            </Col>
          </div>
        );


    default:
      return <div className="App"></div>;
  }
};

export default SelfEmployed;
