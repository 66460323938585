import React from "react";
import Box from "@mui/material/Box";
import Img1 from "../../../../../../../../Assets/male.png";
import Img2 from "../../../../../../../../Assets/female.png";
import Img3 from "../../../../../../../../Assets/transgenders.png";
import  Form  from "react-bootstrap/Form";
import Fade from "react-reveal/Fade";
import "../index.css";
import { useFormik } from "formik";
import { Gendervalidate } from "../../../../../Forms/FormsStructure/Schemas";


const GenderSel = ({ nextStep, handleFormData, prevStep, values: value,right }) => {
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: Gendervalidate,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        gender: formValues.gender,
      });
      nextStep();
    },
  });
  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <h4 style={{ fontFamily: "poppins"}}>My gender</h4>
        <div style={{ marginTop: "30px" }}>
          <Form>
            <label>
              <img src={Img1} className="imagesforgender" alt="Gender" />
              <input
                className="inputgender"
                type="radio"
                name="gender"
                value="male"
                defaultreadOnly={values.gender === "male"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <h6 className="typeforgender">Male</h6>
            </label>

            <label>
              <img src={Img2} className="imagesforgender" alt="Gender" />
              <input
                className="inputgender"
                type="radio"
                name="gender"
                value="female"
                defaultreadOnly={values.gender === "female"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <h6 className="typeforgender">Female</h6>
            </label>
            <label>
              <img src={Img3} className="imagesforgender" alt="Gender" />
              <input
                className="inputgender"
                type="radio"
                name="gender"
                value="other"
                defaultreadOnly={values.gender === "other"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <h6 className="typeforgender">Other</h6>
            </label>
            {errors.gender ? (
              <p
                style={{
                  color: "red",
                  fontSize: "14.5px",
                  fontFamily: "Comic Sans",
                  marginTop: "12px",
                }}
              >
                {errors.gender}
              </p>
            ) : null}

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <button type="button" className="btn-prevforcity" onClick={prevStep}>
                &#8592; Previous
              </button>

              <button
                className="btn-oneforcity"
                type="submit"
                onClick={handleSubmit}
              >
                Next &#8594;
              </button>
            </div>
          </Form>
        </div>
      </Box>
      </Fade>
   // </motion.div>
  );
};

export default GenderSel;
