import * as React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import Img from "../../../../../../Assets/sorry_img.webp";
import Home from "../../../Service/PersonalLoan/PersonalLoan/Offline/Home";
import { Link } from "react-router-dom";  
import './GatebackPL.css'
export default function GetBackForPL({ mobile ,
  handleFormData,
  values,
  right,}) {
  const [step, setStep] = useState(1);

  //function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
  };

  //function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <>
          <div className="sorryBg">
            <img src={Img} alt="SorryImg" className="sorryImg" />
            <p className="p11">
              Our representative will be right back with best offers available
              offline.{" "}
            </p>
            <p className="p12">
              No pre-approved online offers present right now.{" "}
            </p>
            <Link to='/' style={{textDecoration :"none"}}>
            <button className="btnsry">
              Get offline offers
            </button>
            </Link>
            
          </div>
        </>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col>
                <Home mobile={mobile} nextStep={nextStep} prevStep={prevStep}  handleFormData={handleFormData}
                  values={values} />
              </Col>
            </Row>
          </Container>
        </div>
      );

    default:
      return <div className="formdiv"></div>;
  }
}
