import { Grid } from "@mui/material";
import img from "../../../../../../Assets/bus8.webp";
import Bussinessben from "../../../../../../Assets/BenOfLoan.jpg";
import BussinessElii from "../../../../../../Assets/BussinessEli.jpg";
import BussinessDocRequired from "../../../../../../Assets/BussinessDocRequire.jpg";
import BussinessApply from "../../../../../../Assets/ApplyBussinessImage.jpg";
import "./Bussiness_loan.css";

const Business_loan = () => {
  return (
    <div className="containerforBussiness">
      <Grid container padding={0}>
        <Grid item lg={6} xs={12} md={6}>
          <h1 className="business_Heading">Business loan</h1>
          <p className="business_paragraph">
            A company's expansion is vital. A company cannot thrive in this
            intense environment unless its earnings as well as profitability
            increase. There are several methods for increasing income, ranging
            from new ideas to effective marketing. Therefore, a large sum of
            money is required to accomplish all of this. It can be possible with
            the help of <b>Loanloom, delivers the best effortless business loan</b> services to its clients. In India, there are
            numerous types of business loans accessible, all of which are
            tailored to unique circumstances. The primary purpose seems to be to
            address the urgent demands of a growing business. To satisfy a
            corporation's business demands, most monetary organizations offer
            terms as well as flexible financing. Business loans are also known
            as commercial lending. Single owners, privately owned enterprises,
            partnerships corporations, self-employed individuals, as well as
            shopkeepers are all eligible for such financing. Trust <b>LoanLoom,
            delivers the best effortless business loan</b> solution for your
            company's success.
          </p>
        </Grid>
        <Grid item lg={6} xs={12} md={6}>
          <img src={img} className="businessloanimg" alt="business" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={4} md={4}>
          <img
            className="BussinessBenefitsImage"
            alt="logo"
            src={Bussinessben}
          />
          <h2 className="BussinessBenefitTitle">
            <b>Benefits Of Business loan</b>
          </h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="BussinessBenefitParagraph">Better flexibility.</li>
            <li className="BussinessBenefitParagraph">
              Loan loom provides a flexible repayment tenure period.
            </li>
            <li className="BussinessBenefitParagraph">
              It provides reasonable or lower interest rates.
            </li>
            <li className="BussinessBenefitParagraph">
              <b>Loanloom, delivers the best effortless business loan</b>{" "}
              beneficial to the company's progress.
            </li>
            <li className="BussinessBenefitParagraph">
              Rapid disbursement of money.
            </li>
            <li className="BussinessBenefitParagraph">
              less time-consuming.
            </li>
          </ul>
        </Grid>

        <Grid item lg={4} md={4} className="BussinessEliGrid">
          <img
            className="businessEligibilityImage"
            alt="logo"
            src={BussinessElii}
          />
          <h2 className="BussinessBenefitTitle">
            <b>Eligibility Criteria for Business Loan</b>
          </h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="BussinessBenefitParagraph">Age limits up to 18 years.</li>
            <li className="BussinessBenefitParagraph">
              Salaried, self-employed, sole proprietor or partnership firm can
              eligible for applying for the loan.
            </li>
            <li className="BussinessBenefitParagraph">
              Citizenship of India is mandatory.
            </li>
            <li className="BussinessBenefitParagraph">
              At least one year of business experience.
            </li>
            <li className="BussinessBenefitParagraph">
              Annual turnover of the business for one year is at least 12 lakhs.
            </li>
          </ul>
        </Grid>

        <Grid item lg={4} md={4} className="BussinessRequiredGrid">
          <img
            className="BussinessRequiredImage"
            alt="logo"
            src={BussinessDocRequired}
          />
          <h2 className="BussinessBenefitTitle">
            <b>Required documents for the business loan</b>
          </h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="BussinessBenefitParagraph">
              PAN card or Adhar card is mandatory.
            </li>
            <li className="BussinessBenefitParagraph">
              Last six-month banking details.
            </li>
            <li className="BussinessBenefitParagraph">
              Voter ID or Passport size photo.
            </li>
            <li className="BussinessBenefitParagraph">
              Business details or another certificate of business incorporation.
            </li>
            <li className="BussinessBenefitParagraph">
              Salary slips of the last 3 months of a salaried person or sole
              proprietor.
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={6} md={6}>
          <img
            className="BussinessApplyImagee"
            alt="logo"
            src={BussinessApply}
          />
        </Grid>

        <Grid item lg={6} md={6}>
          <h2 className="BussinessApplyTitle">
            <b>How to apply for a loanloom business loan?</b>
          </h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="BussinessBenefitParagraph">
              Go to the website of loan Lom.
            </li>
            <li className="BussinessBenefitParagraph">
              Click on apply button{" "}
              <a href="https://loanloom.com/">
                (https://loanloom.com/)
              </a>
            </li>
            <li className="BussinessBenefitParagraph">
              Submit your phone number or verified the OTP.
            </li>
            <li className="BussinessBenefitParagraph">
              After that fill in the personal details or attached the required
              documents for the loan.
            </li>
            <li className="BussinessBenefitParagraph">
              After the click on proceed button and select the repayment tenure
              period of the loan.
            </li>
            <li className="BussinessBenefitParagraph">At last, submit the form.</li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default Business_loan;
