import { Link } from "react-router-dom";  
import "../../Service/PersonalLoan/PersonalLoan/Offline/Home.css";
import Img from '../../../../../Assets/thank.webp'


function Thank(){
  return (
    <>
    <div className="">
    <img src={Img} alt='Thankimg' className="thnku_img" />
   <p className="thank_para">One of our team member will be get back in touch with you shortly</p>
   <Link to ='/' style={{textDecoration :"none"}}>
   <button className="btnthnku">Go to home</button>
   </Link>
   </div>
    </>
  );
};

export default Thank;