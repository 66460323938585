import { useState } from "react";
import { Box, Grid, Typography, Input } from "@mui/material";
import Slider from "@mui/material/Slider";
import { HomeSlider } from "../CalcisliderTabledata/Slider";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HomeCalculator = () => {
  // const iOSBoxShadow =
  //   "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

  const [principalAmount, setPrincipalAmount] = useState(2755000);
  const [interest, setInterest] = useState(7);
  const [duration, setDuration] = useState(147);
  const maxValue = 10000000;
  const intMax = 20;
  const maxDuration = 360;

  const intr = interest / 1200;
  const emi = duration
    ? Math.round(
        (principalAmount * intr) / (1 - Math.pow(1 / (1 + intr), duration))
      )
    : 0;
  const totalAmt = duration * emi;
  var TotalAmountOfcredit = Math.round(
    (emi / intr) * (1 - Math.pow(1 + intr, -duration))
  );
  const TotalAmountOfInterest = Math.round(totalAmt - TotalAmountOfcredit);
  const ChangeInput = (event) => {
    setPrincipalAmount(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };
  const COLORS = ["#F68712", " #480082"];

  const pieData = [
    {
      name: "Total Interest",
      value: TotalAmountOfInterest,
    },
    {
      name: "Amount",
      value: totalAmt,
    },
  ];
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (

        
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value}`}</label>
        </div>
      );
    }

    return null;
  };
  return (
    <div id="Calculator">
      <Link to="/">
       <span className="pageCloseBtnforCalci">
         <FontAwesomeIcon icon={faTimes} />
        </span>
     </Link>
      <Grid container mt={3}className="emidiv2">
        <Grid item md={6} xs={12} sm={true} >
          <Box>
            <Typography gutterBottom style={{fontFamily:"poppins"}}>Loan Amount</Typography>
            <Grid item className="calciInput">
              <strong>&#8377;</strong>
              <Input
                style={{
                  marginLeft: "2px",
                  paddingLeft: "20px",
                  fontSize: "14px",
                  fontFamily:"poppins"
                }}
                value={principalAmount}
                size="small"
                onChange={ChangeInput}
                inputProps={{
                  step: 50,
                  min: 0,
                  max: 10000000,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Slider
            sx={{
              color: '#480082',
              '& .MuiSlider-thumb': {
                border: "2px solid #F68712",
                background: "#FFF",
              },
              '& .MuiSlider-rail':{
                backgroundColor: "#bfbfbf"
              }
            }}
              aria-label="ios slider"
              value={typeof principalAmount === "number" ? principalAmount : 0}
              marks={HomeSlider.marksAmt}
              onChange={(event, vAmt) => {
                setPrincipalAmount(vAmt);
              }}
              defaultValue={principalAmount}
              max={maxValue}
              // valueLabelDisplay="on"
            />
          </Box>
          <Box>
            <Typography gutterBottom style={{fontFamily:"poppins"}}>Interest Rate %</Typography>
            <Grid item className="calciInput">
              <Input
                style={{
                  marginLeft: "2px",
                  paddingLeft: "20px",
                  fontSize: "14px",
                  fontFamily:"poppins"
                }}
                value={interest}
                size="small"
                onChange={(event) => {
                  setInterest(
                    event.target.value === "" ? "" : Number(event.target.value)
                  );
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 20,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
              <strong> % </strong>
            </Grid>
            <Slider
              sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
              aria-label="ios slider"
              value={typeof interest === "number" ? interest : 0}
              marks={HomeSlider.marksInt}
              onChange={(event, vInt) => {
                setInterest(vInt);
              }}
              defaultValue={interest}
              max={intMax}
              // valueLabelDisplay="on"
            />
          </Box>
          <Box>
            <Typography gutterBottom style={{fontFamily:"poppins"}}>Tenure (months)</Typography>
            <Grid item className="calciInput">
              <Input
                style={{
                  marginLeft: "2px",
                  paddingLeft: "30px",
                  fontSize: "14px",
                  fontFamily:"poppins"
                }}
                value={duration}
                size="small"
                onChange={(event) => {
                  setDuration(
                    event.target.value === "" ? "" : Number(event.target.value)
                  );
                }}
                inputProps={{
                  step: 5,
                  min: 0,
                  max: 360,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Slider
              sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
              aria-label="ios slider"
              value={typeof duration === "number" ? duration : 0}
              marks={HomeSlider.marksTenture}
              onChange={(event, vDur) => {
                setDuration(vDur);
              }}
              defaultValue={duration}
              max={maxDuration}
              // valueLabelDisplay="on"
            />
          </Box>
          <Link to ='/eligibility/Offers' style={{textDecoration:'none'}}>
          <button className="btnforemi">Apply Now</button>
          </Link>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <Grid item md={12} xs={12}>
            <Box>
              <PieChart
                width={340}
                height={340}
                style={{ margin: "auto" }}
                className="pie"
              >
                <Pie
                  data={pieData}
                  color="white"
                  dataKey="value"
                  nameKey="name"
                  cx="47%"
                  cy="50%"
                  outerRadius={75}
                  fill="#8884d8"
                  isAnimationActive={true}
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend/>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </Box>
          </Grid>
          <Grid container p={2} alignContent="center" justifyContent="center" align="center">  
        <Grid md={6} xs={12} mt={2} >
         <Typography gutterBottom className="tableHead" >EMI (Months):<span className="tablejs">&#8377;&nbsp;{emi}</span></Typography>
        </Grid>
        <Grid md={6} xs={12} mt={2}>
        <Typography gutterBottom className="tableHead">Total Interest:<span  className="tablejs">&#8377;&nbsp;{TotalAmountOfInterest}</span></Typography>
        </Grid>
        <Grid mt={2}><Typography gutterBottom className="tableHead"> (Loan Amount + Interest):<span  className="tablejs">&#8377;&nbsp;{totalAmt}</span></Typography></Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeCalculator;
