import React, { useState } from "react";
import Aadhar from "./Aadhar";
import Pancard from "./Pancard";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CurrentAddress from "./CurrentAddress";
import GetBackForPL from "./GetBackForPL";
export default function Index({ prevStep: prevPreviousStep,mobile,response,values:loan_type}) {
  const [right, setRight] = useState(true);
  const formattedJSON = JSON.stringify(loan_type)
// console.log(response);
  const [step, setstep] = useState(1);

  const [formData, setFormData] = useState({
    city: "",
    pancard_No: "",
    aadhar_No: "",
    loan_type:formattedJSON,
    name:"",
    locality:"",
    house_No:"",
    landmark:"",
    emp_type:"",
    company_Name:"",
    salary:"",
    Pincode:"",
    profit:"",
    loanAmount:"",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true);
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false);
    if (step === 1) {
      prevPreviousStep();
    } else {
      setstep(step - 1);
    }
  };
  switch (step) {
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 7, offset: 3 }} className="m-auto">
                <Pancard
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );

    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 7, offset: 3 }} className="m-auto">
                <Aadhar
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 3:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 7, offset: 3 }} className="m-auto">
                <CurrentAddress
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                  mobile={mobile}
                  response={response}
                />
              </Col>
            </Row>
          </Container>
        </div>
      
      );
      case 4:
      return (
        <div className="formdiv">
                <GetBackForPL
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                  mobile={mobile}
                />
        </div>
      );

    default:
      return <div className="formdiv"></div>;
  }
}
