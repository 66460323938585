import React from "react";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeIndex from "../Salaried/HomeIndex";



const Professional = ({prevStep,handleFormData:setFormData,values:formData,mobile,right:professionalRight}) => {
  //state for steps
  const [step, setstep] = useState(1);
;

  const nextStep = () => {
    setstep(step + 1);
  };

  switch (step) {
    case 1:
      return (
       // <motion.div initial={{ x: "100%" }} animate={{ x: "calc(20vw - 24%)" }}>
          <Col >
            <HomeIndex
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              mobile={mobile}
              right={professionalRight}
            />
          </Col>
        //</motion.div>
      );

    default:
      return <div className="App"></div>;
  }
};

export default Professional;
