import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Input from "@mui/material/Input/Input";
import Box from "@mui/material/Box";
import { IoAccessibilityOutline } from "react-icons/io5";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik  } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import { personalInfoTwo } from "./Schemas";
import { useParams } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import axios from "axios";

const PersonalTwo = ({ nextStep, handleFormData, values: value, right }) => {
  const { type } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://www.loanloom.com/loan_phpForm/city_api.php")
      .then((res) => {
        const cities = res.data.map(({ city }) => ({
          label: city,
          value: city,
        }));
        setData(cities);
      });
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#white" : "#white",
      color: state.isSelected ? "black" : "black",
      padding: 10,
      "&:hover": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 10,
      border: "none",
      boxShadow: "none",
      borderBottom: "1px solid #989696",
      fontSize: 15,
      "&:hover": {
        borderColor: "#989696",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontSize: 16,
      fontFamily: "poppins",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#989696",
      fontSize: 15,
      fontFamily: "poppins",
    }),
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: personalInfoTwo,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        age: formValues.age,
        city: formValues.city,
      });

      const firstApI = {
        fullName: formValues.fullName,
        mobile: formValues.mobile,
        city: formValues.city,
        age: formValues.age,
        type: type,
      };

      const secondApI = {
        full_name: formValues.fullName,
        phone: formValues.mobile,
        city: formValues.city,
        age: formValues.age,
        product: type,
      };

      axios
        .post(
          `https://www.loanloom.com/loan_phpForm/all_loans_step1.php`,
          firstApI
        )
        .then((res) => console.log(res.data));
      axios
        .post(
          `https://www.uponly.in/Loan_Loom_API/Loan_loom_api.php`,
          secondApI
        )
        .then((res) => console.log(res.data));
      nextStep();
    },
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col lg={6} md={6} sm={12}>
              <Form.Group>
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <IoAccessibilityOutline className="formicon" />
                    </InputAdornment>
                  }
                  value={values.age}
                  onBlur={handleBlur}
                  name="age"
                  type="number"
                  placeholder="Age"
                  onChange={handleChange}
                />
                {errors.age && touched.age ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.age}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} sm={12} md={6} className="mt-3">
              <Select
                fullWidth
                variant="standard"
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem",
                }}
                styles={customStyles}
                id="other"
                isClearable
                options={data}
                name="city"
                onChange={(option) => {
                  setFieldValue("city", option?.value);
                }}
                placeholder="select city.."
              />
              {errors.city && touched.city ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "12px",
                  }}
                >
                  {errors.city}
                </p>
              ) : null}
            </Col>
          </Row>

          <button
            type="submit"
            className="btn-one1"
            onClick={handleSubmit}
          >
            Next &#8594;
          </button>
        </Form>
      </Box>
    </Fade>
  );
};

export default PersonalTwo;
