import React from "react";
import  Form from "react-bootstrap/Form";
import Card from "@mui/material/Card/Card";
import Input from "@mui/material/Input/Input"
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import { RiErrorWarningLine } from "react-icons/ri";
import "./Offline/Home.css";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { HomeAddressInfo } from "../../../Forms/FormsStructure/Schemas";

const CurrentAddress = ({ nextStep, handleFormData, values: value, right,mobile,prevStep }) => {
// console.log(response.mobile);
  const url =`https://www.loanloom.com/loan_phpForm/personalloan_step2.php/${mobile}`
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    // setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: HomeAddressInfo,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        locality: formValues.locality,
        house_No: formValues.house_No,
        landmark: formValues.landmark,
      });
      axios.post(url,formValues).then(res=>console.log(res.data))
      axios.post(`https://www.loanloom.com/loan_phpForm/offers_api_step2.php/${mobile}`,formValues).then((res)=>console.log(res.data));
      nextStep();
    },
  });

  const Navigate=useNavigate();

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <h4 style={{ fontFamily: "poppins" }}>
          Please confirm your current home address
        </h4>
        <Card className="cardforaddress">
          <RiErrorWarningLine className="iconforaddress" /> Address proof must
          match:eg-driver lisc,aadhar,pancard,voter id etc
        </Card>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col md={12} lg={12}>
              <Form.Group className="mb-3">
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  name="house_No"
                  onBlur={handleBlur}
                  value={values.house_No}
                  type="text"
                  placeholder="Flat No/Block No/House No"
                  onChange={handleChange}
                />
                {errors.house_No && touched.house_No ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.house_No}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className="mb-3">
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  name="locality"
                  type="text"
                  placeholder="Apartment/Street/Locality/Area"
                  value={values.locality}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.locality && touched.locality ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.locality}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className="mb-3">
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  name="landmark"
                  value={values.landmark}
                  onBlur={handleBlur}
                  type="text"
                  placeholder="Landmark(Optional)"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
           
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {/* onClick={handleSubmit} */}
            <button className="btn-onePL" type="submit" onClick={()=>Navigate('/Personalloan_Thankyou')}>
              Submit &#8594;
            </button>
          </div>
        </Form>
      </Box>
    </Fade>
  );
};
export default CurrentAddress;
