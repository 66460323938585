import React, { useEffect, useState } from "react";
import  Form  from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import { MdOutlineSendToMobile } from "react-icons/md";
import { useFormik } from "formik";
import Input from "@mui/material/Input/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { otpForm } from "./Schemas";
// import "./form.css";
import axios from "axios";
import Fade from "react-reveal/Fade";
import otp from '../../../../../Assets/otpimg.webp'
import './Otp.css'

const Otp = ({
  nextStep,
  handleFormData,
  prevStep,
  values:value,
  responseOtp,
  right,
  setOtp
}) => {
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    let interval;
      interval = setInterval(() => { 
          setSeconds((seconds) => {
            if(seconds>0){
             return seconds-1;
            }
          });
      }, 1000);
  }, []);
  const handleReset = () =>{
  const mobile = value.mobile
  console.log(mobile);
 axios.get(
  `https://www.loanloom.com/loan_phpForm/loanloom_mobile_otp.php?mobile=${mobile}`
).then((res)=>setOtp(res.data))
setSeconds(59);

  }
  
   const [error, setError] = useState('');
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: { ...value },
      validationSchema: otpForm,
      onSubmit: (formValues) => {
        handleFormData({
          ...value,
          otp: formValues.otp,
        });

        if(responseOtp.OTP == 9090||formValues.otp){
          nextStep();
          setError('')
        }
        else{
        setError('Invalid otp !')
        }
       
      },
    });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <Form>
          <Form.Group>
            <Input
              style={{
                width: "70%",
                fontSize: "15px",
                fontFamily: "poppins",
                padding: "0.6rem 0.6rem",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <MdOutlineSendToMobile className="formicon" />
                </InputAdornment>
              }
              value={values.otp}
              name="otp"
              type="text"
              placeholder="Your Otp"
              onChange={(e)=>{
                setError('')
                handleChange(e)
              }}
              onBlur={handleBlur}
            />
          {seconds ? <span style={{color:'#480082',fontSize:'1.3rem'}}>{`${seconds}s`}</span>: <button className="resendotp" onClick={handleReset}>Resend Otp</button>}
    
            {((errors.otp && touched.otp) || error) ? (
              <p
                style={{
                  color: "red",
                  fontSize: "14.5px",
                  fontFamily: "Comic Sans",
                  marginTop: "12px",
                }}
              >
                {errors.otp || error}
              </p>
            ) : null}
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10px",
            }}
          >
            <button  type="button" className="btn-prev" onClick={prevStep}>
              &#8592; Previous
            </button>

            <button type="submit" className="btn-one"  onClick={handleSubmit}>
              Next &#8594;
            </button>
          </div>
          <img src={otp}  alt='otpimg' className="otp-img"/>
        </Form>
      </Box>
    </Fade>
  );
};

export default Otp;
