import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import InCome from "./InCome";
import Pincode from "./Pincode";
import App from "./HowToUseLoan/App";

const HomeIndex = ({ prevStep: prevPreviousStep,handleFormData: setFormData, values: formData,mobile,right:salariedRight}) => {
  //state for steps
  const [step, setstep] = useState(1);
  const[right,setRight] = useState(true)

  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  const prevStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if (step === 1) {
      prevPreviousStep();
    } else {
      setstep(step - 1);
      setRight(false)
    }
  };

  switch (step) {
    case 1:
      return (
        <div>
          <Col md={{ span: 6, offset: 3 }} className="custom-margin">
            <InCome
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={salariedRight}
            />
          </Col>
        </div>
      );

    case 2:
      return (
        <div>
          <Col md={{ span: 6, offset: 3 }} className="custom-margin">
            <Pincode
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              mobile={mobile}
              right={right}
            />
          </Col>
        </div>
      );
    case 3:
      return (
          <Row>
            <Col className="custom-margin">
              <App
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
            </Col>
          </Row>
      );

    default:
      return <div className="App"></div>;
  }
};

export default HomeIndex;
