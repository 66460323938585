        import React from 'react'
        import dashlogo from '../../../Assets/dashboardlogo.webp'
        import './Profile.css'
        import Nav from 'react-bootstrap/Nav';
        import { Link } from 'react-router-dom';
        import AccountCircleIcon from '@mui/icons-material/AccountCircle';
        import SupportAgentIcon from '@mui/icons-material/SupportAgent';
        import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
        import QrCodeIcon from '@mui/icons-material/QrCode';
        import { useState } from 'react';
        import Button from 'react-bootstrap/Button';
        import Modal from 'react-bootstrap/Modal';
        import dashimg1 from '../../../Assets/homeloandashimg.webp'
        import dashimg2 from '../../../Assets/personaldashimg.webp'
        import dashimg3 from '../../../Assets/educationdashimg.webp'
        import dashimg4 from '../../../Assets/businessdashimg.webp'
        import dashimg5 from '../../../Assets/autodashimg.webp'
        import Accordion from 'react-bootstrap/Accordion';
     
        
    
        function Profile() {
            const [show, setShow] = useState(false);
            const showDropdown = (e)=>{
            setShow(!show);
        }

       
    const logout =()=>{
        const storedMobile = localStorage.getItem('mobile');
        console.log(storedMobile);
    }

   
        

            return (

            <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container"> 
            <img src="https://img.freepik.com/free-icon/user_318-159711.jpg" alt='user' width={30} className='dashuserprofileimg'/>
            <a className="navbar-brand" href="#">
                    <div className="d-flex">
                    
                    
                        <Nav.Link as={Link} to="/">
                            <img src={dashlogo} width={200}></img>
                            </Nav.Link>
                            </div>
                        
                    
                </a>
                <div className="collapse navbar-collapse" id="mynav">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        
                            <li>
                            <Button variant="#f68713" onClick={() => setShow(true)}>
                Our Services Product
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogclassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Our Services
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='dashservicelinks'>
                    <Nav.Link as={Link} to="/eligibility/Home">
                    <img src='https://img.freepik.com/premium-vector/house-with-hand-gold-coin-money-illustration-real-estate-investment-concept-building-white-isolated_138676-874.jpg' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Home Loan</li>
                    </Nav.Link>
                    

                    <Nav.Link as={Link} to="/eligibility/Personal">
                    <img src='https://cdn4.iconfinder.com/data/icons/BRILLIANT/shopping/png/400/personal_loan.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Personal Loan</li>
                    </Nav.Link>
                    

                    <Nav.Link as={Link} to="/eligibility/Education">
                    <img src='https://cdn3d.iconscout.com/3d/premium/thumb/invest-for-education-5768769-4833566.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Education Loan</li>
                    </Nav.Link>
                    </div>

                    

                    <div className='dashservicelinks'>
                    <Nav.Link as={Link} to="/eligibility/Business">
                    <img src='https://qph.cf2.quoracdn.net/main-qimg-200f599f08af80a11c92e37341d2b0e2' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Business Loan</li>
                    </Nav.Link>
                    


                    <Nav.Link as={Link} to="/eligibility/Automobile">
                    <img src='https://cdn-icons-png.flaticon.com/512/2505/2505993.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Automobile Loan</li>
                    </Nav.Link>
                    </div>
                
                
                </Modal.Body>
            </Modal>

                        </li>

                        
                        <li className="nav-item"> 
                        <Nav.Link as={Link} to=""><button className='dashlogoutbtn' onClick={logout}>Log Out</button></Nav.Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-3 my-lg-0 my-md-1">
                    <div id="sidebar" className="bg-purple">
                        <div className="h4 text-white">Account</div>
                        <ul>
                            
                            <li> 
                            
                                    <AccountCircleIcon style={{margin:"10px" , color:"#fff"}}/>
                                    <div className="d-flex flex-column">
                                    <Nav.Link as={Link} to="/myprofile">
                                        <div className="link">My Profile</div>
                                        <div className="link-desc">Change your profile details & password</div>
                                        </Nav.Link>
                                    </div>
                                    
                                </li>
                            


                                <li> 
                                
                            <Nav.Link as={Link} to="">
                                    <GroupRemoveIcon style={{margin:"10px" , color:"#fff"}}/>
                                        <div className="link">Pre-User-Offers</div>
                                        <div className="link-desc">Contact Us for help and support</div>
                                    </Nav.Link>
                                </li>


                                <li> 
                                
                                <Accordion >
                        <Accordion.Item eventKey="0">
                                         <Accordion.Header><QrCodeIcon style={{margin:"1px"}}/>Existing Product</Accordion.Header>
                        <Accordion.Body className="bg-purple">
                    <Nav.Link as={Link} to="/eligibility/Home"><p style={{color:"#fff"}}>Home Loan</p></Nav.Link>
                    <Nav.Link as={Link} to="/eligibility/Personal"> <p style={{color:"#fff"}}>Personal Loan</p></Nav.Link>
                    <Nav.Link as={Link} to="/eligibility/Education"><p style={{color:"#fff"}}>Education Loan</p></Nav.Link>
                    <Nav.Link as={Link} to="/eligibility/Business"><p style={{color:"#fff"}}>Business Loan</p></Nav.Link>
                    <Nav.Link as={Link} to="/eligibility/Automobile"><p style={{color:"#fff"}}>Automobile Loan</p></Nav.Link>
                    </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
    
                                    </li>

                                    <li> 
                                
                            <Nav.Link as={Link} to="/contact">
                                    <SupportAgentIcon style={{margin:"10px" , color:"#fff"}}/>
                                        <div className="link">Help & Support</div>
                                        <div className="link-desc">Contact Us for help and support</div>
                                    </Nav.Link>
                                </li>
                        </ul>
                    </div>
                </div>
                        <div className="col-lg-9 my-lg-0 my-1">
                        <div id="main-content" className="bg-white border">
                    <div className="d-flex flex-column">
    
                <div className='h3'>Welcome, {localStorage.getItem('name')} 🤚</div>
        
      </div>
    </div>

    
            


                        <div className="text-uppercase">My Products</div>
                    

                        <div className="dash-cards-list">
        
        <div className="dash-card 1">
            <div className="dash-card_image">
            
                <img src={dashimg1} alt='img'/> 
                
                </div>
            
        </div>
        
        <div className="dash-card 2">
            <div className="dash-card_image">
            <img src={dashimg2} alt='img' />
            </div>
        </div>
        
        <div className="dash-card 3">
            <div className="dash-card_image">
            <img src={dashimg3} alt='img'/>
            </div>
            
        </div>
            
        <div className="dash-card 4">
            <div className="dash-card_image">
            <img src={dashimg4} alt='img' />
            </div>
        </div>

        <div className="dash-card 5">
            <div className="dash-card_image">
            <img src={dashimg5} alt='img' />
            </div>
        </div>
        
        </div>
            
                        </div>
            
        </div>
                    </div>
                </div>
            
        
        

            
            )
        }

        export default Profile



