import React from 'react';
import { Component } from "react";
import Slider from "react-slick";
import './HappyClient.css';
import firstimg from '../../../Assets/choice.jpg'
import secondimg from '../../../Assets/finnable.png'
import thirdimg from '../../../Assets/hdfc.png'
import forthimg from '../../../Assets/chole.png'
import fifthimg from '../../../Assets/Axis.png'
import sixthimg from '../../../Assets/pf.png'
import seventhimg from '../../../Assets/lt-finance.png'
import eightimg from '../../../Assets/Stan_char.png'
import ninethimg from '../../../Assets/yesbank.png'

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2500,
      autoplaySpeed: 2500,
      cssEase: "linear"
    };
    return (
      <div className="investorSlide mb-5">
        <h1 className='text-center headerTitle'>Our Partners</h1>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div className="investor-card">
            <img
              className="picture"
              src={firstimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={secondimg}
              width={53} height={53}
              alt="Secondslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={thirdimg}
              width={53} height={53}
              alt="Thirdslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={forthimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={fifthimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={sixthimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>
          <div className="investor-card">
            <img
              className="picture"
              src={seventhimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>

          <div className="investor-card">
            <img
              className="picture"
              src={eightimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>


          <div className="investor-card">
            <img
              className="picture"
              src={ninethimg}
              width={53} height={53}
              alt="Firstslide"
            />
          </div>
        </Slider>

      </div>
    );
  }
}