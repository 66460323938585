import { Grid } from "@mui/material";
import './Educational_loan.css';
import img from '../../../../../../Assets/edu2.webp'
import EducationEliloanImage from '../../../../../../Assets/EducationalEliImage.jpg';
import EducationalDocImage from '../../../../../../Assets/EducationalDocumentImage.jpg';
import EducationalApplyLoanImage from '../../../../../../Assets/EducationalLoanApplyImage.jpg';
import EducationalSiginificance from '../../../../../../Assets/EducationalSiginImage.jpg';

const Educational_loan = () => {
  return (
    <>
      <div className='containerforEducation'>
        <Grid container padding={0}>
          <Grid item lg={6} xs={12} md={6}>
            <img src={img} className="educationloanimg" alt='education' />
          </Grid>

          <Grid item lg={6} xs={12} md={6}>
            <h1 className="education_Heading">Education loan</h1>
            <p className="education_paragraph">An educational loan seems to be several funds borrowed to cover the costs of university
            education or advanced learning. <b>Loanloom provides the most affordable education loan </b>
              that is utilized to pay for tuition, literature as well as materials, as well as lifestyle
              expenditures while the borrower is seeking a qualification. Most students stop their studies
              due to financial shortages but there is no need to worry for these individuals because
              <b> Loanloom provides the most affordable educational loan</b> to the desired students who want
              to further continue their studies at a higher level. loanloom is the best assistance that supports
              the needed or desired students’ requirements and <b>Loanloom provides the most affordable
              educational loan</b> to that student who wants to continue their studies in India as well as in
              foreign.</p>
          </Grid>
        </Grid>
      </div>

      <Grid container>
        <Grid item lg={4} md={4}>
          <img className='EducationalEligibilityImage' alt='logo' src={EducationEliloanImage} ></img>
          <h2 className='EducationEligibilityTitle'><b>Educational Loan Eligibility Criteria</b></h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className='EducationDocumentParagraph'>Limited age 18 years or maximum age limit up to 35 years.</li>
            <li className='EducationDocumentParagraph'>Indian residential.</li>
            <li className='EducationDocumentParagraph'>Well academic record of the students.</li>
            <li className='EducationDocumentParagraph'>Income of the parents is stable.</li>
            <li className='EducationDocumentParagraph'>Qualification detail required for the educational loan.</li>
          </ul>
        </Grid>

        <Grid item lg={4} md={4} className='EductionalRequiredGrid'>
          <img className='EducationalRequiredImage' alt='logo' src={EducationalDocImage}></img>
          <h2 className="EducationEligibilityTitle"><b>Required documents for the educational loan</b></h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="EducationDocumentParagraph">Passport size photo.</li>
            <li className="EducationDocumentParagraph">DMC of 10 th or 12 th .</li>
            <li className="EducationDocumentParagraph">Students’ pan card or adhar card.</li>
            <li className="EducationDocumentParagraph">Residential proof.</li>
            <li className="EducationDocumentParagraph">Parents’ banking details.</li>
            <li className="EducationDocumentParagraph">Last six months’ banking statements of parents.</li>
          </ul>
        </Grid>

        <Grid item lg={4} md={4} className='EductionalApplyGrid'>
          <img className='EducationalApplyImage' alt='logo' src={EducationalApplyLoanImage}></img>
          <h2 className="EducationEligibilityTitle"><b>How to apply for an education loan?</b></h2>
          <ul style={{ listStyleType: "disc" }}>
            <li className="EducationDocumentParagraph"><b>Loanloom provides the most affordable education loan</b> both online and offline
            services to apply for educational loans.</li>
            <li className="EducationDocumentParagraph">Click on apply button <a href='https://loanloom.com/'>(https://loanloom.com/)</a></li>
            <li className="EducationDocumentParagraph">Online method for the applies for an education loan is a simple or convenient method
            and an easy way for all processing to be done in less period or for documents is also
            submitted in a fast way.</li>

            <li className="EducationDocumentParagraph">Offline method is also the best method for educational loan applications because in
            this method person meets personally with the lender and appropriately resolves their
            query or discusses everything related to educational loans in a detailed manner.</li>
          </ul>
        </Grid>
      </Grid>

      <Grid container padding={2}>
        <Grid item lg={6} md={6}>
          <img className='EducationalSiginificanceImage' alt='logo' src={EducationalSiginificance}></img>
        </Grid>

        <Grid item lg={6} md={6}>
          <h2 className="EducationSiginificanceTitle"><b>Significance Of Educational Loan</b></h2>
          <ul style={{ listStyleType: "disc" }} className="EducationSiginificanceParagraph">
            <li><b>Loanloom provides the most affordable education loan</b> that is helpful in reducing your
              burden for higher studies and it also provides education loans at minimal interest rates.</li>
            <li>It is important for a student because loanloom provides a long-term repayment facility
              to students and they need not worry about the repayment of the educational loan immediately.</li>
            <li>It is also beneficial because we provide a tax benefit to the students on educational loan as
              per the guideline of section 80 E. <b>Loanloom provide the most affordable education loan </b>
              and students get tax benefits on it in an appropriate manner.</li>
            <li>Loan loom education loan is more significant and it demands no collateral security from the students.</li>
            <li>It also covers several expenses of the students such as traveling expenses, book expenses, or laptops, etc.</li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
}

export default Educational_loan;
