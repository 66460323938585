import React from "react";
import Box from "@mui/material/Box";
import Img1 from "../../../../../../../../Assets/completed.png";
import Img1forPhone from "../../../../../../../../Assets/completed1.png";
import Img2 from "../../../../../../../../Assets/underconstruction.png";
import Img2forPhone from "../../../../../../../../Assets/underconstruction1.png";
import Img3 from "../../../../../../../../Assets/plot.webp";
import Img3forPhone from "../../../../../../../../Assets/plot1.png";
import Img4 from "../../../../../../../../Assets/built.webp";
import Img4forPhone from "../../../../../../../../Assets/built1.png";
import Img5 from "../../../../../../../../Assets/buyplot.webp";
import Img5forPhone from "../../../../../../../../Assets/buyplot1.png";
import "../index.css";
import Fade from "react-reveal/Fade";
import { useFormik } from "formik";
import { AboutPropertyValidate } from "../../../../../Forms/FormsStructure/Schemas";


const AboutProperty = ({
  nextStep,
  // prevStep,
  handleFormData,
  values: value,
  right
}) => {
  const { values, errors, handleBlur, handleChange, handleSubmit} = useFormik({
    initialValues: { ...value },
    validationSchema: AboutPropertyValidate,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        aboutProperty: formValues.aboutProperty,
      });
      nextStep();
    },
  });
  return (
    // <motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
    <Fade duration={1000} right={right} left={!right}>
      <form>
   <Box>
      <h4 style={{ fontFamily: "poppins" }}>Tell us about your property?</h4>
      <div style={{ marginTop: "40px" }}>
        <label>
          <img src={Img1} className="imagesforproperty" alt="About" />
          <img src={Img1forPhone} className="imagesforpropertyphone" alt="About" />
          <input
            className="inputproperty"
            type="radio"
            name="aboutProperty"
            value="completed"
            defaultreadOnly={values.aboutProperty === "completed"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <h6 className="typeforproperty">
            Completed <br />
            project
          </h6>
        </label>

        <label>
          <img src={Img2} className="imagesforproperty" alt="About" />
          <img src={Img2forPhone} className="imagesforpropertyphone1" alt="About" />
          <input
            className="inputproperty1"
            type="radio"
            name="aboutProperty"
            value="under-construction"
            defaultreadOnly={values.aboutProperty === "under-construction"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <h6 className="typeforproperty">
            Under-construction
            <br /> project
          </h6>
        </label>
        <label>
          <img src={Img3} className="imagesforproperty" alt="About" />
          <img src={Img3forPhone} className="imagesforpropertyphone" alt="About" />
          <input
            className="inputproperty"
            type="radio"
            name="aboutProperty"
            value="land"
            defaultreadOnly={values.aboutProperty === "land"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <h6 className="typeforproperty">
            Land and <br />
            Plot only
          </h6>
        </label>
        <label>
          <img src={Img4} className="imagesforproperty" alt="About" />
          <img src={Img4forPhone} className="imagesforpropertyphone1" alt="About" />
          <input
            className="inputproperty"
            type="radio"
            name="aboutProperty"
            value="buildown"
            defaultreadOnly={values.aboutProperty === "buildown"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <h6 className="typeforproperty">
            Built/building on <br /> land I own
          </h6>
        </label>
        <label>
          <img src={Img5} className="imagesforproperty" alt="About" />
          <img src={Img5forPhone} className="imagesforpropertyphone1" alt="About" />
          <input
            className="inputproperty"
            type="radio"
            name="aboutProperty"
            value="tobuy"
            defaultreadOnly={values.aboutProperty === "tobuy"}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <h6 className="typeforproperty">
            Looking to buy land
            <br /> and build on it
          </h6>
        </label>
        {errors.aboutProperty ? (
          <p
            style={{
              color: "red",
              fontSize: "14.5px",
              fontFamily: "Comic Sans",
              marginTop: "12px",
            }}
          >
            {errors.aboutProperty}
          </p>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
        
          <button
           type="submit"
            className="btn-oneforcity"
            onClick={handleSubmit}
          >
            Next &#8594;
          </button>
        </div>
      </div>
    </Box>
    </form>
    </Fade>
    //</motion.div>
  );
};

export default AboutProperty;
