import React from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../../../Shared/Navbar/NavbarMain/Navbar";
import "./homeEligibility.css";
import Products from "../../Forms/FormsStructure/Products";


const HomeEligibility = () => {
  const { type } = useParams();

  return (
    <>
      <NavBar />
      <section style={{paddingTop:'80px'}}>
      <Products type={type}/>
      </section>
    
    </>
  );
};

export default HomeEligibility;
