import { Input, InputAdornment } from "@mui/material";
import './Feedback.css';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

const FeedBack = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleFeedbackChange = event => {
    setFeedback(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name,
      feedback
    };
    axios.post('https://www.loanloom.com/loan_phpForm/feedbackform_api.php', body)
      .then(res => console.log(res))
      .catch(error => console.error(error));
    
    alert('Thank you for giving your feedback');
    navigate('/');
  };

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="containerforfeeback">
        <form className="form" onSubmit={handleSubmit}>
          <h1 className="heading">
            <span className="spanforfeedback"> Feed</span>Back
          </h1>
          <Form.Group className="mb-3">
            <Input
              required
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={handleNameChange}
              startAdornment={<InputAdornment position="start" />}
              autoComplete="off"
              fullWidth
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Input
              required
              type="text"
              placeholder="Your Feedback.."
              value={feedback}
              onChange={handleFeedbackChange}
              startAdornment={<InputAdornment position="start" />}
              autoComplete="off"
              fullWidth
            />
          </Form.Group>
          <button type="submit" className="lrnmorebtn">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default FeedBack;
