import * as React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import Pincode from "../SalariedPL/Pincode";
import Profit from "../Professional/Profit";
import PLDoc from "../SalariedPL/PLDoc";
import PLAmount from "../SalariedPL/PLAmount";
import Thank from "../../../../../Forms/FormsStructure/Thank";

export default function SelfEmployePL({prevStep:preprevStep, handleFormData:setFormData, values:formData,mobile}) {
    const [right, setRight] = useState(true);
  
  const [step, setstep] = useState(0);


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true)
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
    if(step === 0){
      preprevStep();
    }
    else{
      setstep(step-1)
    }
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
        <div className="formdiv">
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="m-auto">
              <Profit
                nextStep={nextStep}
                prevStep={prevStep} 
                handleFormData={setFormData}
                values={formData}
                right={right}
              />
            </Col>
          </Row>
        </Container>
      </div>
      );
      case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="m-auto">
                <PLAmount
                  nextStep={nextStep}
                  prevStep={prevStep} 
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="m-auto">
                <Pincode
                  nextStep={nextStep}
                  prevStep={prevStep} 
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
      case 3:
      return (
        <div className="formdiv">
          <PLDoc
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={setFormData}
            values={formData}
            right={right}
          />
        </div>
      );
      case 4:
        return (
          <div className="formdiv">
            <Thank
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={right}
            />
          </div>
        );

    default:
      return <div className="formdiv"></div>;
  }
}
