import React from "react";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "../Salaried/HowToUseLoan/App";

const Independent = ({
  prevStep: prevPreviousStep,
  handleFormData: setFormData,
  values: formData,
  mobile,
  right: IndependentRight
}) => {
  //state for steps
  const [step] = useState(1);

  const prevStep = () => {
    prevPreviousStep();
  };

  return (
    <Col>
      <App
        nextStep={null}
        prevStep={prevStep}
        handleFormData={setFormData}
        values={formData}
        mobile={mobile}
        right={IndependentRight}
      />
    </Col>
  );
};

export default Independent;
