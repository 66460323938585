import * as Yup from 'yup';

const phoneRegExp = /^[+0]{0,2}(91)?[0-9]{10}$/;
const otpRegex = /^[+0]{0,2}(91)?[0-9]{4}$/;
const emailregex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
const nameregex = /^[a-zA-Z\s]+/;
const pincodeRgex = /^[1-9][0-9]{5}$/;
const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const aadharRegex = /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
const ageRegex = /^(1[89]|[2-6][0-9]|70)$/gm;
const yearRegex = /^(0|[1-9]\d*)(\.\d+)?$/;

export const otpForm = Yup.object({
  otp: Yup.string().matches(otpRegex, 'Invalid OTP').required('Please enter the OTP'),
});

export const personalInfo = Yup.object({
  fullName: Yup.string().matches(nameregex, 'Invalid input').required('Full Name is required'),
  mobile: Yup.string().matches(phoneRegExp, 'Enter a valid phone number').required('Mobile number is required'),
});

export const personalInfoTwo = Yup.object({
  age: Yup.string().matches(ageRegex, 'Invalid format').required('Age is required'),
  city: Yup.string().required('City is required'),
});

export const InComeValid = Yup.object({
  Income: Yup.string().required('Income is required'),
});

export const PincodeRegex = Yup.object({
  Pincode: Yup.string().matches(pincodeRgex, 'Invalid format').required('Pincode is required'),
});

export const AadharRegex = Yup.object({
  aadhar_No: Yup.string().matches(aadharRegex, 'Invalid format').required('Please enter your Aadhar number'),
});

export const PancardRegx = Yup.object({
  pancard_No: Yup.string().matches(pancardRegex, 'Invalid format').required('Please enter the Pancard number'),
});

export const Cityvalidate = Yup.object().shape({
  currentCity: Yup.string().required('Please select the city'),
});

export const CityValidate = Yup.object().shape({
  city: Yup.string().required('Please select the city'),
});

export const Gendervalidate = Yup.object().shape({
  gender: Yup.string().required('Please select the gender'),
});

export const Propertyvalidate = Yup.object().shape({
  propertyBasedCity: Yup.string().required('Please select the city'),
});

export const AboutPropertyValidate = Yup.object().shape({
  aboutProperty: Yup.string().required('Please select the property'),
});

export const HomeValidate = Yup.object({
  costofplot: Yup.string().required('Please select the price'),
});

export const ExistBankValidate = Yup.object({
  existbankLoan: Yup.string().required('Please type the bank name'),
});

export const DateValidate = Yup.object({
  whenDidYouBegin: Yup.date().required('Please select the date'),
});

export const CompanyWorkForRegex = Yup.object({
  company_Name: Yup.string().required('Please type the company name'),
});

export const NetSalaryRegex = Yup.object({
  salary: Yup.string().required('Please enter your salary'),
});

export const HomeAddressInfo = Yup.object({
  locality: Yup.string().required('Area/Locality is required'),
  house_No: Yup.string().required('House number is required'),
});

export const ComapnyAddressInfo = Yup.object({
  companyName: Yup.string().matches(nameregex, 'Invalid input').required('Company name is required'),
  companyLocality: Yup.string().required('Area/Locality is required'),
  building: Yup.string().required('Building number is required'),
});

export const PLAmountForRegex = Yup.object({
  loanAmount: Yup.string().required('Please enter the loan amount'),
});

export const EducationRegex = Yup.object({
  email: Yup.string().matches(emailregex, 'Invalid input').required('Email is required'),
  countryStudy: Yup.string().required('Please select the country of study'),
});

export const BussinessRegex = Yup.object({
  companyTurnOver: Yup.string().required('Required'),
  natureOfBussiness: Yup.string().required('Required'),
});

export const BussinessRegex1 = Yup.object({
  yearsIncurrentBussiness: Yup.string().matches(yearRegex, 'Invalid input').required('Required'),
});

export const BussinessRegex2 = Yup.object({
  loanRequirement: Yup.string().required('Please enter the loan requirement'),
});
