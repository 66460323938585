import React from 'react';
import { styled } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import { useFormik } from 'formik';
import { Slider } from '@mui/material';
import Fade from 'react-reveal/Fade';
import { NetSalaryRegex } from '../../../../../Forms/FormsStructure/Schemas';


const Input = styled(MuiInput)`
  width: 300px;
  font-family: sans-serif;
  padding: 0.4rem 0.8rem;
`;

const IncomeSlider = {
  marksAmt: [
    { value: 0, label: '₹ 0' },
    { value: 200000, label: '> ₹ 200 K' },
  ],
};

const NetSalary = ({ prevStep, nextStep, handleFormData, values: value, right }) => {
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: NetSalaryRegex,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        salary: formValues.salary,
      });
      console.log(formValues);
      nextStep();
    },
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Form>
        <Box>
          <h4 style={{ fontFamily: 'poppins' }}>My net monthly salary is</h4>
          <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px', fontFamily: 'poppins' }}>
            <Grid item xs={12}>
              <strong>&#8377; </strong>
              <Input
                name="salary"
                style={{ fontFamily: 'poppins' }}
                value={values.salary}
                size="small"
                onChange={(e) => setFieldValue('salary', e.target.value)}
                onBlur={handleBlur}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 200000,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }} p={1}>
              <Slider
                sx={{
                  color: '#480082',
                  '& .MuiSlider-thumb': {
                    border: '2px solid #F68712',
                    background: '#FFF',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: '#bfbfbf',
                  },
                }}
                value={values.salary || 0}
                marks={IncomeSlider.marksAmt}
                max={200000}
                onChange={(e, newValue) => setFieldValue('salary', newValue)}
                aria-labelledby="input-slider"
              ></Slider>
            </Grid>
          </Grid>
          {errors.salary ? (
            <p
              style={{
                color: 'red',
                fontSize: '14.5px',
                fontFamily: 'Comic Sans',
              }}
            >
              {errors.salary}
            </p>
          ) : null}
        </Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '20px',
          }}
        >
          <button type="button" className="btn-prevforcity" onClick={prevStep}>
            &#8592; Previous
          </button>

          <button className="btn-oneforcity" type="submit" onClick={handleSubmit}>
            Next &#8594;
          </button>
        </div>
      </Form>
    </Fade>
  );
};

export default NetSalary;
