import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Home from "./component/Home/Home/Home";
import NotFound from "./component/MainPart/NotFound";
import Accordian from "./component/Faqs/Accordian";
import Blog from "./component/Home/Blog/BlogMain/Blog";
import Singal from "./component/Home/Blog/SingalpostBlog/Singal";
import Calculator from "./component/Home/Home/Calculator/CalciMain/Calculator";
import Loader from "./component/MainPart/Loader";
import FeedBack from "./component/Home/Footer/FeedBack/Feedback";
import Dashboard from "./component/Dashboard/DashboardMain/Dashboard";
import Userdropdown from "./component/Shared/Navbar/UserdropdownMain/Userdropdown";
import HomeEligibility from "./component/Eligibility/HomeElibility/Service/HomeLoan/HomeEligibility";
import Thank from "./component/Eligibility/HomeElibility/Forms/FormsStructure/Thank";
import MyProfile from "./component/Dashboard/MyprofilePage/MyProfile"; 



const LazyLearnMore = React.lazy(() =>import("./component/Home/Header/LearnmoreReadmore/LearnMore"));
const LazyAboutUs = React.lazy(() =>  import("./component/Home/About/AboutreadContent/AboutUsContent") );
const LazyPrivacy = React.lazy(() => import("./component/Home/Footer/PrivacyTerms/Privacy"));
const LazyTermsCondition = React.lazy(() =>  import("./component/Home/Footer/PrivacyTerms/Terms"));
const LazyContactForNav = React.lazy(()=> import("./component/Home/Contact/ContactforNav/ContactForNav"));

const App = () => {
  return (
    <>
  
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/learnmore" element={<LazyLearnMore />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/contact" element={<LazyContactForNav />} />
          <Route path="/eligibility/:type" element={<HomeEligibility/>}/> 
          <Route path="/FAQs" element={<Accordian />} />
          <Route path="/AboutUs" element={<LazyAboutUs />} />
          <Route path="/terms" element={<LazyTermsCondition />} />
          <Route path="/privacy" element={<LazyPrivacy />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/userdropdown" element={<Userdropdown/>}/>
          <Route path="/blog" element={<Blog />} />
          <Route path="/post/:id" element={<Singal />} />
          <Route path="/Homeloan_Thankyou" element={<Thank/>}/>
          <Route path="/Personalloan_Thankyou" element={<Thank/>}/>
          <Route path="/Educationloan_Thankyou" element={<Thank/>}/>
          <Route path="/Businessloan_Thankyou" element={<Thank/>}/>
        <Route path="/myprofile" element={<MyProfile/>}/>
        </Routes> 
      </Suspense>
  
      
    </>
  );
};
export default App;
