import Home from '../HomeBlog/Home'
import BlogNav from '../BlogNav/BlogNav'
import './Blog.css'

const Blog = () => {
  return (
  <>
  <div className='link'>
      <BlogNav/>
      <Home/>
    </div>
    </>
  )
}

export default Blog
