import * as React from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Img1 from "../../../../../../../Assets/constructs.jpg";
import Img2 from "../../../../../../../Assets/tran.png";
import Fade from "react-reveal/Fade";
import Img3 from "../../../../../../../Assets/serching.jpg";
import ExistBankLoan from "./TranferExistLoan/ExistBankLoan";
import Index from "./Haven't Identify/Index";
import CurrentCity from "./PurchaseAndConstruct/CurrentCity";

export default function App({handleFormData:setFormData,values:formData,mobile,right:rightApp}) {
  const [step, setstep] = useState(0);

  const [right, setRight] = useState(true);
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if (step === 2) {
      setstep(0);
    }
    else if (step === 3) {
      setstep(0);
    } 
    else{
      setstep(step-1)
    }
    
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
       // <motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
        <div>
          <Fade duration={1000} right={rightApp} left={!rightApp}>
          <h4 style={{ fontFamily: "poppins" }}>
            How would you like to use your home loan?
          </h4>
          <div style={{ marginTop: "40px" }}>
            <label>
              <img src={Img1} className="puchaseImg" alt="Loan" />
              <input
                type="radio"
                className="input"
                name="howWouldyouliketoUse"
                value={step}
                onChange={() =>{
                  setFormData({...formData,howWouldyouliketoUse:'purchase and construct'})
                  setstep(1)}}
              />
              <h6 className="puchasetype">
                Purchase or construct
                <br /> on property I have <br /> already chosen
              </h6>
            </label>
            <label>
              <img src={Img2} className="puchaseImg3" alt="Loan" />
              <input
                type="radio"
                className="input"
                name="howWouldyouliketoUse"
                value={step}
                onChange={() =>{
                  setFormData({...formData,howWouldyouliketoUse:'transfer to existing loan'})
                  setstep(2)}}
              />
              <h6 className="puchasetype">
                Transfer my existing
                <br /> home loan<br/>
                <br/>
              
              </h6>
            </label>
            <label>
              <img src={Img3} className="puchaseImg1" alt="Loan" />
              <input
                type="radio"
                className="input"
                name="howWouldyouliketoUse"
                value={step}
                onChange={() =>{
                  setFormData({...formData,howWouldyouliketoUse:'haven not identify yet'})
                  setstep(3)}}
              />
              <h6 className="puchasetype1">
                I haven't yet identified <br /> the property I'd <br />
                like to purchase
              </h6>
            </label>
          </div>
          </Fade>
        </div>
        //</motion.div>
      );
    case 1:
      return (
        <div>
          <Row>
            <Col md={12} sm={6}>
            <CurrentCity
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              mobile={mobile}
              right={right}
            />
            </Col>
          </Row>
        </div>
      );
    case 2:
      return (
        <div>
          <Row>
            <ExistBankLoan
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              mobile={mobile}
              right={right}
            />
          </Row>
        </div>
      );
    case 3:
      return (
        <div>
          <Row>
            <Index nextStep={nextStep} prevStep={prevStep}   handleFormData={setFormData}
              values={formData} mobile={mobile}   right={right}/>
          </Row>
        </div>
      );
    default:
      return <div className="App"></div>;
  }
}
