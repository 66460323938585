import React from "react";
import "./SingalPost.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function SinglePost() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [post, setPost] = useState({});
  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("https://www.loanloom.com/loan_phpForm/blog_api.php?id=" + path);
      setPost(res.data);
    };
    getPost();
  }, [path]);

  return (
    <div className="singlePost">
      <div className="singlePostWrapper">
        {post.photo && (
          <img className="singlePostImg" src={post.photo} alt="single" />
        )}
        <h1 className="singlePostTitle">
          {post.title} Loan
          <span className="singlePostInfo">
            {new Date(post.createdAt).toDateString()}
          </span>
        </h1>
        <p className="singlePostDesc">{post.desc}</p>
        {post.queAns && post.queAns.map((e, index) => (
          <React.Fragment key={index}>
            <h2 className="singlePostQuestions">{e.que}</h2>
            <h2 className="singlePostAnswer">{e.ans}</h2>
          </React.Fragment>
        ))}
        <h1 className="singlePostTitle1">Conclusion</h1>
        <p className="singlePostDesc">{post.conclusion}</p>
      </div>
      <Link to="/blog" onClick={scrollTop} style={{ textDecoration: 'none' }}>
        <button className="gotohome">Go Back</button>
      </Link>
    </div>
  );
}
