import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AverageMonthlyIncome from "../PurchaseAndConstruct/AverageMonthlyIncome";
import AnnualBonus from "../PurchaseAndConstruct/AnnualBonus";
import AboutProperty from "../PurchaseAndConstruct/AboutProperty";
import TotalEmi from "../PurchaseAndConstruct/TotalEmi";
import NameofBuilder from "../PurchaseAndConstruct/NameofBuilder";
import CostofHome from "../PurchaseAndConstruct/CostofHome";
import GenderSel from "../PurchaseAndConstruct/GenderSel";
import Thank from "../../../../../Forms/FormsStructure/Thank";

const Index = ({ prevStep: prevPreviousStep ,handleFormData:setFormData,values:formData,mobile,right:haventIdentify}) => {
  const [step, setstep] = useState(1);
  const [right, setRight] = useState(true);

  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if (step === 1) {
      prevPreviousStep();
    } else {
      setstep(step - 1);
    }
  };

  switch (step) {
    case 1:
      return (
        <div>
          <Row>
            <Col md={{ span:7, offset:3}} className='m-auto'>
              <GenderSel nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData} right={haventIdentify} />
            </Col>
          </Row>
        </div>
      );
    case 2:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className='m-auto'>
              <AnnualBonus nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData} right={right}/>
            </Col>
          </Row>
        </div>
      );
    case 3:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}  className='m-auto'>
              <AverageMonthlyIncome nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData}  right={right}/>
            </Col>
          </Row>
        </div>
      );

    case 4:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}  className='m-auto'>
              <TotalEmi nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData} mobile={mobile}  right={right}/>
            </Col>
          </Row>
        </div>
      );

    case 5:
      return (
        <div>
          <Row>
            <Col sm={2} md={6} lg={12}  className='m-auto'>
              <AboutProperty nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData}  right={right}/>
            </Col>
          </Row>
        </div>
      );

    case 6:
      return (
        <div>
          <Row>
            <Col  md={{ span: 7, offset: 3 }} className='m-auto'>
              <NameofBuilder nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData}  right={right} />
            </Col>
          </Row>
        </div>
      );

    case 7:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }}  className='m-auto'>
              <CostofHome nextStep={nextStep} prevStep={prevStep} handleFormData={setFormData}
              values={formData} mobile={mobile}  right={right} />
            </Col>
          </Row>
        </div>
      );
      case 8:
      return (
        <div>
          <Row>
              <Thank
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
          </Row>
        </div>
      );
      default:
        return <div className="App"></div>;

  }
};

export default Index;
