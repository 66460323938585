import { useEffect, useState } from "react";
import Header from "../Header/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftSide from "../LeftsideRightSide/LeftSide";
import RightSide from "../LeftsideRightSide/RightSide";
import axios from "axios";
import { useLocation } from "react-router";
const Home = () => {
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://www.loanloom.com/loan_phpForm/blog_api_all_data.php" + search
      );
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);
  return (
    <>
      <Header/>
      <div style={{ padding: "2rem" }}>
        <Row>
          <Col md={9} sm={true}>
            <LeftSide posts={posts} />
          </Col>
          <Col md={3} sm={true}>
            <RightSide posts={posts}/>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;
