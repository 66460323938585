import { useState} from "react";
import { questions } from "./AccordianApi";
import MyAccordian from "./MyAccordian";
import "./Accordian.css";
import NavBar from "../Shared/Navbar/NavbarMain/Navbar";



const Accordian = (props) => {
  const [data, setData] = useState(questions);
  return (
    <>
        <NavBar/>
        <div className="body" style={{paddingTop:'99px'}}>
        <h2 className="heading" style={{textAlign:"center",color:"#2c3148",fontFamily:'poppins'}}>Frequently Asked Questions </h2>
        <section className="main-div">
          {data.map((curElem) => {
            return <MyAccordian key={curElem.id} {...curElem} />;
            //here the spread operator is use to pass the the properties of the questions api
          })}
        </section>
      </div>
    </>
  );
};

export default Accordian;
