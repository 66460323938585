import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="headermain">
      <div className="headerTitles">
        <span className="headerTitleLg">Loan Loom</span>
        <span className="headerTitlesm">Blog</span>
      </div>
      <img
        className="headerImg"
        src="https://img.freepik.com/premium-photo/four-wooden-blocks-with-letters-blog-bright-surface-gray-table-business-concept_384017-3526.jpg?size=626&ext=jpg&uid=R89945875&ga=GA1.2.330176121.1673590231&semt=sph"
        alt="header"
      />
    </div>
  );
};

export default Header;
