import * as React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NetSalary from "./NetSalary";
import Pincode from "./Pincode";
import CompanyWorkFor from "./CompanyWorkFor";
import DocSalaried from "./DocSalaried";
import PLAmount from "./PLAmount";
import Thank from "../../../../../Forms/FormsStructure/Thank";

export default function SalariedPL({
  prevStep: preprevStep,
  handleFormData: setFormData,
  values: formData,
  mobile
}) {
  const [right, setRight] = useState(true);

  const [step, setstep] = useState(0);

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true);
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false);
    if (step === 0) {
      preprevStep();
    } else {
      setstep(step - 1);
    }
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="m-auto">
                <CompanyWorkFor
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="m-auto">
                <NetSalary
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
  
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <PLAmount
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 3:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Pincode
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 4:
      return (
        <div className="formdiv">
          <DocSalaried
            prevStep={prevStep}
            nextStep={nextStep}
            handleFormData={setFormData}
            values={formData}
            right={right}
          />
        </div>
      );
      case 5:
        return (
          <div className="formdiv">
            <Thank
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={right}
            />
          </div>
        );

    default:
      return <div className="formdiv"></div>;
  }
}
