import * as React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import Img1 from "../../../../../../../Assets/tech-support.png";
import Img1forPhone from "../../../../../../../Assets/tech-support1.png";
import Img2 from "../../../../../../../Assets/worker.webp";
import Img2forPhone from "../../../../../../../Assets/worker.png";
import Img4 from "../../../../../../../Assets/team.png";
import Img4forPhone from "../../../../../../../Assets/team1.png";
import Img3 from "../../../../../../../Assets/freedom.png";
import Img3forPhone from "../../../../../../../Assets/freedom1.png";
import Box from "@mui/material/Box";
import SalariedPL from "./SalariedPL/SalariedPL";
import "./Home.css";
import ProfessionalPL from "./Professional/ProfessionalPL";
import SelfEmployePL from "./SelfEmployeed/SelfEmployePL";
import Fade from "react-reveal/Fade";
import Index1 from "./Independent/Index1";

export default function Home({ handleFormData:setFormData,mobile,values:formData}) {
  const [right, setRight] = useState(true);
  const [step, setstep] = useState(0);

  const nextStep = () => {
    setRight(true)
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
   setstep(0)
  }

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
        <Fade duration={1000} right={right} left={!right}>
          <Box>
            <h4 style={{fontFamily:'poppins',textAlign:"center"}}>Type of employment</h4>
            <div style={{ marginTop: "40px" }}>
              <label>
                <img src={Img1} className="image" alt="Home" />
                <img src={Img1forPhone} className="imageforPhone" alt="HomeImg" />
                <input type="radio"className="radioPL" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'salaried'})
                  setstep(1)
                }} />
                <h6 className="typeforPL">Salaried</h6>
              </label>

              <label>
                <img src={Img2} className="image" alt="Home" />
                <img src={Img2forPhone} className="imageforPhone" alt="HomePage" />
                <input type="radio" className="radioPL" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'professional'})
                  setstep(2)
                }} />
                <h6 className="typeforPL">Professional</h6>
              </label>
              <label>
                <img src={Img3} className="image" alt="HomePage" />
                <img src={Img3forPhone} className="imageforPhone" alt="HomePage" />
                <input type="radio" className="radioPL"  value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'selfEmployee'})
                  setstep(3)
                }} />
                <h6 className="typeforPL">Self Employed</h6>
              </label>
              <label>
                <img src={Img4} className="image" alt="HomePage" />
                <img src={Img4forPhone} className="imageforPhone" alt="HomePage" />
                <input type="radio" className="radioPL"  value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'Independent_worker'})
                  setstep(4)
                }}   />
                <h6 className="typeforPL">Independent Worker</h6>
              </label>
            </div>
            </Box>
            </Fade>
      );
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col className="m-auto">
                <SalariedPL
                  nextStep={nextStep}
                  prevStep={prevStep} 
                  handleFormData={setFormData}
                  values={formData}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col>
                <ProfessionalPL
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
      case 3:
        return (
          <div className="formdiv">
            <Container>
              <Row>
                <Col>
                  <SelfEmployePL
                    prevStep={prevStep}
                    nextStep={nextStep}
                    handleFormData={setFormData}
                    values={formData}
                    mobile={mobile}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        );
        case 4:
          return (
            <div className="formdiv">
              <Container>
                <Row>
                  <Col className='m-auto'>
                    <Index1
                     prevStep={prevStep}
                      nextStep={nextStep}
                      handleFormData={setFormData}
                      values={formData}
                      right={right}
                      mobile={mobile}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          );

    default:
      return <div className="formdiv"></div>;
  }
}
