import React from 'react';
import about from '../../../../Assets/about.webp';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './About.css';

const About = () => {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
        <section className="about overflow-hidden py-3 mt-1">
            <div className="row col-md-12 p-3">
                <div className="col-md-6 img">
                    <Fade duration={2000} left>
                        <LazyLoadImage
                            src={about}
                            alt='contactusImg'
                            width={412}
                            height={250}
                            className="aboutusimage"
                        />
                    </Fade>
                </div>
                <div className="col-md-6">
                    <Fade duration={2000} right>
                        <div>
                            <p className="miniTitle">about us</p>
                            <h1 className="headerTitleforAbout">
                                How We Can Help Your<span>&nbsp;Loan</span> Process
                            </h1>
                            <Typography
                                component='p'
                                className='abtpara'
                                style={{ color: "#9e9b9b", fontSize: "18px" }}
                            >
                                <b>LoanLoom, your trusted source for quick loans in India,</b> offers top-notch financial aid. With quick
and easy loan services, it simplifies individuals&#39; lives through excellent assistance.
                            </Typography>
                            <Link to="/AboutUs">
                                <Box
                                    className="KnowmorebranBtn"
                                    onClick={scrollTop}
                                    style={{ backgroundColor: '#480082', color: '#FFFFFF' }}
                                >
                                    Know More
                                </Box>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default About;
