import * as React from "react";
import { styled } from "@mui/material/styles";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Slider } from "@mui/material";
import Fade from "react-reveal/Fade";
import MuiInput from "@mui/material/Input";
import "../../../Forms/FormsStructure/form.css";
import { useFormik } from "formik";
import { InComeValid } from "../../../Forms/FormsStructure/Schemas";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 20000000, label: "> ₹ 2cr" },
  ],
};

const Latest_Profit = ({ nextStep, handleFormData, values: value,prevStep,right }) => {
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: InComeValid,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        Income: formValues.Income,
      });
      nextStep();
    },
  });


  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x:"calc(50% - 50%)" }}>
    <Fade duration={1000} right={right} left={!right}>
      <Form>
        <Box style={{ marginTop: "30px" }}>
          <h4 style={{ fontFamily: "poppins" }}>Latest year's profit after tax</h4>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "20px",fontFamily:'poppins' }}
          >
            <Grid item xs={12}>
              <strong>&#8377; </strong>
              <Input
                value={values.Income}
                style={{fontFamily:'poppins'}}
                size="small"
                onChange={(e) => setFieldValue("Income",e.target.value)}
                onBlur={handleBlur}
                inputProps={{
                  step: 100,
                  min: 0,
                  max: 20000000,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
              {errors.Income && touched.Income ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14.5px",
                          fontFamily: "Comic Sans",
                          marginTop:"20px"
                        }}
                      >
                        {errors.Income}
                      </p>
                    ) : null}
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Slider
               sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
                value={values.Income || 0}
                marks={IncomeSlider.marksAmt}
                max={20000000}
                onChange={(e,newValue) => setFieldValue("Income", newValue)}
                aria-labelledby="input-slider"
              ></Slider>
            </Grid>
          </Grid>
        </Box>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button type="button" className="btn-prev1" onClick={prevStep}>
              &#8592; Previous
              </button>

              <button className="btn-one1" type="submit" onClick={handleSubmit}>
              Next &#8594;
              </button>
            </div>
      </Form>
      </Fade>
   // </motion.div>
  );
};
export default Latest_Profit;

