import React from 'react';
import NavBar from '../../../Shared/Navbar/NavbarMain/Navbar';
import './Header.css'
import Hero from '../../Hero/HeroMain/Hero';

const Header = () => {
    return (
        <section className='header'>
            <NavBar/>
            <Hero/>
        </section>
    );
};

export default Header;