import React from 'react';
import Service from './Service'
import Menu from '../ServiceapiNav/serviceApi'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Services = () => {
  const product = Menu.map((val,ind)=><Service
  key={ind}
  imgSrc ={val.imgsrc}
  name ={val.name}
  description ={val.description}
  />)
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }; 
  return (
    <>
    <div className='my-5'> 
    <h1 className="text-center miniTitle">SERVICES</h1> 
    <h2 className='text-center headerTitle'>We Are Offering</h2>
    </div>
    <Carousel responsive={responsive}>
      {product}
</Carousel>
    
  
    </>
  )
}

export default Services
