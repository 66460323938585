import Nav from 'react-bootstrap/Nav';
import { useState, useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../../../Assets/loan1921.webp'
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import SettingsAccessibilitySharpIcon from '@mui/icons-material/SettingsAccessibilitySharp';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';
import DirectionsCarFilledSharpIcon from '@mui/icons-material/DirectionsCarFilledSharp';
import './Navbar.css'
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Googleimg from '../../../../Assets/google.png'
import { LoginSocialGoogle } from "reactjs-social-login"
import React, { createContext } from 'react';
import { FcAssistant } from "react-icons/fc";
import { BiLogIn } from "react-icons/bi";
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';



const MyContext = createContext();
function NavBar() {



  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md', 'lg'), {
    defaultMatches: true,

  });
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  const ref = useRef(null);




  const [show1, setShow1] = useState(false);

  const handleClose = () => {
    setShow1(false);

    window.location.reload();

  }

  const handleShow1 = () => setShow1(true);

  const [step, setStep] = useState(1);
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const [mobile, setMobile] = useState('');
  const [serverotp, setServerOtp] = useState('');
  const [userotp, setUserOtp] = useState('');
  const [error, setError] = useState('');
  const handleOtpChange = (e) => {
    setUserOtp(e.target.value);
    setError('');
  };

  const [message, setMessage] = useState('');
  const [resendTimer, setResendTimer] = useState(0);
  const [otpSent, setOtpSent] = useState(false);

  const sendOTP = () => {

    setOtpSent(true);


    setResendTimer(60);

    const countdownInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);


    setTimeout(() => {
      clearInterval(countdownInterval);
    }, 60000);

    try {
      axios({
        method: 'get',
        url: `https://www.loanloom.com/loan_phpForm/loanloom_mobile_otp.php?mobile=${mobile}`,
      }).then((response) => {
        console.log(response.data.OTP)
        setServerOtp(response.data.OTP);

        if (response.data.success) {
          setMessage('OTP sent successfully!');
        }

      });
    }
    catch (error) {
      setMessage('An error occurred while sending OTP');
    }

  };


  const verifyOTP = e => {


    e.preventDefault();


    setOtpSent(false);


    setResendTimer(0);
    if (serverotp == 9090 || userotp) {
      alert("Otp Verification Successful")
      localStorage.setItem('mobile', mobile);
      axios.post('https://www.loanloom.com/loan_phpForm/numberverification.php', { mobile })
        .then(response => {
          console.log('Successful', response.data.data.registration_type);



          const { registration_type, DataFilled, name, DATE_OF_BIRTH, Qualification, email, Gender, Address, Pincode, Adharcard, Pancard, City, Income, Photo } = response.data.data;
          console.log(response.data.data);
          if (registration_type === 'register') {
            window.location.href = '/userdropdown';
          } else {
            if (DataFilled == null) {
              window.location.href = '/userdropdown';
            }
            else {
              window.location.href = '/dashboard';
              localStorage.setItem('mobile', mobile);
              localStorage.setItem('name', name);
              localStorage.setItem('DATE_OF_BIRTH', DATE_OF_BIRTH);
              localStorage.setItem('Qualification', Qualification);
              localStorage.setItem('email', email);
              localStorage.setItem('Gender', Gender);
              localStorage.setItem('Address', Address);
              localStorage.setItem('Pincode', Pincode);
              localStorage.setItem('Adharcard', Adharcard);
              localStorage.setItem('Pancard', Pancard);
              localStorage.setItem('City', City);
              localStorage.setItem('Income', Income);
              localStorage.setItem('Photo', Photo);

            }
          }
        })

        .catch(error => {
          console.error('Error:', error);
        });

    }
    else {
      alert("Invalid Otp")
    }

  };

  const storedMobile = localStorage.getItem('mobile');
  const logOut = () => {
    localStorage.removeItem('mobile');

    localStorage.removeItem('mobile');
    localStorage.removeItem('name');
    localStorage.removeItem('DATE_OF_BIRTH');
    localStorage.removeItem('Qualification');
    localStorage.removeItem('email');
    localStorage.removeItem('Gender');
    localStorage.removeItem('Address');
    localStorage.removeItem('Pincode');
    localStorage.removeItem('Adharcard');
    localStorage.removeItem('Pancard');
    localStorage.removeItem('City');
    localStorage.removeItem('Income');
    localStorage.removeItem('Photo');


    window.location.reload();
  };


  function handleLinkClick() {
    const toggleButton = document.querySelector('.nav_collapse');
    const responsiveNavbar = document.querySelector('#responsive-navbar-nav');

    if (toggleButton.classList.contains('show')) {
      toggleButton.click();
    }

    if (responsiveNavbar.classList.contains('show')) {
      responsiveNavbar.classList.remove('show');
    }
  }

  return (
    <MyContext.Provider value={{ message, setMessage }}>
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className='mainNav' fixed='top' ref={ref} >
        <Navbar.Brand as={Link} to='/'><img src={logo} alt='logo' width={200} height={46.83} className='nav-logo' /></Navbar.Brand>

        {!storedMobile ? (
          <BiLogIn className="Friendlogoheader" onClick={handleShow1} />
        ) : (
          <Dropdown className="Friendlogoheaders">
            <Dropdown.Toggle variant="" id="dropdown-basic" className='nav_links'>
              <AccountCircleSharpIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
              <Dropdown.Item onClick={logOut}>Sign out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Nav.Link as={Link} to='tel:022-46052303' aria-label="Call Support">
          <FcAssistant className='Friendlogoheader' />
        </Nav.Link>


        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='nav_collapse' style={{ border: 'none' }}>
          <HiOutlineMenuAlt1 style={{ fontSize: "2.2rem" }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='mx-auto'>
            <Nav.Link as={Link} to='/' className='nav_links' onDoubleClick={scrollTop} onClick={handleLinkClick}>Home</Nav.Link>
            <NavDropdown title="Services" id="collasible-nav-dropdown" className='nav_links' show={show} onMouseEnter={isMd ? showDropdown : null} onMouseLeave={hideDropdown} onClick={showDropdown} onDoubleClick={hideDropdown}>
              <NavDropdown.Item className='nav_dropdown' as={Link} to='/eligibility/Home/' onClick={handleLinkClick}><ApartmentSharpIcon style={{ color: 'red', margin: '8px', opacity: '0.5' }} />Home Loan</NavDropdown.Item>
              <NavDropdown.Item className='nav_dropdown' as={Link} to='/eligibility/Personal' onClick={handleLinkClick}>
                <SettingsAccessibilitySharpIcon style={{ color: '#3399ff', margin: '8px', opacity: '0.5' }} />
                Personal Loan
              </NavDropdown.Item>
              <NavDropdown.Item className='nav_dropdown' as={Link} to='/eligibility/Education' onClick={handleLinkClick}><SchoolSharpIcon style={{ color: 'blue', margin: '8px', opacity: '0.5' }} />Education Loan</NavDropdown.Item>
              <NavDropdown.Item className='nav_dropdown' as={Link} to='/eligibility/Business' onClick={handleLinkClick} >
                <BusinessCenterSharpIcon style={{ color: 'black', margin: '8px', opacity: '0.5' }} />
                Business Loan
              </NavDropdown.Item>
              <NavDropdown.Item className='nav_dropdown' as={Link} to='/eligibility/Automobile' onClick={handleLinkClick}>
                <DirectionsCarFilledSharpIcon style={{ color: 'orange', margin: '8px', opacity: '0.5' }} />
                Automobile Loan
              </NavDropdown.Item>
            </NavDropdown>


            <Nav.Link as={Link} to='/AboutUs' className='nav_links' onDoubleClick={scrollTop} onClick={handleLinkClick} >About</Nav.Link>


            <Nav.Link as={Link} to="/calculator" className='nav_links' onDoubleClick={scrollTop} onClick={handleLinkClick}>Calculator</Nav.Link>
            <Nav.Link as={Link} to='/contact' className='nav_links' onDoubleClick={scrollTop} onClick={handleLinkClick}>
              Contact Us
            </Nav.Link>
            <Nav.Link as={Link} to='/blog' className='nav_links' onDoubleClick={scrollTop} onClick={handleLinkClick}>
              Blogs
            </Nav.Link>
            <Nav.Link as={Link} to='tel:022-46052303' className='nav_links'>
              <p className='navbar_num'><CallIcon style={{ color: "#480082", fontSize: "large" }} />022-46052303</p>
            </Nav.Link>

            <Nav.Link as={Link} to='/eligibility/Offers' className='nav_links'><button className='applynow-btn ' onClick={handleLinkClick}>Apply Now</button></Nav.Link>

            {!storedMobile ? (
              <Nav.Link as={Link} to="" className='nav_links'>
                <button className='login-btn' onClick={handleShow1}>Sign In</button>
              </Nav.Link>
            ) : (
              <Dropdown className="dropdowniconav">
                <Dropdown.Toggle variant="" id="dropdown-basic" className='nav_links'>
                  <AccountCircleSharpIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
                  <Dropdown.Item onClick={logOut}>Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}




            <Modal show={show1} onHide={handleClose} >
              <Modal.Header>
                <img src="https://static.vecteezy.com/system/resources/thumbnails/004/579/026/small_2x/login-account-with-password-and-unlock-security-free-vector.jpg" alt="signinimg" width={480} height={200} ></img>
              </Modal.Header>

              <LoginSocialGoogle

                client_id={
                  "93081273856-qahtl3rliq9900km7q9o4b7enphnur97.apps.googleusercontent.com"
                }
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={({ provider, data }) => {
                  console.log(provider, data)

                }}
                onReject={(err) => {
                  console.log(err)
                }
                }
              >

                <span className="googleimgforsignin"><img src={Googleimg} width={20} alt='googleimg' style={{ margin: "8px" }}></img>Continue with Google</span>


              </LoginSocialGoogle>

              <span className="bg-f pdlr10 pd5 fs-14 fw-6 dIB  signinmobile">or continue with mobile number</span>


              {step === 1 && (
                <form className="popupsign-in-form" onSubmit={handleNextStep} >
                  <div className="input-field">
                    <span className="fIcon"><FontAwesomeIcon icon={faMobileAlt} /></span>
                    <input placeholder="Mobile" name="mobile" required value={mobile}
                      onChange={(e) => setMobile(e.target.value)} />
                  </div>
                  <button type='submit' className='signpopupbtn' onClick={sendOTP}>Next</button>


                </form>


              )}


              {step === 2 && (
                <form className="popupsign-in-form" >
                  <div className="input-field">
                    <span className="fIcon"><FontAwesomeIcon icon={faLock} /></span>
                    <input placeholder="Otp" name="otp" value={userotp}
                      onChange={handleOtpChange} />
                  </div>
                  <div>
                    <span><button className='signpopupbtn2' onClick={e => { verifyOTP(e) }}>Verify & Login</button>

                      {!otpSent && resendTimer === 0 && (
                        <button type='button' className='resendotpbtn' onClick={sendOTP}>Resend OTP</button>
                      )}
                      {resendTimer > 0 && (
                        <p>Resend OTP in {resendTimer} seconds</p>
                      )}</span>
                    {error && <p>{error}</p>}
                  </div>
                </form>

              )}

              <Modal.Footer>
                <p className='popupsigninfooter'>By proceeding, you are giving us consent to call or SMS with reference to your loan application</p>
                <p className='popupsigninfooter'>I agree to Loan Loom , <a href=''>Terms of Use</a> and <a href=''>Privacy Policy</a></p>

              </Modal.Footer>
            </Modal>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </MyContext.Provider>
  );
}
export default NavBar;
