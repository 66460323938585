import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Index from '../../Service/PersonalLoan/PersonalLoan/Index';
import Type from '../../Service/HomeLoan/Type';
import EducationHome from '../../Service/EducationLoan/EducationLoan/EducationHome';
import BussinessHome from '../../Service/BusinessLoan/BussinessLoan/BussinessHome'
import NotAvailable from '../FormsStructure/NotAvailable';

export default function IndexType({ type, values, response, prevStep, right }) {
  switch (type) {
    case 'Home':
      return (
        <Container>
          <Row>
            <Col>
              <Type mobile={values} right={right} />
            </Col>
          </Row>
        </Container>
      );
    case 'Personal':
      return (
        <div className='formdiv'>
          <Index type={type} mobile={values} response={response} prevStep={prevStep} />
        </div>
      );
    case 'Education':
      return (
        <div className='formdiv'>
          <EducationHome type={type} mobile={values} prevStep={prevStep} right={right} />
        </div>
      );
    case 'Business':
      return (
        <div className='formdiv'>
          <BussinessHome type={type} mobile={values} prevStep={prevStep} right={right} />
        </div>
      );
    case 'Automobile':
      return (
        <div className='formdiv'>
          <NotAvailable />
        </div>
      );
    default:
      return <div className='formdiv'></div>;
  }
}
