export const questions = [
    {
        id: 1,
        question: 'How is my loan eligibility  calculated?',
        answer:'Loan eligibility is primarily dependent on the income and repayment capacity of the individual. many other factors that determine the eligibility of loans such as age, financial position, credit history, credit score,etc.'
    },
    {
        id: 2,
        question: 'What is the minimum and maximum age limit offered as a loan?',
        answer:'Minimum and maximum age for personal loan is 21 years to 65 years.'
    },
    {
        id: 3,
        question: 'How long does it take to disburse a loan?',
        answer:'Loan disbursement time can be up to 1-2 working days after approval. You can then go to the lender and get a cheque for the loan amount.'
    },
    {
        id: 4,
        question: 'What are the advantages of applying for a loan online?',
        answer:"There are many advantages of applying home loan:\n Minimal Documentation, Low interest rates, Quick Disbursal\n Faster process."
    },
    {
        id: 5,
        question: 'What is meant by pre closure?',
        answer:'Pre-closure is the process when one repays the loan before the loan tenure ends. Some lenders do levy a penalty for preclosing the loan. However, pre-closure at times does help in lowering the interest rates and debt burden. The banks have different lock-in periods before which one can close the loan.'
    },
    {
        id: 6,
        question: 'Does credit score affect our approval of loan?',
        answer:'Your credit score measures your creditworthiness and reflects your ability to repay the loan. It is a benchmark on which your risk of default is calculated. Therefore, if you have a poor credit score or a history of bankruptcy, its unlikely for you to be approved for any loan, including Loan Against Property.'
    },
    {
        id: 7,
        question: 'What is the repayment method for a loan ?',
        answer:'Loan repayment is the act of settling an amount borrowed from a lender along with the applicable interest amount. Generally, the repayment method includes a scheduled process (called loan repayment schedule) in the form of equated monthly instalments or EMIs.'
    },
    {
        id: 8,
        question: 'Is foreclosure of a loan permitted?',
        answer:'Yes foreclosure for the loan is granted, but there are some charges depending on the bank and the amount of loan '
    },
    {
        id: 9,
        question: 'Are there any charges for foreclosure of loans?',
        answer:'Yes, \n Borrowers may be allowed to foreclose or prepay their loan 6 months after the date it has been disbursed, without any prepayment penalty. A charge of 2.5% + GST will be levied on any prepayment amount that is over 25% of the principal due.  '
    },
    {
        id: 10,
        question: 'Will there be any tax benefits after approval of an education loan?',
        answer:'yes, \n there will be benefits on tax if you take an educational loan. The benefit will be interest paid on the loan and will be claimed as deductions according to   the Income-tax Act of India   '
    },
    {
        id: 11,
        question: 'Can a person get an education loan in cash?',
        answer:'No, as the bank pays the entire amount of loan to the college or educational institution .'
    },
    {
        id: 12,
        question: 'Is there any other benefit that comes with the education loan?',
        answer:'Yes there are  many benefits on education loans, the applicant will avail tax benefits. '
    },
    {
        id: 13,
        question: 'Will the loan amount cover the 100% of education expenses?',
        answer:'Yes, many banks cover the 100% of education expense on loans. '
    },
    {
        id: 14,
        question: 'Do the loan taker have to pay the processing fees?',
        answer:'Yes, the applicant has to pay the processing fees as it bears the administrative cost of the bank and the rates are 0.50% 3.50% on the loan amount. '
    },
    {
        id: 15,
        question: 'What courses are covered in educational loans?',
        answer:'Most of the courses are covered in India like PHD,engineering,specialisation in training courses etc.  '
    },
    {
        id: 16,
        question: 'Is it  possible to apply for a personal loan jointly?',
        answer:'No the banks or financial does not allow anyone to become a co borrower, but some banks offer the same with parents or spouse and even some bank allow co borrower with siblings. '
    },
    {
        id: 17,
        question: 'How do I get the lowest interest rates on a personal loan?',
        answer:'Your credit score decide nature of the loan.the person having a score of 700-750 is a good candidate and get the good interest rates. '
    },
    {
        id: 18,
        question: 'What is the minimum interest rate on a personal professional  loan?',
        answer:'The Lowest rate of interest is around 10.85, the reason behind such high interest rate is the there is no  collateral security with lender .'
    },
    {
        id: 19,
        question: 'What are the benefits of a professional loan for doctors, CS, and CAs?',
        answer:'They get many benefits in professional loan such as no collateral security with the lender.'
    },
    {
        id: 20,
        question: 'Do You  Have to pledge assets to get a professional loan?',
        answer:'No , you do not have to pledge any assets to get a professional loan  as it is a unsecured loan.'
    }

]