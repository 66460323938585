import React from "react";
import { useState } from "react";
import "../index.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GenderSel from "./GenderSel";
import PropertyBasedCity from "./PropertyBasedCity";
import AnnualBonus from "./AnnualBonus";
import TotalEmi from "./TotalEmi";
import AboutProperty from "./AboutProperty";
import NameofBuilder from "./NameofBuilder";
import CostofHome from "./CostofHome";
import AverageMonthlyIncome from "./AverageMonthlyIncome";

import { useFormik } from "formik";
import Thank from "../../../../../Forms/FormsStructure/Thank";
import { CityValidate } from "../../../../../Forms/FormsStructure/Schemas";


const CurrentCity = ({
  prevStep: prevPreviousStep,
  handleFormData: setFormData,
  values: formData,
  mobile,
  right:currentRight
}) => {

  const [step, setstep] = useState(0);
  const [right, setRight] = useState(true);
  const {
    values,
  } = useFormik({
    initialValues: { formData },
    validationSchema: CityValidate,

    onSubmit: (formValues) => {
      setFormData({
        ...values,
        currentCity: formValues.currentCity,
      });
      nextStep();
    },
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if (step === 0) {
      prevPreviousStep();
    } else {
      setstep(step - 1);
    }
  };

  switch (step) {
    case 0:
      return (
        <Row>
          <Col md={{ span: 8, offset: 2 }} sm={6}>
            <GenderSel
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={currentRight}
            />
          </Col>
        </Row>
      );
    case 1:
      return (
        <Row>
          <PropertyBasedCity
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={setFormData}
            values={formData}
            right={right}
          />
        </Row>
      );
    case 2:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <AnnualBonus
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );
    case 3:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}>
              <AverageMonthlyIncome
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );
    case 4:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}>
              <TotalEmi
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );
    case 5:
      return (
        <div>
          <Row>
            <AboutProperty
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={setFormData}
              values={formData}
              right={right}
            />
          </Row>
        </div>
      );
    case 6:
      return (
        <div>
          <Container>
            <Row>
              <Col md={6} className='m-auto'>
                <NameofBuilder
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 7:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <CostofHome
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );
      case 8:
      return (
        <div>
          <Row>
              <Thank
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                right={right}
                mobile={mobile}
              />
          </Row>
        </div>
      );
    default:
      return <div className="App"></div>;
  }
};

export default CurrentCity;
