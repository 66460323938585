import { Grid } from "@mui/material"
import img from '../../../../../../Assets/home3.webp'
import PersonalFeatureImagee from '../../../../../../Assets/PersonalLoanFeatureImage.jpg';
import PersonalLoanBenefitsImage from '../../../../../../Assets/PersonalBenefitsImageLoan.jpg';
import PersonalEligibilityLoanImagee from '../../../../../../Assets/PersonalEligibilityLoanImage.jpg';
import PersonalLoanDocumentsImage from '../../../../../../Assets/PersonalLoanDocumentsImageee.jpg';
import PersonalRenovationLoanImagee from '../../../../../../Assets/PersonalRenovationLoanImage.jpg';
import PersonalMedicalLoanImage  from '../../../../../../Assets/PersonalMedicalImage.jpg';
import PersonalEductionLoanImagee from '../../../../../../Assets/PersonalEductionLoanImage.jpg';
import PersonalCarloanImage from '../../../../../../Assets/PersonalCarloanImagee.jpg';
import PersonalWeedingLoanImagee from '../../../../../../Assets/PersonalWeedingLoanImage.jpg';
import PersonalApplicationloanImagee from '../../../../../../Assets/PersonalApplicationloanImage.jpg';
import './personalLoanContent.css'
const PersonalLoanContent = () => {
  return (
    <>
 <div className='containerforPersonal'>
    <Grid container padding={0}>
      <Grid item  lg={6} xs={12} md={6}>
        <img src={img} className="Personal_loanimg" alt='Personal'/>
      </Grid>
      <Grid item lg={6} xs={12} md={6}>
      <h1 className="personal_Heading">Personal Loan</h1>
        <p className="personal_paragraph">
        <b>Get an instant personal loan by Loanloom,</b> Personal loans can be beneficial for both
businesses and individuals. For businesses, personal loans can help with growing your
business, improving cash flow, and meeting your financial obligations. Likewise, personal
loans can also help individuals get out of a financial bind. If you are stuck short of money, a
personal loan is a feasible way to obtain the capital you need, one that will not require a
credit check. Individuals can <b>get an instant personal loan by loanloom</b> to meet your
financial needs quickly and conveniently. Customers acquire a personal loan for their
personal use, such as the use of a car. After the loan is issued, the borrower pays the creditor
back in monthly instalments. Personal loans are not secured by collateral such as a house or a
car. A borrower can use a personal loan for several reasons. Personal loans are sometimes
used to pay off credit card debts. This is advantageous because the interest rates that come
with credit cards are much higher than those of a personal loan. It is the ideal choice for
people to <b>get an instant personal loan by loanloom.</b>
        </p>
      </Grid>
    </Grid>
    </div>

    <Grid container>
        <Grid item lg={4} md={4}>
        <img className='PersonalFeatureImage' alt='logo' src={PersonalFeatureImagee} ></img>
        <h2 className="PersonalFeatureTitle"><b>Features of Personal Loan</b></h2>
        <ul style={{listStyleType:"disc"}}>
        <li className="personalFeatureParagraph">Flexible repayment.</li>
        <li className="personalFeatureParagraph">Rapid disbursement facility of loan.</li>
        <li className="personalFeatureParagraph">Less documentation.</li>
        <li className="personalFeatureParagraph">Best tenure facility.</li>
        <li className="personalFeatureParagraph">Applicants always get better services from us.</li>
        </ul>
        </Grid>

        <Grid item lg={4} md={4} className='PersonalBenefitsGrid'>
          <img className='PersonalDocumentsImage' alt='logo' src={PersonalLoanDocumentsImage}></img>
              <h2 className="PersonalDocumentTitle"><b>Documentation</b></h2>
              <ul style={{listStyleType:"disc"}}>
                <li className="PersonalDocumentParagraph">Aadhar card or pan card mandatory.</li>
                <li className="PersonalDocumentParagraph">Last three months’ banking details.</li>
                <li className="PersonalDocumentParagraph">Customer salary slip is required for it.</li>
                <li className="PersonalDocumentParagraph">Require cibil score documents.</li>
                <li className="PersonalDocumentParagraph">Permanent address of the customers.</li>
                <li className="PersonalDocumentParagraph">Requirement of personal email ids or 2 passport photographs.</li>
              </ul>
          </Grid>
        
        <Grid item lg={4} md={4} className='PersonalEligibilityGrid'>
        <img className='PersonalEligibilityImage' alt='logo' src={PersonalEligibilityLoanImagee}></img>
        <h2 className="PersonalEligibilityTitle"><b>Eligibility Criteria for Personal Loan</b></h2>
              <ul style={{listStyleType:"disc"}}>
                <li className="PersonalEligibilityParagraph">Age limit-21 years.</li>
                <li className="PersonalEligibilityParagraph">Require a good cibil score of up to 750.</li>
                <li className="PersonalEligibilityParagraph">Individuals must have a saving account in any bank.</li>
                <li className="PersonalEligibilityParagraph">Only a Salaried person can apply for the loan.</li>
                <li className="PersonalEligibilityParagraph">Indian residential or NRI both can apply for a personal loan.</li>
              </ul>
        </Grid>

      </Grid>


      <Grid container>
        <Grid item lg={6} md={6}>
        <img className='PersonalLoanBenImage' alt='logo' src={PersonalLoanBenefitsImage}></img>
        </Grid>
      <Grid item lg={6} md={6} className='PersonalBenefitsGrid'>
        <h2 className="PersonalBenefitsTitle"><b>Benefits of Personal Loan</b></h2>
        <ul style={{listStyleType:"disc"}}>
          <li className='PersonalBenefitsParagraph'>
            Loanloom personal loans are beneficial in managing the debts of their customers properly.
          </li>

          <li className='PersonalBenefitsParagraph'>
          <b>Get an Instant Personal Loan by Loanloom</b> and it provides the best flexibility opportunity
regarding their loan services in the best way.
          </li>

          <li className='PersonalBenefitsParagraph'>
            It is also helpful in maintaining the credit score of the customers reliably.
          </li>

          <li className='PersonalBenefitsParagraph'>
            In case of any emergency personal loan is the best option to acquire extra funds.
          </li>

          <li className='PersonalBenefitsParagraph'>
          Best benefit to <b>get an instant personal loan by loanloom</b> is that it does not require any
collateral security for it.
          </li>
          </ul>
          </Grid>
      </Grid>


        <h2 className="TypesOfPersonalLoanTitle"><b>Types Of Personal Loan</b></h2>

        <Grid container>
            <Grid item lg={4} md={4}>
            <img className='PersonalRenovationImage' alt='logo' src={PersonalRenovationLoanImagee}></img>
            <h4 className="PersonalRenovationTitle"><b>Home renovation purposes</b></h4>
            <p className="PersonalRenovationParagraph" >
            <b>Get an instant personal loan by loanloom</b> for the renovation of the home or improvement
of the home. Loanloom is the best provider of personal loans customers must take advantage
of it.
            </p>
            </Grid>

            <Grid item lg={4} md={4} className="PersonalMedicalGrid">
            <img className='PersonalMedicalImage' alt='logo' src={PersonalMedicalLoanImage  }></img>
            <h4 className="PersonalRenovationTitle"><b>Medical facilities</b></h4>
            <p className="PersonalRenovationParagraph">
            Individuals can <b>get an instant personal loan by loanloom</b> for medical in an emergency at a
low-interest rate.
            </p>
            </Grid>

            <Grid item lg={4} md={4} className="PersonalEductionGrid">
            <img className='PersonalEductionnImage' alt='logo' src={PersonalEductionLoanImagee}></img>
            <h4 className="PersonalRenovationTitle"><b>Education</b></h4>
            <p className="PersonalRenovationParagraph">
              Loan loom is the best loan provider in India that provide quick or
              flexible education personal loan facility to their customers in an
              appropriate manner.
            </p>
            </Grid>
        </Grid>

        <Grid container>
        <Grid item lg={6} md={6} className="PersonalCarloanGrid">
            <img className='PersonalCarloanImage' alt='logo' src={PersonalCarloanImage}></img>
            <h4 className="PersonalRenovationTitle"><b>Car loans</b></h4>
            <p className="PersonalRenovationParagraph">It also allows customers to acquire a personal loan for their car.</p>
            </Grid>

            <Grid item lg={6} md={6} className="PersonalWeedingGrid">
            <img className='PersonalWeedingImage' alt='logo' src={PersonalWeedingLoanImagee}></img>
            <h4 className="PersonalRenovationTitle"><b>Personal loan for weddings</b></h4>
            <p className="PersonalRenovationParagraph">
              If the customers do have not much amount for their weddings and
              want a personal loan for their wedding arrangement then they
              acquire it an easy way.
            </p>
            </Grid>
        </Grid>

        <Grid container className="flex-column-reverse flex-lg-row">
            <Grid item lg={6} md={6}>
            <h2 className="PersonalApplicationTitle"><b>How to apply?</b></h2>
            <ul style={{listStyleType:"disc"}}>
            <li className="PersonalApplicationParagraph">Fill out the online application for a personal loan on the loanloom site.<a href="https://www.loanloom.com">(https://www.loanloom.com)</a></li>
            <li className="PersonalApplicationParagraph">Applicants must fill in all their details on the online site.</li>
            <li className="PersonalApplicationParagraph">After that applicants must proceed with the loan process after clicking the proceed page of the loan loom.</li>
            <li className="PersonalApplicationParagraph">Applicants select the loan amount that it applies for.</li>
            <li className="PersonalApplicationParagraph">After that customers can select their repayment tenure.</li>
            <li className="PersonalApplicationParagraph">Then complete the verification procedure or submit their form.</li>
            </ul>
            </Grid>

            <Grid item lg={6} md={6}>
            <img className='PersonalApplicationImage' alt='logo' src={PersonalApplicationloanImagee} ></img>
            </Grid>
        </Grid>
    </>
  )
}

export default PersonalLoanContent

