import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Personal from "../../Forms/FormsStructure/Personal";
import Otp from "../../Forms/FormsStructure/Otp";
import "../../Forms/FormsStructure/form.css";
import { useParams } from "react-router-dom";
import ApplyNow from "../../Forms/FormsStructure/ApplyNow";
import IndexType from "../../Forms/FormsStructure/IndexType";
import PersonalTwo from "../../Forms/FormsStructure/PersonalTwo.js";

function Slider({ setShow }) {
  const [right, setRight] = useState(true);
  //state for steps
  const [step, setstep] = useState(1);
  const [responseOtp, setResponseOtp] = useState("");
  const { type } = useParams();
  
  const [formData, setFormData] = useState({
    fullName: "",
    age: "",
    mobile: "",
    city: "",
    type: "",
  });

  useEffect(() => {
    if (step > 3) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [step]);


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setRight(true);
    if (type !== "Offers" && step === 3) {
      setstep(step + 2);
    } else {
      setstep(step + 1);
    }
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setRight(false);
    if (type !== "Offers" && step === 3) {
      setstep(step - 2);
    } 
    if(step===5){
      setstep(3);
    }
    else {
      setstep(step - 1);
    }
  };

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin mt-1">
                <Personal
                  nextStep={nextStep}
                  handleFormData={setFormData}
                  values={formData}
                  setOtp={setResponseOtp}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <Otp
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  setOtp={setResponseOtp}
                  responseOtp={responseOtp}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
      case 3:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <PersonalTwo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  otp={responseOtp}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 4:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col sm={2} md={6} lg={12} className="custom-margin">
                <ApplyNow values={formData} rightFade={right}/>
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 5:
      return (
        <div className="formdiv">
          <IndexType values={formData.mobile} type={type} prevStep={prevStep}  right={right}/>
        </div>
      );
    // default case to show nothing
    default:
      return <div className="formdiv"></div>;
  }
}

export default Slider;
