import React from 'react';
import './MyProfile.css'; 
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import dashlogo from '../../../Assets/dashboardlogo.webp'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ProfileCard = () => {
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
  setShow(!show);
}





const logout =()=>{
const storedMobile = localStorage.getItem('mobile');
console.log(storedMobile);
}


const imagePath= localStorage.getItem('Photo');


  return (
    <>
     <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container"> 
            <Nav.Link as={Link} to="/dashboard" className='arrowbtnforbackdash'><ArrowBackIcon style={{fontSize:"2rem"}}/></Nav.Link>
            <a className="navbar-brand" href="#">
                    <div className="d-flex">
                    
                    
                        <Nav.Link as={Link} to="/">
                            <img src={dashlogo} width={200}></img>
                            </Nav.Link>
                            </div>
                        
                    
                </a>
                <div className="collapse navbar-collapse" id="mynav">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        
                            <li>
                            <Button variant="#f68713" onClick={() => setShow(true)}>
                Our Services Product
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogclassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Our Services
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='dashservicelinks'>
                    <Nav.Link as={Link} to="/eligibility/Home">
                    <img src='https://img.freepik.com/premium-vector/house-with-hand-gold-coin-money-illustration-real-estate-investment-concept-building-white-isolated_138676-874.jpg' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Home Loan</li>
                    </Nav.Link>
                    

                    <Nav.Link as={Link} to="/eligibility/Personal">
                    <img src='https://cdn4.iconfinder.com/data/icons/BRILLIANT/shopping/png/400/personal_loan.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Personal Loan</li>
                    </Nav.Link>
                    

                    <Nav.Link as={Link} to="/eligibility/Education">
                    <img src='https://cdn3d.iconscout.com/3d/premium/thumb/invest-for-education-5768769-4833566.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Education Loan</li>
                    </Nav.Link>
                    </div>

                    

                    <div className='dashservicelinks'>
                    <Nav.Link as={Link} to="/eligibility/Business">
                    <img src='https://qph.cf2.quoracdn.net/main-qimg-200f599f08af80a11c92e37341d2b0e2' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Business Loan</li>
                    </Nav.Link>
                    


                    <Nav.Link as={Link} to="/eligibility/Automobile">
                    <img src='https://cdn-icons-png.flaticon.com/512/2505/2505993.png' alt='img' width={100}></img>
                    <li style={{listStyle:'none'}}>Automobile Loan</li>
                    </Nav.Link>
                    </div>
                
                
                </Modal.Body>
            </Modal>

                        </li>

                        
                        <li className="nav-item"> 
                        <Nav.Link as={Link} to="/dashboard"><ArrowBackIcon style={{fontSize:"2rem"}}/></Nav.Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        
              
        

        <div className="profile-card">
  <div>
  
  {imagePath && (
        <img className="profile-picture" src={imagePath} alt="Profile Picture" id="profilePicture"/>
      )}
  </div>
  <div className="profile-details">
    <p className="profile-name">Name: {localStorage.getItem('name')}</p>
    <p className="profile-name">Email: {localStorage.getItem('email')}</p>
    <p className="profile-name">Birth Date: {localStorage.getItem('DATE_OF_BIRTH')}</p>
  </div>
  <div className="profile-details">
    <p className="profile-name">Gender: {localStorage.getItem('Gender')}</p>
    <p className="profile-name">Qualification: {localStorage.getItem('Qualification')}</p>
    <p className="profile-name">Mobile No: {localStorage.getItem('mobile')}</p>
  </div>
  <div className="profile-details">
    <p className="profile-name">City: {localStorage.getItem('City')}</p>
    <p className="profile-name">Pincode: {localStorage.getItem('Pincode')}</p>
    <p className="profile-name">Aadhar Number: {localStorage.getItem('Adharcard')}</p>
  </div>
  <div className="profile-details">
    <p className="profile-name">Income: {localStorage.getItem('Income')}</p>
    <p className="profile-name">Pan Number: {localStorage.getItem('Pancard')}</p>
  </div>
</div>



    </>
  );
};

export default ProfileCard;
