import { Grid } from "@mui/material"
import image from '../../../../../../Assets/car2.webp'
import image2 from '../../../../../../Assets/aautomm.jpg';
import image3 from '../../../../../../Assets/automobii.jpg';
import image7 from '../../../../../../Assets/aautomobb.jpg';
import image5 from '../../../../../../Assets/aautomobill.jpg';
import image9 from '../../../../../../Assets/autoo.jpg';
import './Automobile.css'

const AutomobileLoanContent = () => {
  return (
    <>
<div className='containerforAuto'>
   <Grid container padding={0}> 
   <Grid item lg={6} md={6} xs={12}>
   <h1 className="automobile_Heading">Automobile loan</h1>
   
   <p className="automobile_paragraph" >As per the advanced world and growing technologies <b>accelerate your drive with loanloom
automobile loan.</b> The automobile sector has much importance in the modern world. Automobile
loans are secured loans and customers acquired these loans for purchasing an automobile such as a
new car or a second-hand car for their convenience. <b>Accelerate your drive with loanloom
automobile loan</b> because it provides better financing facilities to its customers at reasonable or
affordable rates. Loanloom provides an option in an automobile to their customer at the best prices
that are most suitable for any kind of customer segment such as high, medium, or lower. It is easy to 
 <b> accelerate your drive with loanloom automobile loan</b> because it is the best provider that provides
the best services or loan facilities in comparison to others.
   </p>
   </Grid>
   <Grid item lg={6} md={6} xs={12}>
   <img className="automobileloanimg" alt="Automobile" src={image}></img>
   </Grid>
   
   </Grid>
   </div>
   
   <Grid container className='FeatureeeMainGrid'>
 <Grid item lg={4} md={4} className='FeatureeGrid'>
 <img className="FeatureAntoImage" alt="logo" src={image2}/>
 <h2 className="FeatureAntotitle"><b>Features of an Automobile Loan</b></h2>
 <ul style={{listStyleType:"disc"}}>
 <li className="FeatureAntoparagraph">Easiest way to <b>accelerate your drive with loanloom automobile</b> loan for purchasing a car.</li>
 <li className="FeatureAntoparagraph">Affordable interest rate.</li>
 <li className="FeatureAntoparagraph">Low-cost EMI.</li>
 <li className="FeatureAntoparagraph">Choose a tenure period as per their convenience.</li>
 <li className="FeatureAntoparagraph">More flexibility is provided for the selection of payment modes.</li>
 <li className="FeatureAntoparagraph">Less number of documents is required.</li>
 </ul>
 </Grid>

 <Grid item lg={4} md={4} className='EligibilityGrid'>
 <img className="Eligibilityimage" alt="logo" src={image3}></img>
 <h2 className="FeatureAntotitle"><b>Eligibility Criteria for Automobile Loan</b></h2>
 <ul style={{listStyleType:"disc"}}>
 <li className="FeatureAntoparagraph">Limited age 21years.</li>
 <li className="FeatureAntoparagraph">Both salaried and self-employed persons can apply for it.</li>
 <li className="FeatureAntoparagraph">Citizen of India.</li>
 <li className="FeatureAntoparagraph">Annual income of an individual up to 2,00,000.</li>
 </ul>
 </Grid>

 <Grid item lg={4} md={4} className='DocumentationGrid'>
 <img className="DocumentationImage" alt="logo" src={image7}></img>
 <h2 className="FeatureAntotitle"><b>Documentation Needed for Automobile Loan</b></h2>
 <ul style={{listStyleType:"disc"}}>
 <li className="FeatureAntoparagraph">Passport size photo.</li>
 <li className="FeatureAntoparagraph">PAN card and Adhar card.</li>
 <li className="FeatureAntoparagraph">Driving license.</li>
 <li className="FeatureAntoparagraph">Voter ID.</li>
 <li className="FeatureAntoparagraph">Previous 6 months’ banking details.</li>
 <li className="FeatureAntoparagraph">Residential proof.</li>
 <li className="FeatureAntoparagraph">Permanent address details.</li>
 </ul>
 </Grid>
  
</Grid>


<Grid container padding={2}>
<Grid item lg={6} md={6}>
<img className="AffectingImage" alt="logo" src={image5}></img>
</Grid>
<Grid item lg={6} md={6}>
<h2 className="FeatureAntotitle"><b>Factors Affecting Automobile Loan</b></h2>
<ul style={{listStyleType:"disc"}}>
  <li className="Affectingparagraph">Lenders examine customer credit scores to determine their repayment ability as well as payback conduct for prior or ongoing obligations.</li>
  <li className="Affectingparagraph">The employment status of the salaried or self-employed person must be appropriate for the automobile loan. The salaried person must be currently working for the last year. The self-employed person is also working for the last three.</li>
  <li className="Affectingparagraph">Income stability of the applicant is also checked by the lender for assessing whether a customer can pay their EMI on time or not.</li>
</ul>
</Grid>
</Grid>


<Grid container padding={2} className='flex-column-reverse flex-lg-row'>
<Grid item lg={6} md={6}>
<h2 className="FeatureAntotitle"><b>How do applicants apply for a Automobile loan ?</b></h2>
<ul style={{listStyleType:"disc"}}>
<li className="Applicantsparagraph"><p>Apply for a Automobile loan on the website of loan-loom <a href="http://loanloom .com">(http://loanloom .com)</a></p></li>
<li className="Applicantsparagraph"><p>Fill in the required documents for the Automobile loan procedure.</p></li>
<li className="Applicantsparagraph"><p>Applicants must have to pay the processing fee for the Automobile loan.</p></li>
<li className="Applicantsparagraph"><p>After that Automobile loan verification is done.</p></li>
<li className="Applicantsparagraph"><p>Applicants’ documents procedure is also done suitably.</p></li>
<li className="Applicantsparagraph"><p>Automobile is approved after the proper verifications.</p></li>
</ul></Grid>

<Grid item lg={6} md={6}>
<img className="ApplicantsImage" alt="logo" src={image9}></img>
</Grid>
</Grid>

    </>
  )
}

export default AutomobileLoanContent
