import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

const FooterInfo = ({ data: { icon, phnInfo, emailInfo, info1, info2, id } }) => {
  return (
    <Col md={4}>
      <div className={`d-flex fContactInfo fContactInfo${id} align-items-center`}>
        <FontAwesomeIcon icon={icon} className="fContactIcon" />
        <div>
          <p>
            <a className='link' href="http://maps.google.com/?q=1200 Uponly.in,OFFICE NO.1617,BUILDING A1 RUPA SOLITAIRE MBP, MAHAPE NAVI MUMBAI-400701" aria-label="Location: OFFICE NO.1617,BUILDING A1 RUPA SOLITAIRE MBP, MAHAPE NAVI MUMBAI-400701">
              {info1}
            </a>
          </p>
          <p className={`brnName${id}`}>
            <a className='link' href="tel:022-46052303" aria-label="Phone: 022-46052303">{phnInfo}</a>
          </p>
          <p>
            <a className='link' href="mailto:care@loanloom.com" aria-label="Email: care@loanloom.com">{emailInfo}</a>
          </p>
          {info2 && <p>{info2}</p>}
        </div>
      </div>
    </Col>
  );
};

export default FooterInfo;
