import React from "react";
import  Form  from "react-bootstrap/Form";
import  Input  from "@mui/material/Input/Input";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { ImMobile } from "react-icons/im";
import { useFormik } from "formik";
import { AadharRegex } from "../../../Forms/FormsStructure/Schemas";
import Fade from "react-reveal/Fade";


const Aadhar = ({
  nextStep,
  handleFormData,
  prevStep,
  values: value,
  right,
}) => {
  // const navigate =useNavigate();
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: { ...value },
      validationSchema: AadharRegex,

      onSubmit: (formValues) => {
        handleFormData({
          ...value,
          aadhar_No: formValues.aadhar_No,
        });
        nextStep();
      },
    });
  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <h4 style={{ fontFamily: "poppins" }}>Your Aadhar number?</h4>
        <div style={{ marginTop: "20px" }}>
          <Form>
            <Form.Group className="mb-3">
              <Input
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <ImMobile className="formicon" alt="" />
                  </InputAdornment>
                }
                name="aadhar_No"
                value={values.aadhar_No}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Your Aadhar No."
              />
              {errors.aadhar_No && touched.aadhar_No ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "16px",
                  }}
                >
                  {errors.aadhar_No}
                </p>
              ) : null}
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button type="button" className="btn-prev1" onClick={prevStep}>
                &#8592; Previous
              </button>
              <button className="btn-one1" type="submit" onClick={handleSubmit}>
                Next &#8594;
              </button>
            </div>
          </Form>
        </div>
      </Box>
    </Fade>
  );
};

export default Aadhar;
