import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { BiUserCheck } from "react-icons/bi";
import Input from "@mui/material/Input/Input";
import { ImMobile } from "react-icons/im";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import { personalInfo } from "./Schemas";
import Fade from "react-reveal/Fade";
import axios from "axios";

const Personal = ({ nextStep, handleFormData, values: value, right, setOtp }) => {
  const callOtpAPI = async (props) => {
    await axios
      .get(`https://www.loanloom.com/loan_phpForm/loanloom_mobile_otp.php?mobile=${props}`)
      .then((res) => setOtp(res.data));
  };

  // Function to get the values from localStorage
  const getValuesFromLocalStorage = () => {
    const fullNameFromLocalStorage = localStorage.getItem("name");
    const mobileFromLocalStorage = localStorage.getItem("mobile");

    if (fullNameFromLocalStorage && mobileFromLocalStorage) {
      // Set the initial values for the input fields if they exist in localStorage
      handleChange({
        target: {
          name: "fullName",
          value: fullNameFromLocalStorage,
        },
      });
      handleChange({
        target: {
          name: "mobile",
          value: mobileFromLocalStorage,
        },
      });
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: personalInfo,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        fullName: formValues.fullName,
        mobile: formValues.mobile,
      });

      callOtpAPI(formValues.mobile);
      nextStep();
    },
  });

  // Run the function when the component mounts
  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  return (
    <Fade duration={10} right={right} left={!right}>
      <Box>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col md={12} lg={6}>
              <Form.Group>
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  name="fullName"
                  value={values.fullName}
                  type="text"
                  placeholder="Full Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  startAdornment={
                    <InputAdornment position="start">
                      <BiUserCheck className="formicon" />
                    </InputAdornment>
                  }
                />
                {errors.fullName && touched.fullName ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.fullName}
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={12} lg={6}>
              <Form.Group className="mb-3">
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <ImMobile className="formicon" />
                    </InputAdornment>
                  }
                  value={values.mobile}
                  name="mobile"
                  type="text"
                  placeholder="Your Mobile"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.mobile && touched.mobile ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px",
                    }}
                  >
                    {errors.mobile}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <button type="submit" className="btn-one1" onClick={handleSubmit}>
            Next &#8594;
          </button>

          <p
            style={{
              fontSize: "14px",
              fontFamily: "poppins",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            By proceeding, you are giving us consent to call or SMS with reference to your loan application
          </p>
          {['checkbox'].map((type) => (
            <div key={`default-${type}`} className="mb-3">
              <Form.Check
                type={type}
                id={`default-${type}`}
                label={
                  <span>
                    I agree to Loan Loom{" "}
                    <a style={{ textDecoration: "none", color: "#480082", fontWeight: "bold" }} href="https://loanloom.com/terms" target="_blank" rel="noopener noreferrer">
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a style={{ textDecoration: "none", color: "#480082", fontWeight: "bold" }} href="https://loanloom.com/privacy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy.
                    </a>
                  </span>
                  
                }
                defaultChecked
              />
            </div>
          ))}
        </Form>
      </Box>
    </Fade>
  );
};

export default Personal;
