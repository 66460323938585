import React from "react";
import Box from "@mui/material/Box";
import  Form  from "react-bootstrap/Form";
import Input  from "@mui/material/Input";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";
import { DateValidate } from "../../../../../Forms/FormsStructure/Schemas";


const WhendidUbegin = ({
  nextStep,
  prevStep,
  handleFormData,
  values: value,
  right
}) => {
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: { ...value },
    validationSchema: DateValidate,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        whenDidYouBegin: formValues.whenDidYouBegin,
      });
      nextStep();
    },
  });
  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
    <Fade duration={1000} right={right} left={!right}>
    <Box>
      <h4 style={{ fontFamily: "poppins" }}>
        When did you begin your existing loan?
      </h4>
      <div style={{ marginTop: "40px" }}>
        <Form>
          <Input
            style={{
              width: "210px",
              fontSize: "16px",
              fontFamily: "poppins",
              padding: "0.6rem 0.6rem",
            }}
            name="whenDidYouBegin"
            value={values.whenDidYouBegin}
            type="date"
            placeholder="select date"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.whenDidYouBegin ? (
            <p
              style={{
                color: "red",
                fontSize: "14.5px",
                fontFamily: "Comic Sans",
                marginTop: "12px",
              }}
            >
              {errors.whenDidYouBegin}
            </p>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <button type="button" className="btn-prevforcity" onClick={prevStep}>
              &#8592; Previous
            </button>

            <button
              className="btn-oneforcity"
              type="submit"
              onClick={handleSubmit}
            >
              Next &#8594;
            </button>
          </div>
        </Form>
      </div>
    </Box>
    </Fade>
    //</motion.div>
  );
};

export default WhendidUbegin;
