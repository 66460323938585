import SingalPost from './SingalPost';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RightSide from '../LeftsideRightSide/RightSide';
import axios from "axios";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";

const Singal = () => {
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://www.loanloom.com/loan_phpForm/blog_api_all_data.php" + search
      );
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  return (
    <div style={{ padding: "1rem" }}>
      <Row>
        <Col md={9} sm={true}>
          <SingalPost />
        </Col>
        <Col md={3} sm={true}>
          <RightSide posts={posts} />
        </Col>
      </Row>
    </div>
  );
};

export default Singal;
