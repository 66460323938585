import React from 'react';
import './Doc.css';

const DocSalaried = ({ nextStep }) => {
  const handleSubmit = () => {
    nextStep();
  };

  return (
    <div className="DocBg">
      <p className="p11">Our representative will be right back with the best offers available offline for you</p>
      <p className="p11">Be ready with the following documents:</p>

      <div className="p22">
        <ul className="loanUl">
          <li>Passport Size Photo</li>
          <li>Aadhar and PAN Card for KYC</li>
          <li>
            Address Proof if Address on Aadhar doesn't match current address (any 1 of below)
            <ul className="loansubUl">
              <li>Electricity Bill</li>
              <li>Telephone Bill</li>
              <li>Gas Bill</li>
              <li>Ration Card</li>
              <li>Company Allotment Letter</li>
              <li>Leave and Licence Agreement</li>
              <li>Sale deed</li>
            </ul>
          </li>
          <li>3 months salary slip</li>
          <li>6 months banking statement showing salary</li>
          <li>Work Experience Letter</li>
        </ul>
      </div>
      <button className="btn-oneforcity" type="submit" onClick={handleSubmit}>
        Next &#8594;
      </button>
    </div>
  );
};

export default DocSalaried;
