import { FaSistrix } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import { Input } from "@mui/material";

const Search = ({ setSearch }) => {
	return (
		<div >
		<Input
		style={{fontFamily:"poppins",fontWeight:400,width:"60%",color:"#480082",opacity:'0.9'}}
		 startAdornment={
			<InputAdornment position="start">
			  <FaSistrix className="formIconforSearch" />
			</InputAdornment>
		  }
		className="inputforSearch"
			type="text"
			placeholder="Search..."
			autoComplete="off"
			name="search"
			onChange={({ currentTarget: input }) => setSearch(input.value)}
		/>
		</div>
	);
};

export default Search;