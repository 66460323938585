import React, { useState } from "react";
import  Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import  Input from "@mui/material/Input/Input";
import { RxCursorArrow } from "react-icons/rx";
import Fade from "react-reveal/Fade";
import WhendidUbegin from "./WhendidUbegin";
import AverageMonthlyIncome from "../PurchaseAndConstruct/AverageMonthlyIncome";
import AnnualBonus from "../PurchaseAndConstruct/AnnualBonus";
import AboutProperty from "../PurchaseAndConstruct/AboutProperty";
import TotalEmi from "../PurchaseAndConstruct/TotalEmi";
import NameofBuilder from "../PurchaseAndConstruct/NameofBuilder";
import CostofHome from "../PurchaseAndConstruct/CostofHome";
import GenderSel from "../PurchaseAndConstruct/GenderSel";
import { useFormik } from "formik";
import PropertyBasedCity from "../PurchaseAndConstruct/PropertyBasedCity";
import Thank from "../../../../../Forms/FormsStructure/Thank";
import { ExistBankValidate } from "../../../../../Forms/FormsStructure/Schemas";

const ExistBankLoan = ({ prevStep: prevPreviousStep, handleFormData: setFormData,values: formData,mobile,right:exitRight}) => {
  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: { formData },
    validationSchema: ExistBankValidate,

    onSubmit: (formValues) => {
      setFormData({
        ...formData,
        existbankLoan: formValues.existbankLoan,
      });
      nextStep();
    },
  });

  const [step, setstep] = useState(1);
  const [right, setRight] = useState(true);

  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if (step === 1) {
      prevPreviousStep();
    } else {
      setstep(step - 1);
    }
  };

  switch (step) {
    case 1:
      return (
        <Fade duration={1000} right={exitRight} left={!exitRight}>
        <Col md={8} className="m-auto">
            <Box>
              <h4 style={{ fontFamily: "poppins" }}>
                Which bank is your existing home loan with?
              </h4>
              <div style={{ marginTop: "20px" }}>
                <Form>
                  <Form.Group>
                    <Input
                      style={{
                        width: "300px",
                        fontSize: "16px",
                        fontFamily: "poppins",
                        padding: "0.6rem 0.6rem",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <RxCursorArrow className="formicon" />
                        </InputAdornment>
                      }
                      name="existbankLoan"
                      value={values.existbankLoan}
                      type="text"
                      placeholder="type bank name here..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  {errors.existbankLoan ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "14.5px",
                        fontFamily: "Comic Sans",
                        marginTop: "12px",
                      }}
                    >
                      {errors.existbankLoan}
                    </p>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "20px",
                    }}
                  >
                    <button type="button" className="btn-prevforcity" onClick={prevStep}>
                      &#8592; Previous
                    </button>

                    <button
                      className="btn-oneforcity"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Next &#8594;
                    </button>
                  </div>
                </Form>
              </div>
            </Box>
        </Col>
        </Fade>
        // </motion.div>
      );
    case 2:
      return (
        <div>
          <Row>
            <Col md={{ span: 8, offset: 3 }} className="m-auto">
              <WhendidUbegin
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );
    case 3:
      return (
        <div>
          <Row>
            <Col>
              <PropertyBasedCity
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                right={right}
              />
            </Col>
          </Row>
        </div>
      );

    case 4:
      return (
        <div>
          <Row>
            <Col sm={2} md={{ span: 7, offset: 3 }}>
              <GenderSel
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
              />
            </Col>
          </Row>
        </div>
      );
    case 5:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <AnnualBonus
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
              />
            </Col>
          </Row>
        </div>
      );
    case 6:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}>
              <AverageMonthlyIncome
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
              />
            </Col>
          </Row>
        </div>
      );

    case 7:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }}>
              <TotalEmi
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
              />
            </Col>
          </Row>
        </div>
      );

    case 8:
      return (
        <div>
          <Row>
            <Col sm={2} md={6} lg={12}>
              <AboutProperty
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
              />
            </Col>
          </Row>
        </div>
      );

    case 9:
      return (
        <div>
          <Row>
            <Col md={{ span: 7, offset: 3 }} className='m-auto'>
              <NameofBuilder
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
              />
            </Col>
          </Row>
        </div>
      );

    case 10:
      return (
        <div>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <CostofHome
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
              />
            </Col>
          </Row>
        </div>
      );
      case 11:
      return (
        <div>
          <Row>
              <Thank
                 right={right}
                nextStep={nextStep}
                prevStep={prevStep}
                handleFormData={setFormData}
                values={formData}
                mobile={mobile}
              />
          </Row>
        </div>
      );
    default:
      return <div className="App"></div>;
  }
};

export default ExistBankLoan;
