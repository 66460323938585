import React from 'react'
import './Doc.css'


const PLDoc = ({nextStep}) => {
  const handleSubmit = ()=>{
   nextStep();
  }
  return (
   
    <div className='DocBg1'>
     <p className="p11">Our representative will be right back with best offers available offline for you </p>
      <p className="p11">Be ready with Below Docs :- </p>

      <div  className='p22'>
      <ul className='loanUl'>
        <li>PAN Card of the applicant</li>
        <li>Aadhar Card</li>
        <li>Local address Proof</li>
        <li>Addtional KYC in case of Company/Firm
            <ul><li>
                <ul className='loansubUl'>
                <li>Partnership Deed in case of Partnership firm</li>
                <li>MOA and AOA of company</li>
                <li>Share holding pattern</li>
                <li>PAN card copy</li>
                <li>Valid Shop License</li>
                </ul>
                </li>
           </ul>
           <li>Income Documents of company</li>
           <ul>
            <li>
                <ul className='loansubUl'>
                    <li>CA attested Latest 2 years financials including ITR copy,computation of income,balance sheet, profit and loss,Tax Audit Reports</li>
                    <li>GST Return</li>              
                </ul>
            </li>
           </ul>
           <li>Banking</li>
           <ul>
            <li>
                <ul className='loansubUl'>
                    <li>Latest year current/CC/OD account bank statement</li>
                </ul>
            </li>
           </ul>
        </li>
      </ul>

      </div>
      <button
            className="btn-oneforcity"
            type="submit"
            onClick={handleSubmit}
          >
            Next &#8594;
          </button>
    </div>

  )
}

export default PLDoc
