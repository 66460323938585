import React from 'react';
  import { useTheme } from '@mui/material/styles';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useState } from "react";
  import { Box, Grid, Typography, Input } from "@mui/material";
  import Slider from "@mui/material/Slider";
  import { HomeSlider } from "../CalcisliderTabledata/Slider";
  import { PieChart, Pie, Cell, Tooltip } from "recharts";
  import { Link } from "react-router-dom";
  import './calculator.css'

  const CalciForHome = () => {
      const theme = useTheme();

      const isMd = useMediaQuery(theme.breakpoints.up('md'), {
          defaultMatches: true,
          
      });

    const [principalAmount, setPrincipalAmount] = useState(2755000);
    const [interest, setInterest] = useState(7);
    const [duration, setDuration] = useState(147);
    const maxValue = 10000000;
    const intMax = 20;
    const maxDuration = 360;

    const intr = interest / 1200;
    const emi = duration
      ? Math.round(
          (principalAmount * intr) / (1 - Math.pow(1 / (1 + intr), duration))
        )
      : 0;
    const totalAmt = duration * emi;
    var TotalAmountOfcredit = Math.round(
      (emi / intr) * (1 - Math.pow(1 + intr, -duration))
    );
    const TotalAmountOfInterest = Math.round(totalAmt - TotalAmountOfcredit);
    const ChangeInput = (event) => {
      setPrincipalAmount(
        event.target.value === "" ? "" : Number(event.target.value)
      );
    };
    const COLORS = ["#F68712", " #480082"];

    const pieData = [
      {
        name: "Total Interest",
        value: TotalAmountOfInterest,
      },
      {
        name: "Amount",
        value: totalAmt,
      },
    ];
    const CustomTooltip = ({ active, payload }) => {
      if (active) {
        return (
          <div
            className="custom-tooltip"
            style={{
              backgroundColor: "#ffff",
              padding: "5px",
              border: "1px solid #cccc",
            }}
            
          >
            <label>{`${payload[0].name} : ${payload[0].value}`}</label>
          </div>
        );
      }

      return null;
    };
    return (
      <div> 
        <span className="calciHead">EMI Calculator</span>
        
            <Grid container mt={3} className="emidiv">
              {isMd ? 
               <Grid item md={6} xs={12} sm={true} >
                <Box>
                  <Typography gutterBottom style={{fontFamily:"poppins"}}>Loan Amount</Typography>
                  <Grid item className="calciInput">
                    <strong>&#8377;</strong>
                    <Input
                      style={{
                        marginLeft: "2px",
                        paddingLeft: "20px",
                        fontSize: "14px",
                        fontFamily:"poppins"
                      }}
                      value={principalAmount}
                      size="small"
                      onChange={ChangeInput}
                      inputProps={{
                        step: 50,
                        min: 0,
                        max: 10000000,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                  <Slider
                  sx={{
                    color: '#480082',
                    '& .MuiSlider-thumb': {
                      border: "2px solid #F68712",
                      background: "#FFF",
                    },
                    '& .MuiSlider-rail':{
                      backgroundColor: "#bfbfbf"
                    }
                  }}
                    aria-label="ios slider"
                    value={typeof principalAmount === "number" ? principalAmount : 0}
                    marks={HomeSlider.marksAmt}
                    onChange={(event, vAmt) => {
                      setPrincipalAmount(vAmt);
                    }}
                    defaultValue={principalAmount}
                    max={maxValue}
                  />
                </Box>
                <Box>
                  <Typography gutterBottom style={{fontFamily:"poppins"}}>Interest Rate %</Typography>
                  <Grid item className="calciInput">
                    <Input
                      style={{
                        marginLeft: "2px",
                        paddingLeft: "30px",
                        fontSize: "14px",
                        fontFamily:"poppins"
                      }}
                      value={interest}
                      size="small"
                      onChange={(event) => {
                        setInterest(
                          event.target.value === "" ? "" : Number(event.target.value)
                        );
                      }}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 20,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                    <strong> % </strong>
                  </Grid>
                  <Slider
                    sx={{
                      color: '#480082',
                      '& .MuiSlider-thumb': {
                        border: "2px solid #F68712",
                        background: "#FFF",
                      },
                      '& .MuiSlider-rail':{
                        backgroundColor: "#bfbfbf"
                      }
                    }}
                    aria-label="ios slider"
                    value={typeof interest === "number" ? interest : 0}
                    marks={HomeSlider.marksInt}
                    onChange={(event, vInt) => {
                      setInterest(vInt);
                    }}
                    defaultValue={interest}
                    max={intMax}
                    // valueLabelDisplay="on"
                  />
                </Box>
                <Box>
            <Typography gutterBottom style={{fontFamily:"poppins"}}>Tenure (months)</Typography>
            <Grid item className="calciInput">
              <Input
                style={{
                  marginLeft: "2px",
                  paddingLeft: "30px",
                  fontSize: "14px",
                  fontFamily:"poppins"
                }}
                value={duration}
                size="small"
                onChange={(event) => {
                  setDuration(
                    event.target.value === "" ? "" : Number(event.target.value)
                  );
                }}
                inputProps={{
                  step: 5,
                  min: 0,
                  max: 360,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Slider
              sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
              aria-label="ios slider"
              value={typeof duration === "number" ? duration : 0}
              marks={HomeSlider.marksTenture}
              onChange={(event, vDur) => {
                setDuration(vDur);
              }}
              defaultValue={duration}
              max={maxDuration}
              // valueLabelDisplay="on"
            />
          </Box>
                <Link as={Link} to='/eligibility/Offers' style={{ textDecoration: 'none' }}>
                  <button
                    className="btnforemi"
                    style={{ backgroundColor: '#480082', color: '#FFFFF' }}
                  >
                    Apply Now
                  </button>
                </Link>

              </Grid> : 
              
              <Grid item md={6} xs={12} sm={true}>
              <Box className="calci-mainbox">
              <Grid container md={6} xs={12}>
              <Grid item className="calciInput">
                <Typography gutterBottom style={{fontFamily:"poppins",fontSize:"13px"}}>Loan Amount</Typography></Grid>
                  <strong>&#8377;</strong>
                  <label htmlFor="principalAmount">Principal Amount</label>
<Input
  id="principalAmount"
  style={{
    marginLeft: "87px",
    paddingLeft: "20px",
    fontSize: "12px",
    fontFamily: "poppins"
  }}
  value={principalAmount}
  size="small"
  onChange={ChangeInput}
  inputProps={{
    step: 50,
    min: 0,
    max: 10000000,
    type: "number",
    "aria-labelledby": "input-slider",
  }}
/>

                </Grid>
                <Slider
                sx={{
                  color: '#480082',
                  '& .MuiSlider-thumb': {
                    border: "2px solid #F68712",
                    background: "#FFF",
                  },
                  '& .MuiSlider-rail':{
                    backgroundColor: "#bfbfbf"
                  }
                }}
                  aria-label="ios slider"
                  value={typeof principalAmount === "number" ? principalAmount : 0}
                  marks={HomeSlider.marksAmt}
                  onChange={(event, vAmt) => {
                    setPrincipalAmount(vAmt);
                  }}
                  defaultValue={principalAmount}
                  max={maxValue}
                  // valueLabelDisplay="on"
                />
              </Box>
              <Box className="calci-mainbox">
              <Grid container md={6} xs={12}>
              <Grid item className="calciInput">
                <Typography gutterBottom style={{fontFamily:"poppins",fontSize:"13px"}}>Interest Rate %</Typography></Grid>
                
                <label htmlFor="interest">Interest Rate</label>
<Input
  id="interest"
  style={{
    marginLeft: "130px",
    paddingLeft: "20px",
    fontSize: "12px",
    fontFamily: "poppins"
  }}
  value={interest}
  size="small"
  onChange={(event) => {
    setInterest(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  }}
  inputProps={{
    step: 1,
    min: 0,
    max: 20,
    type: "number",
    "aria-labelledby": "input-slider",
  }}
/>

                  <strong> % </strong>
                </Grid>
                <Slider
                  sx={{
                    color: '#480082',
                    '& .MuiSlider-thumb': {
                      border: "2px solid #F68712",
                      background: "#FFF",
                    },
                    '& .MuiSlider-rail':{
                      backgroundColor: "#bfbfbf"
                    }
                  }}
                  aria-label="ios slider"
                  value={typeof interest === "number" ? interest : 0}
                  marks={HomeSlider.marksInt}
                  onChange={(event, vInt) => {
                    setInterest(vInt);
                  }}
                  defaultValue={interest}
                  max={intMax}
                  // valueLabelDisplay="on"
                />
              </Box>
              <Box className="calci-mainbox">
              <Grid container md={6} xs={12}>
              <Grid item className="calciInput">
                <Typography gutterBottom style={{fontFamily:"poppins",fontSize:"13px"}}>Tenure (months)</Typography></Grid>
                <label htmlFor="duration">Loan Duration (in months)</label>
<Input
  id="duration"
  style={{
    marginLeft: "105px",
    paddingLeft: "20px",
    fontSize: "12px",
    fontFamily: "poppins",
  }}
  value={duration}
  size="small"
  onChange={(event) => {
    setDuration(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  }}
  inputProps={{
    step: 5,
    min: 0,
    max: 360,
    type: "number",
    "aria-labelledby": "input-slider",
  }}
/>

                
                </Grid>
                <Slider
                  sx={{
                    color: '#480082',

                    '& .MuiSlider-thumb': {
                      border: "2px solid #F68712",
                      background: "#FFF",
                    },
                    '& .MuiSlider-rail':{
                      backgroundColor: "#bfbfbf",
                      margintop:"30px"
                    }
                  }}
                  aria-label="ios slider"
                  value={typeof duration === "number" ? duration : 0}
                  marks={HomeSlider.marksTenture}
                  onChange={(event, vDur) => {
                    setDuration(vDur);
                  }}
                  defaultValue={duration}
                  max={maxDuration}
                />
              </Box>
              <Grid container>
              <Grid md={6} xs={6} mt={2} >
                <Typography gutterBottom className="tableHeadforphone" >EMI (Months):<span className="tablejsforphone">&#8377;&nbsp;{emi}</span></Typography>
              </Grid>
              <Grid md={6} xs={6} mt={2}>
              <Typography gutterBottom className="tableHeadforphone">Total Interest:<span  className="tablejsforphone">&#8377;&nbsp;{TotalAmountOfInterest}</span></Typography>
              </Grid>
              </Grid>
              <Grid mt={2}><Typography gutterBottom className="tableHeadforphone"> (Loan Amount + Interest):<span  className="tablejsforphone">&#8377;&nbsp;{totalAmt}</span></Typography>
              </Grid>
            
              <Link to='/eligibility/Offers' style={{ textDecoration: 'none' }}>
  <button
    className="btnforemi"
    style={{ backgroundColor: '#480082', color: '#FFFFF' }}
  >
    Apply Now
  </button>
</Link>
            </Grid> 
                  }
              <Grid item md={6} xs={12} sm={12}>
                <Grid item md={12} xs={12}>
                  <Box>

                    <PieChart
                      width={340}
                      height={340}
                      style={{ margin: "auto" }}
                      className="pie"
                    > 
                      <Pie
                        data={pieData}
                        color="#000000"
                        dataKey="value"
                        nameKey="name"
                        cx="47%"
                        cy="35%"
                        outerRadius={75}
                        fill="#8884d8"
                        isAnimationActive={true}
                        label
                      >
                        {pieData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  </Box>

                  {isMd ? <Grid container p={2} alignContent="center" justifyContent="center" align="center" >  
              <Grid md={6} xs={12} mt={2} >
              <Typography gutterBottom className="tableHead" >EMI (Months):<span className="tablejs">&#8377;&nbsp;{emi}</span></Typography>
              </Grid>
              <Grid md={6} xs={12} mt={2}>
              <Typography gutterBottom className="tableHead">Total Interest:<span  className="tablejs">&#8377;&nbsp;{TotalAmountOfInterest}</span></Typography>
              </Grid>
              <Grid mt={2}><Typography gutterBottom className="tableHead"> (Loan Amount + Interest):<span  className="tablejs">&#8377;&nbsp;{totalAmt}</span></Typography></Grid>
                </Grid>
                
              :null}
              
              </Grid> 
               </Grid>
            </Grid>
      </div>
    );
  };

  export default CalciForHome;
