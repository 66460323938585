import Img from '../../../../../Assets/sorry_img.webp'

function NotAvailable(){

  return (
    <>
    <div className="sorryBg">
   
    <img src={Img} alt='notavail' className="sorryImg" />
   <p className="p11">Not available for the moment ! </p>
 
   </div>
    </>
  );
};

export default NotAvailable;
