import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';


const FooterCol = (props) => {
  return (
    <Col md={6} lg={3} className="footerLink">
      <p className='footertitle'>{props.title ? props.title : ''}</p>
      <Col md={12} lg={12} className='footerLink_A'>
        {props.menuItems?.map(({ name, path }) => (
          <ul style={{ paddingLeft: "0rem" }} key={path}>
            <li>
              {path.startsWith('http') ? (
                <a href={path} target="_blank" rel="noopener noreferrer">
                  {name}
                </a>
              ) : (
                <Link to={String(path)}>
                  {name}
                </Link>
              )}
            </li>
          </ul>
        ))}
      </Col>
      {props.children && props.children}
    </Col>
  );
};

export default FooterCol;
