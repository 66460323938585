import React from 'react'
import Box from "@mui/material/Box";
import { FaBuilding } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "react-reveal/Fade";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import { Input } from '@mui/material';


const CompanyWorkFor = ({ nextStep, handleFormData, prevStep, values: value, right }) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: { ...value },

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        company_Name: formValues.company_Name,
      });
      nextStep();
    },
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <h4 style={{ fontFamily: "poppins" }}>
          Company I work for
        </h4>
        <div style={{ marginTop: "20px", fontFamily: 'poppins' }}>
          <Form>
            <Form.Group>
              <Input
                fullWidth
                id="custom-input-demo"
                placeholder="type here ..."
                name="company_Name"
                value={values.company_Name}
                onChange={handleChange}
                style={{
                  fontSize: "15px",
                  fontFamily: "poppins",
                  padding: "0.6rem 0.6rem",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <FaBuilding className="formicon" />
                  </InputAdornment>
                }
              />
              {errors.company_Name && touched.company_Name ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "14.5px",
                    fontFamily: "Comic Sans",
                    marginTop: "16px"
                  }}
                >
                  {errors.company_Name}
                </p>
              ) : null}
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              <button type="button" className="btn-prevforbuilder" onClick={prevStep}>
                &#8592; Previous
              </button>
              <button
                className="btn-oneforcity"
                type="submit"
                onClick={handleSubmit}
              >
                Next &#8594;
              </button>
            </div>
          </Form>
        </div>
      </Box>
    </Fade>
  )
}

export default CompanyWorkFor;
