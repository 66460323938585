import CalciAccordian from "../CalciAccordian/CalciAccordian";
import HomeCalculator from "./HomeCalculator";
import Calculator_hero from "./Calculator_hero";

const Calculator = () => {
  return (
    <div style={{padding:"1rem"}}>
      <Calculator_hero/>
   <HomeCalculator/>
   <CalciAccordian />    
    </div>
  );
};

export default Calculator;
