import '../Table/table.css'
import { Button } from '@mui/material';
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
const Pagination = ({ page, total, limit, setPage }) => {
const scrollTop=(id)=>{
  const violation = document.getElementById(id); 
  window.scrollTo({
    top:violation?.offsetTop-110,
    behavior:"smooth"
});
};
	const totalPages = Math.ceil(total / limit);

	const onClick = (newPage) => {
		setPage(newPage + 1);
		scrollTop('tablescroll');

	};

	return (
		<div id ="app" className="tableContainer">
			<ul>
				<li className="page_btn">
				<Button
				
				disabled={page===1}
						onClick={() =>{setPage(page-1);scrollTop('tablescroll');}}
					>
						<IoIosArrowBack className='pageIcon'/>
					</Button>
			{totalPages > 0 &&
				[...Array(totalPages)].map((val, index) => (
                  
				  index>=page-1 && index<=page+1 && <button
					className={
						page===index+1 ? 'page':'active'
					}
						onClick={() => onClick(index)}
						key={index}
					>
						{index + 1}
					</button>
				))}
				<Button
				style={{width:"40px"}}
				disabled={page===totalPages}
						onClick={() =>{setPage(page+1);scrollTop('tablescroll')}}	
					>
					<IoIosArrowForward className='pageIcon'/>
					</Button>
				</li>
				</ul>
		</div>
	);
};

export default Pagination;