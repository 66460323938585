import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../CalciMain/calculator.css";
import Img from "../../../../../Assets/emiformula.png";
import Grid from "@mui/material/Grid";

export default function Calculator_FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <h5 className="mt-5 calciHead">FAQs</h5>
      <Grid container p={2}>
        <Grid item md={12} xs={12} spacing={4}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="Faq">What is EMI?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                Equated Monthly Installment - EMI for short - is the amount
                payable every month to the bank or any other financial
                institution until the loan amount is fully paid off. It consists
                of the interest on loan as well as part of the principal amount
                to be repaid. The sum of principal amount and interest is
                divided by the tenure, i.e., number of months, in which the loan
                has to be repaid.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className="Faq">
                Formula for calculating EMI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                <b>formula to calculate EMI </b>:
                <p>
                  <img src={Img} alt="formula" className="formula" />
                </p>
                <p> where</p>
                <p>E is EMI</p>
                <p>is Principal Loan Amount</p>
                <p>r is rate of interest calculated on monthly basis.</p>
                <p>n is loan term / tenure / duration in number of months</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className="Faq">
                How to Use EMI Calculator?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                Enter the following information in the EMI Calculator: *
                Principal loan amount you wish to avail (rupees) * Loan
                term/Tenture (months or years) * Rate of interest (percentage)
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="Faq">
                What happens if loan EMI is not paid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                If you miss an EMI payment, your credit score will go down,
                which will make it harder for you to get credit in the future.
                If you're late on an EMI payment, you'll have to pay extra
                interest at a rate of 2% per month.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="Faq">
                How does EMI work on loan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                An EMI is a set amount that a borrower pays to a lender on a
                specified date each month. EMIs go towards both interest and
                principal each month so that the loan is paid off in full over a
                number of years.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="Faq">
                What are the options through which I can pay my loan EMI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                There are several ways to pay your EMI: * Post dated cheques:
                You can submit post dated cheques to your nearest bank loan
                center by your EMI due date. * Electronic Clearing Service: By
                this option, non bank account holders can have their EMIs
                automatically deducted at the end of the month. * Standing
                Instruction: If you have a savings, salary, or current account
                with Bank, your EMI can be automatically deducted from your
                account at the end of your monthly cycle.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="Faq">
                What happens if you miss a month on loan EMI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="Faq">
                The bank offers a grace period in many cases during which you
                can clear your EMI. If you miss paying your EMI for three months
                or 90 days, the bank will begin charging you for late payment
                and you will be added to the defaulter's list.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
