import React, { useState } from 'react';
import Img1 from "../../../../../Assets/homeproduct.png";
import ImgforPhone1 from "../../../../../Assets/homeloan.png";
import Img2 from "../../../../../Assets/personalproduct.webp";
import ImgforPhone2 from "../../../../../Assets/personalloan.png";
import Img4 from "../../../../../Assets/educationproduct.webp";
import ImgforPhone4 from "../../../../../Assets/educationloan.png";
import Img3 from "../../../../../Assets/bussinessproduct.png";
import ImgforPhone3 from "../../../../../Assets/businessloan.png";
import Img6 from "../../../../../Assets/carproduct.webp";
import ImgforPhone6 from "../../../../../Assets/carloan.png";
import Box from "@mui/material/Box";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Type from '../../Service/HomeLoan/Type';
import BussinessHome from '../../Service/BusinessLoan/BussinessLoan/BussinessHome';
import EducationHome from '../../Service/EducationLoan/EducationLoan/EducationHome';
import Fade from "react-reveal/Fade";
import Index from '../../Service/PersonalLoan/PersonalLoan/Index';

export default function ApplyNow({values,rightFade}) {
  const mobile = values.mobile
  const [right, setRight] = useState(true);
  
  const [step, setstep] = useState(0);

  const [data, setFormData] = useState({
    loan_type: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true)
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setRight(false)
if(step ===2){
  setstep(0)
}
else{
  setstep(step-1)
}
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
        <Fade duration={1000} right={rightFade} left={!rightFade}>
          <Box>
            <h4 style={{fontFamily:'poppins'}}>Type of Product</h4>
            <div className='divforapplynow'>
              <label>
                <img src={Img1} className="imageforApplyNow" alt="ApplyNow" />
                <img src={ImgforPhone1} className="imageforApplyNowphone" alt="ApplyNow" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...data,loan_type:'home loan'})
                  setstep(1)}}/>
                <h6 className="typeapplynow">Home Loan</h6>
              </label>

              <label>
                <img src={Img2} className="imageforApplyNow" alt="ApplyNow" />
                <img src={ImgforPhone2} className="imageforApplyNowphone" alt="ApplyNow" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...data,loan_type:'personal loan'})
                  setstep(2)}} />
                <h6 className="typeapplynow">Personal Loan</h6>
              </label>
              <label>
                <img src={Img3} className="imageforApplyNow" alt="ApplyNow" />
                <img src={ImgforPhone3} className="imageforApplyNowphone" alt="ApplyNow" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...data,loan_type:'bussiness loan'})
                  setstep(3)}}/>
                <h6 className="typeapplynow">Bussiness Loan</h6>
              </label>
              <label>
                <img src={Img4} className="imageforApplyNow" alt="ApplyNow" />
                <img src={ImgforPhone4} className="imageforApplyNowphone1" alt="ApplyNow" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...data,loan_type:'education loan'})
                  setstep(4)}} />
                <h6 className="typeapplynow">Education Loan</h6>
              </label>
              <label>
                <img src={Img6} className="imageforApplyNow" alt="ApplyNow" />
                <img src={ImgforPhone6} className="imageforApplyNowphone1" alt="ApplyNow" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...data,loan_type:'automobile loan'})
                  setstep(5)}} />
                <h6 className="typeapplynow">Automobile Loan</h6>
              </label>
            </div>
          </Box>
          </Fade>
      );
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col>
                <Type
                  nextStep={nextStep}
                  prevStep={prevStep} 
                  right={right}
                  handleFormData={setFormData}
                  values={data}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
      case 2:
      return (
        <div className="formdiv">
                <Index
                 nextStep={nextStep}
                 prevStep={prevStep} 
                 right={right}
                 handleFormData={setFormData}
                 values={data}
                 mobile={mobile}
                />
        </div>
      );
      case 3:
        return (
          <div className="formdiv">
                  <BussinessHome
                    nextStep={nextStep}
                    prevStep={prevStep} 
                    handleFormData={setFormData}
                    values={data}
                    right={right}
                    mobile={mobile}
                  />
                
          </div>
        );
        case 4:
          return (
            <div className="formdiv">
                    <EducationHome
                      nextStep={nextStep}
                      prevStep={prevStep} 
                      right={right}
                      handleFormData={setFormData}
                      values={data}
                      mobile={mobile}
                    />
            </div>
          );

    default:
      return <div className="formdiv"></div>;
  }
}
