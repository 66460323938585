import { Grid } from "@mui/material"
import './homeLoanContent.css';
import image from '../../../../../../Assets/home11.webp'
import image2 from '../../../../../../Assets/newimage.jpg';
import image7 from '../../../../../../Assets/neimage.png';
import image5 from '../../../../../../Assets/image55.jpg';
import image6 from '../../../../../../Assets/image8.jpg';
import image9 from '../../../../../../Assets/constructionLoanImage.jpg';
import image8 from '../../../../../../Assets/PropertyLoanImage.jpg';
import image10 from '../../../../../../Assets/RemodelingImage.jpg';
import image11 from '../../../../../../Assets/PurchImagee.jpg';

const HomeLoanContent = () => {
  return (
    <>
    <div className="containerforhomeloancontent">
    <Grid  container p={0} ml={1}>
    <Grid item  xs={12} sm={12} md={6}>
      <h1 className="home_Heading">Home Loan</h1>
        <p className="home_paragraph">
        <b>Achieve Your Dream Home with Loanloom,</b> the best service provider that understands the
importance of home loans in today&#39;s society. Home loan is very important for every person it
is necessary for every person who needs a home loan. Because we are living in a society
where the number of houses is increasing day by day we have to have a home to live a
peaceful and happy life. A home loan helps us a lot to make our dreams come true in having
the house of our dreams.
 
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <img src={image} alt="home" className="homeloanimg"  />
      </Grid>
      </Grid>
      </div>
      
      <h2 className="TypeOfLoanTitle"><b>Types Of Home Loans</b></h2>
    
      <Grid container className='LoanTypeOf'> 
  <Grid item lg={3} md={6} className='TypeOfLoanGrid'>
    <img className='PropertyImage' alt='logo' src={image8}></img>
   <h4 className='PropertyTitle'><b>1.Property loan</b></h4>
   <p className='TypeloanParagraph'><b>Achieve Your Dream Home with Loanloom,</b> the best provider known for offering property
loan services at an affordable interest rate to their customers. Once the appropriate paperwork
for the acquisition is provided, the first payment of such loan amounts is given to such
borrowers in event of a property loan. The following payments are determined by the house&#39;s
building progress.</p>
  </Grid>

  <Grid item lg={3} md={6} className='TypeOfLoanGridd'>
    <img className='PropertyImage' alt='logo' src={image9}></img>
  <h4 className='PropertyTitle'><b>2.Construction loan</b></h4>
  <p className='TypeloanParagraph'>Loanloom, the leading provider in the industry, understands the needs of customers who
already have their land or plot and are seeking a loan for the construction of their building or
home. <b>Achieve Your Dream Home with Loanloom,</b> as they offer the facility of a
construction loan in an appropriate manner or at a lower interest rate.</p>
  </Grid>

  <Grid item lg={3} md={6} className='TypeOfLoanGriddd'>
    <img className='PropertyImage' alt='logo' src={image10}></img>
  <h4 className='PropertyTitle'><b>3.Remodeling loan</b></h4>
  <p className='TypeloanParagraph'>This loan facility is provided to the customer to repair the condition of their home or building
in a proper manner. <b>Achieve Your Dream Home with Loanloom,</b> as they also offer the best
services in financing loans for customers who want to improve the condition of their houses.
If your home is not looking good and you need a loan for renovations or repairs, Loanloom is
here to help.</p>
  </Grid>

  <Grid item lg={3} md={6} className='TypeOfLoanGridddd'>
    <img className='PropertyImage' alt='logo' src={image11}></img>
  <h4 className='PropertyTitle'><b>4.Purchasing land</b></h4>
  <p className='TypeloanParagraph'><b>Achieve Your Dream Home with Loanloom,</b> as they offer a distinct kind of home loan that
provides customers with the flexibility to acquire a piece of land for building their homes.
Whenever property values are cheap, people may acquire the land with such a loan, and
development can begin whenever customers possess the funds. Customers might even buy
the land for such sole aim of reselling it to make a profit.</p>
  </Grid>

</Grid>

<Grid container className='FeatureGridding'>
  <Grid item lg={4} md={4} className="FeatureGrid">
  <img className="FeatureImagee" alt="logo" src={image2}/>
  <h2 className="PropertyTitle"><b>Features Of Home Loan</b></h2>
  <ul style={{listStyleType:"disc"}}>
  <li className="FeatureParagraph"><b>Achieve Your Dream Home with Loanloom,</b> as they provide a low-interest rate on
their loan.</li>
  <li className="FeatureParagraph">Flexible loan repayment tenure.</li>
  <li className="FeatureParagraph">Low-cost EMI.</li>
  <li className="FeatureParagraph">Affordable home loan available.</li>
  <li className="FeatureParagraph">Documentation procedure is not lengthy.</li>
  <li className="FeatureParagraph">Best digital platform that easily provides the loan.</li>
  </ul>
  </Grid>

  <Grid item lg={4} md={4} className="FeatureGridd">
  <img className="ApplyImage" alt='logo' src={image6}></img>
  <h2 className="PropertyTitle"><b>How to apply for a home loan?</b></h2>
  <ul style={{listStyleType:"disc"}}> 
  <li className="FeatureParagraph">Apply for a home loan on the website of loan-loom <a href="http://loanloom .com">(http://loanloom .com)</a>.</li>
  <li className="FeatureParagraph">Fill in the required documents for the home loan procedure.</li>
  <li className="FeatureParagraph">Applicants must have to pay the processing fee for the home loan.</li>
  <li className="FeatureParagraph">After that home loan verification is done.</li>
  <li className="FeatureParagraph">Applicants’ documents procedure is also done suitably.</li>
  <li className="FeatureParagraph">Home is approved after the proper verifications.</li>
  </ul>
  </Grid>

  
  <Grid item lg={4} md={4} className="FeatureGriddd">
  <img className="DocpImage" alt="logo" src={image5}></img>
  <h2 className="PropertyTitle"><b>Documentation</b></h2>
  <ul style={{listStyleType:"disc"}}>
  <li className="FeatureParagraph">Aadhar card mandatory.</li>
  <li className="FeatureParagraph">Last six months’ banking details.</li>
  <li className="FeatureParagraph">Income certificate.</li>
  <li className="FeatureParagraph">Property papers details.</li>
  <li className="FeatureParagraph">Permanent address details.</li>
  </ul>
  </Grid>

</Grid>

  
<Grid container>
<Grid item lg={6} md={6} xs={12}>
<img className="EligibilityImage" alt="logo" src={image7}></img>
</Grid>

<Grid item lg={6} md={6} xs={12}>
<h2 className="EligibilityTitle"><b>Home Loan Eligibility Criteria</b></h2>
<dl>
<dt><h6 className='EligibilityParagraphTitle'><b>Minimum Age For The Applicants</b></h6></dt>
<dd className="TypeloanParagraph"><p>The age of the applicant for the loan is at least 21 and no 
      person less than 21 applies for the home loan. So, the applicants must have age 21 or above 
      that.</p></dd>

<dt><h6 className='EligibilityParagraphTitle'><b>Salaried Or Non-Salaried person</b></h6></dt>
<dd className="TypeloanParagraph"><p>both can apply for a home loan. But a salaried person can get 
      an advantage because they are offered a low-interest rate due to their employment in 
      comparison to a non-salaried person.</p></dd>

<dt><h6 className='EligibilityParagraphTitle'><b>Residential</b></h6></dt>
<dd className="TypeloanParagraph"><p>It is mandatory for the applicants that permanent residence in India. If the applicant 
      is not a resident of India then it must be not applicable for achieving the home loan services 
      from the institutions.</p></dd>

<dt><h6 className='EligibilityParagraphTitle'><b>Collateral security</b></h6></dt>
<dd className="TypeloanParagraph"><p>The applicant for the home loan must be given something to the bank as 
         collateral security for the assurance of their financial condition stability. So, in that manner 
         customers can also easily be eligible for applying for home loans.</p></dd>
</dl>
</Grid>
</Grid>
    </>
  )
}

export default HomeLoanContent
