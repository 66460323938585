import * as React from "react";
import { styled } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import { useFormik } from "formik";
import Fade from "react-reveal/Fade";
import { Slider } from "@mui/material";
import { PLAmountForRegex } from "../../../../../Forms/FormsStructure/Schemas";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 2500000, label: "> ₹ 2500 K" },
  ],
};

const PLAmount = ({ prevStep, nextStep, handleFormData, values: value,right}) => {
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: PLAmountForRegex,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        loanAmount: formValues.loanAmount,
      });
      nextStep();
    },
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Form>
        <Box>
          <h4 style={{ fontFamily: "poppins" }}>
          Select loan Amount
          </h4>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "20px",fontFamily:'poppins' }}
          >
            <Grid item xs={12}>
              <strong>&#8377; </strong>
              <Input
                name="loanAmount"
                style={{fontFamily:'poppins' }}
                value={values.loanAmount}
                size="small"
                onChange={(e) => setFieldValue("loanAmount", e.target.value)}
                onBlur={handleBlur}
                inputProps={{
                  step: 100,
                  min: 0,
                  max: 2500000,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }} p={1}>
              <Slider
               sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
                value={values.loanAmount || 0}
                marks={IncomeSlider.marksAmt}
                max={2500000}
                onChange={(e, newValue) => setFieldValue("loanAmount", newValue)}
                aria-labelledby="input-slider"
              ></Slider>
            </Grid>
          </Grid>
          {errors.loanAmount ? (
            <p
              style={{
                color: "red",
                fontSize: "14.5px",
                fontFamily: "Comic Sans",
              }}
            >
              {errors.loanAmount}
            </p>
          ) : null}
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <button type="button" className="btn-prevforcity" onClick={prevStep}>
            &#8592; Previous
          </button>

          <button
            className="btn-oneforcity"
            type="submit"
            onClick={handleSubmit}
          >
            Next &#8594;
          </button>
        </div>
      </Form>
 </Fade>
  );
};
export default PLAmount;
