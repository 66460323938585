import React from "react";
import Form from "react-bootstrap/Form";
import Input from "@mui/material/Input/Input";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import { CiLocationOn } from "react-icons/ci";
import axios from "axios";
import Fade from "react-reveal/Fade";
import { PincodeRegex } from "../../../Forms/FormsStructure/Schemas";


// creating functional component ans getting props from app.js and destucturing them
const Pincode = ({ nextStep, handleFormData, prevStep, values:value,mobile,right}) => {

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: { ...value },
    validationSchema: PincodeRegex,

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        Pincode: formValues.Pincode,
      });
      axios.post(`https://www.loanloom.com/loan_phpForm//homeloan_step2.php/${mobile}`,formValues).then((res)=>console.log(res.data));
      axios.post(`https://www.loanloom.com/loan_phpForm/offers_api_step2.php/${mobile}`,formValues).then((res)=>console.log(res.data));
      nextStep();
    },
  });

  
  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)" }}>
    <Fade duration={1000} right={right} left={!right}>
      <Box>
      <h4 style={{ fontFamily: "poppins" }}> Current residential pincode</h4>
      <div style={{ marginTop: "20px" }}>
          <Form>
            <Form.Group className="mb-3">
              <Input
               style={{
                fontSize: "15px",
                fontFamily: "poppins",
                // margin: " 0.5rem 1rem",
                padding: "0.6rem 0.6rem",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <CiLocationOn className="formicon" alt="" />
                </InputAdornment>
              } 
             
              name="Pincode"
              onBlur={handleBlur}
              onChange={handleChange}
              type="otp"
              placeholder="Your Pincode"
               
              />
               {errors.Pincode && touched.Pincode ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14.5px",
                          fontFamily: "Comic Sans",
                          marginTop:"16px"
                        }}
                      >
                        {errors.Pincode}
                      </p>
                    ) : null}
            
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button type="button" className="btn-prev1" onClick={prevStep}>
              &#8592; Previous
              </button>

              <button className="btn-one1" type="submit" onClick={handleSubmit}>
              Next &#8594;
              </button>
            </div>
          </Form>
          </div>
          </Box>
          </Fade>
   // </motion.div>
  );
};

export default Pincode;