import * as React from "react";
import { styled } from "@mui/material/styles";
import Form  from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Slider } from "@mui/material";
import Fade from "react-reveal/Fade";
import MuiInput from "@mui/material/Input";
import { useFormik } from "formik";
import "../index.css";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 10000000, label: "> ₹ 1cr" },
  ],
};

const AverageMonthlyIncome = ({
  prevStep,
  nextStep,
  handleFormData,
  values: value,
  right
}) => {
  const {
    values,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...value },

    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        incentives: formValues.incentives,
      });
      nextStep();
    },
  });


  return (
    //<motion.div initial={{ x: "100%" }} animate={{ x: "calc(50% - 50%)"  }}>
    <Fade duration={1000} right={right} left={!right}>
      <Form>
        <Box>
          <h4 style={{ fontFamily: "poppins" }}>
            Average monthly incentives(optional)
          </h4>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginTop: "20px",fontFamily:'poppins' }}
          >
            <Grid item xs={12}>
              <strong>&#8377; </strong>
              <Input
                name="incentives"
                style={{fontFamily:'poppins'}}
                value={values.incentives}
                size="small"
                onChange={(e) => setFieldValue("incentives", e.target.value)}
                onBlur={handleBlur}
                inputProps={{
                  step: 1000,
                  min: 0,
                  max: 10000000,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }} p={1}>
              <Slider
               sx={{
                color: '#480082',
                '& .MuiSlider-thumb': {
                  border: "2px solid #F68712",
                  background: "#FFF",
                },
                '& .MuiSlider-rail':{
                  backgroundColor: "#bfbfbf"
                }
              }}
                value={values.incentives || 0}
                marks={IncomeSlider.marksAmt}
                max={10000000}
                onChange={(e, newValue) =>
                  setFieldValue("incentives", newValue)
                }
                aria-labelledby="input-slider"
              ></Slider>
            </Grid>
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <button type="button" className="btn-prevforcity" onClick={prevStep}>
            &#8592; Previous
          </button>

          <button
            className="btn-oneforcity"
            type="submit"
            onClick={handleSubmit}
          >
            Next &#8594;
          </button>
        </div>
      </Form>
      </Fade>
    //</motion.div>
  );
};
export default AverageMonthlyIncome;
