import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import BussinessStep1 from "./BussinessStep1";
import BussinessStep3 from "./BussinessStep3";
import BussinessStep2 from "./BussinessStep2";

export default function BussinessHome({ mobile, right: bussinessRight }) {
  const [step, setStep] = useState(1);
  const [right, setRight] = useState(true);
  const [formData, setFormData] = useState({
    companyTurnOver: "",
    loanRequirement: "",
    natureOfBussiness: "",
    yearsIncurrentBussiness: ""
  });

  const nextStep = () => {
    setRight(true);
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
    setRight(false);
  };

  

  switch (step) {
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <BussinessStep1
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={bussinessRight}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <BussinessStep2
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 3:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <BussinessStep3
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  mobile={mobile}
                  right={right}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 4:
      return (
        <div className="formdiv" >
          {/* <Thank /> */}
          
        </div>
      );
    default:
      return <div className="formdiv"></div>;
  }
}
