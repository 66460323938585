import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
}
from 'mdb-react-ui-kit';
import './Getintouch.css'
import { Col, Row } from 'react-bootstrap';
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { BiMessageDetail,BiUser } from "react-icons/bi";
import { Input } from "@mui/material";
import axios from 'axios';
import { contactForm } from "../ContactschemaPopmodel/ContactSchema";
import PopModel from '../ContactschemaPopmodel/PopModel';
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiLinkedinBoxFill } from "react-icons/ri";


const initialValues = {
    fullName: "",
    mobile:"",
    message:"",
  };

function Getintouch() {
    const [modalShow, setModalShow] = React.useState(false);
    
    const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
        initialValues: initialValues,
     validationSchema: contactForm,
      onSubmit: (formValues,action) => {
        const type='contact'
        const secondApI ={
          full_name: formValues.fullName,
          phone: formValues.mobile,
          product: type,
        }
        axios.post('https://www.loanloom.com/loan_phpForm/loanloom_contactUs.php',formValues).then(res=>console.log(res))
        axios
        .post(
          `https://www.uponly.in/Loan_Loom_API/Loan_loom_api.php`,
          secondApI
        )
        .then((res) => console.log(res.data));
        setModalShow(true)
        action.resetForm();
      },
    });
  return (
   <>
   <MDBContainer  className='p-4 background-radial-gradient overflow-hidden'>
   <MDBRow>
   <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>
   <h1 className="my-5 fw-bold ls-tight" style={{color: '#480082'}}>
            24x7 Our Services <br />
            <span style={{color: '#480082'}}>for your loan</span>

            
          </h1>
          <h2 className='getin-para' style={{color:'#9e9b9b'}}>We're available around the clock to assist you with any questions, comments, or concerns you may have. Please feel free to contact us using any of the methods below, and we'll respond as quickly as possible.</h2>
          
    </MDBCol>
    <MDBCol md='6' className='position-relative'>
    <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
          <MDBCard className='my-2 bg-glass'>
          <MDBCardBody className='p-3'>
          
 <form onSubmit={handleSubmit} className="contactForm">
 <h1 className="getin-title">Get In Touch</h1>

                                <Row>
                                    <Col md={12} lg={6} className='mt-4'>
                                        <Input placeholder="Your Name"  name="fullName" className='inputContact' value={values.fullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{fontFamily:"poppins"}}
                                        startAdornment={
                                            <InputAdornment position="start">
                                              <BiUser className="contacticon" />
                                            </InputAdornment>
                                          }
                                        autoComplete="off"
                                        fullWidth
                                        />
                                          {errors.fullName && touched.fullName ? (
                                       <p style={{ fontSize:"13px",color: "red", fontFamily: "Comic Sans",marginTop:"8px", marginLeft:"10px"}}>
                                        {errors.fullName}
                                            </p>
                                        ) : null}
                                       
                                    </Col>
                                    <Col md={12} lg={6}  className='mt-4'>
                                        <Input placeholder="Your Mobile" name="mobile"  value={values.mobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        style={{fontFamily:"poppins"}}
                                        startAdornment={
                                            <InputAdornment position="start">
                                              <HiOutlineDeviceMobile className="contacticon" />
                                            </InputAdornment>
                                          }
                                        autoComplete="off"
                                        fullWidth
                                        />
                                          {errors.mobile && touched.mobile ? (
                                       <p style={{ fontSize:"13px",color: "red", fontFamily: "Comic Sans",marginTop:"8px", marginLeft:"10px"}}>
                                        {errors.mobile}
                                            </p>
                                        ) : null}
                                    </Col>
                                    <Col md={12} lg={12}  className='mt-4'>
                                        <Input variant="standard" placeholder="Your Message..." name ="message" value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{fontFamily:"poppins"}}
                                        startAdornment={
                                            <InputAdornment position="start">
                                              <BiMessageDetail className="contacticon" />
                                            </InputAdornment>
                                          }
                                        fullWidth ></Input>
                                    </Col>
                                    {errors.message && touched.message ? (
                                        <p style={{ fontSize:"13px",color: "red", fontFamily: "Comic Sans",marginTop:"8px", marginLeft:"10px"}}>
                                        {errors.message}
                                            </p>
                                        ) : null}
                                </Row> 
                                <Col className='text-center'>
  <button
    className="getButton"
    type="submit"
    style={{ backgroundColor: '#480082', color: '#fff' }}
  >
    Submit Now
  </button>
</Col>
                                <PopModel show={modalShow}
                             onHide={()=> setModalShow(false)}/>
                                </form>

                                <div className="linkforContactNav">
                                <a href="https://www.facebook.com/profile.php?id=100090455449775" aria-label="Visit our Facebook profile">
  <RiFacebookBoxFill className="linksforContactNavFB" />
</a>

<a href="https://instagram.com/uponly.technologies?igshid=YmMyMTA2M2Y=" aria-label="Visit our Instagram profile">
  <RiInstagramLine className="linksforContactNavInsta" />
</a>

<a href="https://www.linkedin.com/company/uponly-technologies/?viewAsMember=true" aria-label="Visit our LinkedIn profile">
  <RiLinkedinBoxFill className="linksforContactNavLI" />
</a>

            </div>

            </MDBCardBody>
            </MDBCard>

    </MDBCol>
   </MDBRow>
   </MDBContainer>
   </>
  );
}

export default Getintouch;