import * as React from "react";
import { styled } from "@mui/material/styles";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Slider } from "@mui/material";
import MuiInput from "@mui/material/Input/Input";
import Fade from "react-reveal/Fade";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BussinessRegex2 } from "../../../Forms/FormsStructure/Schemas";

const Input = styled(MuiInput)`
  width: 300px;
  fontfamily: sans-serif;
  padding: 0.4rem 0.8rem;
`;
const IncomeSlider = {
  marksAmt: [
    { value: 0, label: "₹ 0" },
    { value: 10000000, label: "> ₹ 1cr" },
  ],
};

const BussinessStep3 = ({ nextStep, handleFormData, values: value, mobile, right }) => {
  const { values, errors, handleBlur,  touched, setFieldValue } =
    useFormik({
      initialValues: { ...value },
      validationSchema: BussinessRegex2,

      onSubmit: (formValues) => {
        handleFormData({
          ...value,
          loanRequirement: formValues.loanRequirement,
        });
        axios
          .post(
            `https://www.loanloom.com/loan_phpForm/businessloan_step2.php/${mobile}`,
            formValues
          )
          .then((res) => console.log(res.data));
        nextStep();
      },
    });
    const navigate=useNavigate();
    

  return (
    <>
      <Fade duration={1000} right={right} left={!right}>
        <Form>
          <Box>
            <h4 style={{ fontFamily: "poppins" }}>Loan Requirement</h4>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "20px", fontFamily: "poppins" }}
            >
              <Grid item xs={12}>
                <strong>&#8377; </strong>
                <Input
                  value={values.loanRequirement}
                  style={{ fontFamily: "poppins" }}
                  size="small"
                  onChange={(e) =>
                    setFieldValue("loanRequirement", e.target.value)
                  }
                  onBlur={handleBlur}
                  inputProps={{
                    step: 100,
                    min: 0,
                    max: 10000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                {errors.loanRequirement && touched.loanRequirement ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "poppins",
                      marginTop: "20px",
                    }}
                  >
                    {errors.loanRequirement}
                  </p>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                p={1}
                style={{ marginTop: "10px", fontFamily: "poppins" }}
              >
                <Slider
                  sx={{
                    color: "#480082",
                    "& .MuiSlider-thumb": {
                      border: "2px solid #F68712",
                      background: "#FFF",
                    },
                    "& .MuiSlider-rail": {
                      backgroundColor: "#bfbfbf",
                    },
                  }}
                  value={values.loanRequirement || 0}
                  marks={IncomeSlider.marksAmt}
                  max={10000000}
                  onChange={(e, newValue) =>
                    setFieldValue("loanRequirement", newValue)
                  }
                  aria-labelledby="input-slider"
                ></Slider>
              </Grid>
            </Grid>
          </Box>
          {/* onClick={handleSubmit} */}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button className="btn-one1" type="submit"  onClick={()=>navigate('/Businessloan_Thankyou')}>
              Submit &#8594;
            </button>
          </div>
        </Form>
      </Fade>
    </>
  );
};

export default BussinessStep3;
