import Modal from 'react-bootstrap/Modal';

const PopModel = (props) => {
  return (
    <>
        <Modal
      {...props}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontFamily:"Bookman" ,color:"#480082"}}>
         Thank you for getting in touch!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{fontFamily:"poppins" ,color:"#F68712"}}>
          One of our team member will be get back in touch with you shortly
        </p>
      </Modal.Body>
    </Modal>
      
    </>
  )
}

export default PopModel
