import React, { useState } from "react";
import AutomobileLoanContent from "../../Service/AutomobileLoan/AutomobileloanContent/AutomobileLoanContent";
import Business_loan from "../../Service/BusinessLoan/BusinessloanContent/Business_loan"; 
import Educational_loan from "../../Service/EducationLoan/EducationloanContent/Educational_loan";
import HomeLoanContent from "../../Service/HomeLoan/HomeloanContent/HomeLoanContent";
import Personal_loan from "../../Service/PersonalLoan/PersonalloanContent/PersonalLoanContent";
import Slider from "./Slider";
import AutomobileLoanTable from "../../Tables/ServicesProduct/AutomobileLoanTable";
import BussinessLoanTable from "../../Tables/ServicesProduct/BussinessLoanTable"; 
import EducationLoanTable from "../../Tables/ServicesProduct/EducationLoanTable";
import HomeLoanTable from "../../Tables/ServicesProduct/HomeLoanTable";
import PersonalLoanTable from "../../Tables/ServicesProduct/PersonalLoanTable";
import Footer from "../../../../Home/Footer/FooterMain/Footer";

export default function Products({ type }) {
  const [show, setShow] = useState(false);
  
  switch (type) {
    case "Home":
      return (
        <>
          <Slider setShow={setShow} />
          <br />
          <br />
          {show && <HomeLoanTable />}
       { show && <HomeLoanContent />}
       { show && <Footer />}
        </>
      );
    case "Personal":
      return (
        <>
          <Slider setShow={setShow} />
          <br />
          <br />
          {show && <PersonalLoanTable />}
        { show && <Personal_loan />}
        { show && <Footer />}
        </>
      );
    case "Offers":
      return (
        <>
        <div className="formheight">
          <Slider setShow={setShow} />
          </div>
        </>
      );
    case "Education":
      return (
        <>
          <Slider setShow={setShow} />
          <br />
          <br />
          {show && <EducationLoanTable />}
        { show &&  <Educational_loan />}
        { show && <Footer />}
        </>
      );
    case "Business":
      return (
        <>
          <Slider setShow={setShow} />
          <br />
          <br />
          {show && <BussinessLoanTable />}
        { show && <Business_loan />}
        { show && <Footer />}
        </>
      );
    case "Automobile":
      return (
        <>
          <Slider setShow={setShow} />
          <br />
          <br />
          {show && <AutomobileLoanTable />}
         {show && <AutomobileLoanContent />}
         { show && <Footer />}
        </>
      );


    default:
      return <div className="formdiv"></div>;
  }
}
