import React from "react";
import "./RightSide.css";
import Img from "../../../../Assets/blog.webp";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const RightSide = ({ posts }) => {
  const scrollTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("https://www.loanloom.com/loan_phpForm/blog_api.php?id=" + path);
      setPost(res.data);
    };
    getPost();
  }, [path]);

  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">ABOUT ME</span>
        <img src={Img} alt="about" />
        <p className="sideDesc">
          "The financial aid for all your needs", An ideology to improve and innovate the lifestyle of the surroundings,
          help in financial aid, and solve all your financial problems through our services..
        </p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle"> LOAN CATEGORIES</span>
        <ul className="sidebarList">
          {posts?.map((post) => (
            <Link key={post.id} to={`/post/${post.id}`} style={{ margin: "auto" }} className="link" onClick={scrollTop}>
              <li className="sidebarListItem">{post.title}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div>
        <Card className="cardDIv">
          <Card.Body>
            <Card.Title className="cardTitle">Get the best offers here</Card.Title>
            <Link to="/eligibility/Offers" className="link" onClick={scrollTop}>
              <button className="cardBtnn">APPLY NOW</button>
            </Link>
          </Card.Body>
        </Card>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW US</span>
        <div className="sidebarSocial">
          <a href="https://www.facebook.com/profile.php?id=100090455449775" className="link">
            <FontAwesomeIcon icon={faFacebook} className="sidebarIcon" />
          </a>
          <a href="https://instagram.com/uponly.technologies?igshid=YmMyMTA2M2Y=" className="link">
            <FontAwesomeIcon icon={faInstagram} className="sidebarIcon" />
          </a>
          <a href="https://www.linkedin.com/company/uponly-technologies/?viewAsMember=true" className="link">
            <FontAwesomeIcon icon={faLinkedinIn} className="sidebarIcon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default RightSide;

