import React from "react";
import Post from "../SingalpostBlog/Post";
import "./LeftSide.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LeftSide = ({ posts }) => {
  return (
    <Row>
      {posts.map((p) => {
        return (
          <Col key={p.id} md={6} sm={true} style={{ display: "flex" }}>
            <Post post={p} />
          </Col>
        );
      })}
    </Row>
  );
};

export default LeftSide;
