import * as React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import "./form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Img1 from "../../../../../Assets/tech-support.png";
import Img1forPhone from "../../../../../Assets/tech-support1.png";
import Img2 from "../../../../../Assets/worker.webp";
import Img2forPhone from "../../../../../Assets/worker.png";
import Img4 from "../../../../../Assets/team.png";
import Img4forPhone from "../../../../../Assets/team1.png";
import Img3 from "../../../../../Assets/freedom.png";
import Img3forPhone from "../../../../../Assets/freedom1.png";
import Box from "@mui/material/Box";
import Index from "../../Service/HomeLoan/Salaried/HomeIndex";
import Fade from "react-reveal/Fade";
import Professional from "../../Service/HomeLoan/Professional/Professional";
 import SelfEmployed from "../../Service/HomeLoan/SelfEmployed/SelfEmployed";
import Independent from "../../Service/HomeLoan/IndependentWorker/Independent";

export default function Type({mobile,values:loan_type,right:typeRight}) {
  const [step, setstep] = useState(0);
  const [right, setRight] = useState(true); 
 const formattedJSON = JSON.stringify(loan_type)

  const [formData, setFormData] = useState({
    emp_type: "",
    loan_type:formattedJSON,
    Income: "",
    Pincode: "",
    currentCity: "",
    gender: "",
    propertyBasedCity: "",
    annualBonus: "",
    incentives: "",
    howWouldyouliketoUse: "",
    emi: "",
    aboutProperty: "",
    nameofBuilder: "",
    costofplot: "",
    coapplicant: "",
    existbankLoan:"",
    whenDidYouBegin:""
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setRight(true)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
    if(step === 1 && step === 4)
    {
      setRight(false)
      setstep(0);
    }
    if(step === 2 ){
      setRight(false)
      setstep(0)
    }
    if(step === 3 ){
      setRight(false)
      setstep(0)
    }
    else{
      setRight(false)
    setstep(step - 1);
    }
  };

  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
      //  <motion.div initial={{ x: "100%" }} animate={{x: "calc(50% - 50%)" }}>
      <Fade duration={1000} right={typeRight} left={!typeRight}>
          <Box>
            <h4 style={{fontFamily:'poppins'}}>Type of employment</h4>
            <div style={{ marginTop: "40px" }}>
              <label>
                <img src={Img1} className="image" alt="TypeImg" />
                <img src={Img1forPhone} className="imageforPhone" alt="TypeImg" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'salaried'})
                  setstep(1)
                }}  />
                <h6 className="type">Salaried</h6>
              </label>

              <label>
                <img src={Img2} className="image" alt="TypeImg" />
                <img src={Img2forPhone} className="imageforPhone" alt="TypeImg" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'professional'})
                  setstep(2)
                }} />
                <h6 className="type">Professional</h6>
              </label>
              <label>
                <img src={Img3} className="image" alt="TypeImg" />
                <img src={Img3forPhone} className="imageforPhone" alt="TypeImg" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'selfEmployed'})
                  setstep(3)
                }} />
                <h6 className="type">Self Employed</h6>
              </label>
              <label>
                <img src={Img4} className="image" alt="TypeImg" />
                <img src={Img4forPhone} className="imageforPhone1" alt="TypeImg" />
                <input type="radio" value={step} onChange={() =>{
                  setFormData({...formData,emp_type:'IndependentWorker'})
                  setstep(4)}} />
                <h6 className="type">Independent Worker</h6>
              </label>
            </div>
          </Box>
          </Fade>
     //   </motion.div>
      );
    case 1:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col>
                <Index
                  nextStep={nextStep}
                  prevStep={prevStep} 
                  handleFormData={setFormData}
                  right={right}
                  values={formData}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="formdiv">
          <Container>
            <Row>
              <Col>
                <Professional
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={setFormData}
                  values={formData}
                  right={right}
                  mobile={mobile}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
      case 3:
        return (
          <div className="formdiv">
            <Container>
              <Row>
                <Col>
                  <SelfEmployed
                    prevStep={prevStep}
                    right={right}
                    nextStep={nextStep}
                    handleFormData={setFormData}
                    values={formData}
                    mobile={mobile}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        );
        case 4:
          return (
            <div className="formdiv">
              <Container>
                <Row>
                  <Col>
                    <Independent
                     prevStep={prevStep}
                     right={right}
                      nextStep={nextStep}
                      handleFormData={setFormData}
                      values={formData}
                      mobile={mobile}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          );

    default:
      return <div className="formdiv"></div>;
  }
}
