import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typed from 'react-typed';
import './Hero.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import first from '../../../../Assets/secondimg.webp'
import second from '../../../../Assets/firstimg.webp'
import third from '../../../../Assets/thirdimg.jpg'

    function Hero() {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const settings = {
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <Grid container className='flex-column-reverse flex-lg-row'>
                <Grid item xs={12} md={6} lg={6} className='herodiv'>
                    <div data-aos="fade-right" className='HeroGridBoxclass'>
                        <div>
                            <Typography variant="h3" className="makingiteasy" style={{ fontFamily: "Bookman" }}>
                                Making It Easy<br />
                                <Typography className="GlobalPurple" component="span" sx={{ fontWeight: 700 }} variant="inherit">
                                    <Typed strings={['Simpler', 'Faster', 'Friendlier']} typeSpeed={100} loop={true} />
                                </Typography>
                            </Typography>
                        </div>
                        <div>
                            <Typography component="p" style={{ fontSize: "18px" }} className='heroPara'>
                                When individuals embark on their quest to discover a financial institution{" "}
                                <span className='hidecontent'>
                                    that can cater to their borrowing needs, <b>LoanLoom, your trusted source for quick loans in India,</b> fulfills their personal or business needs.
                                </span>
                            </Typography>
                        </div>
                        <div>
    <Link to="/eligibility/Offers" style={{ textDecoration: "none", marginRight: "10px" }}>
        <Button variant="contained" className='heroBtn'>Compare Now</Button>
    </Link>
    <Link to="/learnmore" style={{ textDecoration: 'none', color: 'white' }}>
        <Button className='lrnMoreBtn' variant="outlined" onClick={scrollTop}>Learn more..</Button>
    </Link>
</div>

                    </div>
                </Grid> 
                <Grid item xs={12} md={6} lg={6}>
                    <div>
                        <Slider {...settings}>
                            <div>
                                <img
                                    src={third}
                                    className="heropicture"
                                    width={233.25}
                                    height={233.25}
                                    alt="img"
                                />
                            </div>
                            <div>
                                <img src={second} className="heropicture" width={233.25} height={233.25} alt="img" />
                            </div>
                            <div>
                                <img src={first} className="heropicture" width={233.25} height={233.25} alt="img" />
                            </div>
                        </Slider>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Hero;
