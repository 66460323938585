import './loader.css'

const Loader = () => {
  return (
    <>
    <div className="loader"></div>
    <span className='loadercontent'>Loan Loom</span>
    </>
   
  )
}

export default Loader
