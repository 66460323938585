import React from "react";
import { Form } from "react-bootstrap";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "@mui/material/Input/Input";
import { useFormik } from "formik";
import { GiCalendarHalfYear } from "react-icons/gi";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "react-reveal/Fade";
import { BussinessRegex1 } from "../../../Forms/FormsStructure/Schemas";


const BussinessStep2 = ({ nextStep, handleFormData, values: value, right, prevStep }) => {
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: { ...value },
    validationSchema: BussinessRegex1,
    onSubmit: (formValues) => {
      handleFormData({
        ...value,
        yearsIncurrentBussiness: formValues.yearsIncurrentBussiness
      });

      nextStep();
    }
  });

  return (
    <Fade duration={1000} right={right} left={!right}>
      <Box>
        <Form>
          <Row style={{ marginTop: "25px" }}>
            <Col md={12} lg={12}>
              <Form.Group>
                <Input
                  fullWidth
                  style={{
                    fontSize: "15px",
                    fontFamily: "poppins",
                    padding: "0.6rem 0.6rem"
                  }}
                  name="yearsIncurrentBussiness"
                  value={values.yearsIncurrentBussiness}
                  type="number"
                  placeholder="Years In current business"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  startAdornment={
                    <InputAdornment position="start">
                      <GiCalendarHalfYear className="formicon" />
                    </InputAdornment>
                  }
                />
                {errors.yearsIncurrentBussiness && touched.yearsIncurrentBussiness ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14.5px",
                      fontFamily: "Comic Sans",
                      marginTop: "12px"
                    }}
                  >
                    {errors.yearsIncurrentBussiness}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button type="button" className="btn-prev1" onClick={prevStep}>
              &#8592; Previous
            </button>

            <button className="btn-one1" type="submit" onClick={handleSubmit}>
              Next &#8594;
            </button>
          </div>
        </Form>
      </Box>
    </Fade>
  );
};

export default BussinessStep2;
