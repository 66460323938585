import React from 'react';
import './Service.css';
import { Link } from 'react-router-dom';

function Services(props) {
  const scrollTop = () => window['scrollTo']({ top: 0, behavior: 'smooth' });
return (
    
<div className="carousel carousel-dark slide">
<div className="card" >
                    <img src={props.imgSrc} className="card-img-top" alt="service" />


                    <div className="card-body">
                    <h1 className="card-title">{props.name}{" "}Loan</h1>
                        <p className='card-paragraph'>{props.description}</p>
                        
                        <Link
  to={`/eligibility/${props.name}`}
  className="branBtnforService"
  onClick={scrollTop}
  style={{ backgroundColor: '#480082', color: '#FFFFF' }}
  tabIndex="-1"
>
  Check Eligibility
</Link>

                    </div>
                    </div>

    </div>


);
}

export default Services;
