import HomeImage from "../../../../Assets/homeService.webp"
import PersonalImage from "../../../../Assets/personalloanService.webp"
import EducationImage from "../../../../Assets/educationService.webp"
import BussinesssImage from "../../../../Assets/businessService.webp"
import CarImage from "../../../../Assets/automobileService.webp"

  const Menu = [
    {
      id: 1,
      name: "Home",
       imgsrc:HomeImage,
      description:
        "A home loan is a secured loan that is obtained to purchase a property by offering it as collateral.Home loans offer high-value funding at economic interest rates and rates  for..",
    },
  
    {
      id: 2,
      name: "Personal",
      imgsrc:PersonalImage,
      description:
        "A personal loan is money borrowed from a bank, credit union or online lender that you pay back in fixed monthly payments, or installments, typically over two to seven years..",
    },
    {
      id: 3,
      name: "Education",
       imgsrc:EducationImage,
      description:
        "An education loan is a sum of money borrowed to finance post-secondary education or higher education-related expenses. Education loans are important intended..",
    },
    {
      id: 4,
      name: "Business",
     imgsrc:BussinesssImage,
      description:
        "A business loan is a loan specifically intended for business purposes. As with all loans, it involves the creation of a debt, which will be repaid with added tax  interest..",
    },
    {
      id: 5,
      name: "Automobile",
       imgsrc:CarImage,
      description:
        "A car loan (also known as an automobile loan, or auto loan) is a sum of money a consumer borrows in order to purchase a car. Generally speaking a loan is an amount..",
    },

    
  ];
  
  export default Menu;
  
