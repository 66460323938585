import { faMobileAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

  
export const usefulLink = [
    
    { name: 'Home', id: 1, path: "/" },
    {name: 'About us', id: 2,path:"/AboutUs"},
    {name: 'Services', id: 3, path :"/eligibility/Offers"},
    {name: 'Blogs', id: 4, path:"/blog"},
    {name: 'Contact us', id: 5, path:"/contact"}
    
]
export const ourServices = [
    {name: 'Home Loan', id: 6, path:'/eligibility/Home'},
    {name: 'Personal Loan', id: 7, path:"/eligibility/Personal"},
    {name: 'Education Loan', id: 8, path:"/eligibility/Education"},
    {name: 'Business Loan', id: 9, path:"/eligibility/Business"},
    {name: 'Automobile Loan', id: 10, path:"/eligibility/Automobile"}
    ]
export const otherLinks = [
    {name: 'Privacy Policy', id: 11, path:"/privacy"},
    {name: 'Support', id: 12, path:"/contact"},
    {name: 'FAQ', id: 13, path:"/FAQs"},
    {name: 'Feedback', id: 14, path:"/feedback"},
    {name: 'Terms & Conditions', id: 15, path:"/terms"},
    {name: 'RBI Sachet', id: 16, path:"https://sachet.rbi.org.in/"},
    
]

export const footerInfo = [
    {icon:'',info1: 'LOAN LOOM', id: 1},
    {icon: faMobileAlt, phnInfo: '022-46052303', emailInfo: 'care@loanloom.com', id: 2},
    {icon: faMapMarkedAlt, info1: 'OFFICE NO.1617,BUILDING A1 RUPA SOLITAIRE MBP, MAHAPE NAVI MUMBAI-400701', id: 3}
];



