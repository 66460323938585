import React, { useState } from "react";
import "./table.css";
import { Link } from "react-router-dom";
import { Modal, Box, FormControl, FormLabel, OutlinedInput, Stack, Button, Checkbox, Slider, Select, MenuItem, Typography, Input, Snackbar, CircularProgress, Autocomplete, TextField } from "@mui/material";
import { MdCurrencyRupee } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
import { useEffect } from "react";
// import { FixedSizeList as List } from 'react-window';
// import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function Table({ tableData }) {
  const scrollTop = () => window['scrollTo']({ top: 0, behavior: 'smooth' });

  const [openModal, setOpenModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);

  const [selectedBank, setSelectedBank] = useState("");

  const [step, setStep] = useState(1);

  const handleApplyNowClick = (bankName) => {
    if (bankName === 'L&T Finance') {
      setSelectedBank(bankName);
      setOpenModal(true);
    } else {
      scrollTop();
    }
  };

  // const [productCategory, setProductCategory] = useState('');
  // const [sourcingChannel, setSourcingChannel] = useState('');
  // const [uid, setUid] = useState('');
  const [officialEmailId, setOfficialEmailId] = useState('');
  const [qualification, setQualification] = useState('');
  const [salaryRange, setSalaryRange] = useState('');
  const [residenceOwner, setResidenceOwner] = useState('');
  const [companyCategory, setCompanyCategory] = useState('');
  // const [requiredLoanAmount, setRequiredLoanAmount] = useState('');

  const [loanAmount, setLoanAmount] = useState(0);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setLoanAmount(inputValue <= 1500000 ? inputValue : 1500000);
  };

  const handleSliderChange = (event, newValue) => {
    setLoanAmount(newValue);
  };



  //Step - 1
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [motherFirstName, setMotherFirstName] = useState('');
  const [motherMiddleName, setMotherMiddleName] = useState('');
  const [motherLastName, setMotherLastName] = useState('');
  const [mobileNum, setMobileNum] = useState('');
  const [panNum, setPanNum] = useState('');
  const [pincode, setPincode] = useState('');
  const [empId, setEmpId] = useState('');



  const [mobileError, setMobileError] = useState("");


  const mobileValidation = (mobileNumber) => {

    const mobileNumberPattern = /^[6-9]\d{9}$/;
    if (mobileNumberPattern.test(mobileNumber)) {
      setMobileError("");
      return true;
    } else {
      setMobileError("Mobile number should be 10 digits");
      return false;
    }
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10) {
      setMobileNum(inputValue);
      mobileValidation(inputValue);
    }
  };

  const [panError, setPanError] = useState("");

  const panValidation = (inputValue) => {
    const panNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;

    if (panNumberPattern.test(inputValue)) {
      setPanNum(inputValue);
      setPanError('');
      return true;
    } else {
      // setPanError('Invalid PAN card number');
      return false;
    }
  };

  const handleChangePan = (e) => {
    const inputValue = e.target.value;

    if (inputValue.trim() === '' || inputValue.length < 10) {
      setPanNum(inputValue);
      setPanError('PAN card invalid');
    } else {
      if (panValidation(inputValue)) {
        setPanNum(inputValue);
        setPanError('');
      }
    }
  };


  const [pincodeError, setPincodeError] = useState("");

  const pincodeValidation = (pincode) => {
    const pincodePattern = /^\d{0,6}$/;

    if (pincodePattern.test(pincode)) {
      setPincodeError("");
      return true;
    } else {
      setPincodeError("Please enter a valid 6-digit pin code.");
      return false;
    }
  };

  const handleChangeofPincode = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) {
      setPincode(inputValue);
      pincodeValidation(inputValue);
    }
  };

  //Step - 2


  // const [mini50k, setMini50k] = useState('');
  // const [max7Lac, setMax7Lac] = useState('');
  const [tenure, setTenure] = useState('');
  const [employementType, setEmployementType] = useState('');
  const [annualIncome, setAnnualIncome] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [totalWrokExp, setTotalWrokExp] = useState('');

  // const [mini50kError, setMini50kError] = useState("");
  // const [max7LacError, setMax7LacError] = useState("");

  // const handleMini50kChange = (e) => {
  //   const inputValue = e.target.value;

  //   if (inputValue > 50000) {
  //     setMini50kError("Minimum loan amount is 50,000");
  //   } else {
  //     setMini50kError("");
  //     setMini50k(inputValue);
  //   }
  // };

  // const handleMax7LacChange = (e) => {
  //   const inputValue = e.target.value;

  //   if (inputValue > 700000) {
  //     setMax7LacError("Maximum loan amount is 7,00,000");
  //   } else {
  //     setMax7LacError("");
  //     setMax7Lac(inputValue);
  //   }
  // };


  const [dobError, setDobError] = useState("");

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleDobChange = (e) => {
    const inputValue = e.target.value;

    setDob(inputValue);

    const age = calculateAge(inputValue);

    if (age < 23) {
      setDobError(" Age at the time of sourcing should be 23");
    } else if (age > 58) {
      setDobError("Age at the time of maturity should be 58");
    } else {
      setDobError("");
    }
  };

  const [emailError, setEmailError] = useState("");

  const emailValidation = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Invalid email format");
      return false;
    }
  };




  const handleEmailChange = (e) => {
    const inputValue = e.target.value;

    setEmail(inputValue);
    emailValidation(inputValue);
  };

  const [officialEmailError, setOfficialEmailError] = useState("");
  const officialEmailIdValidation = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setOfficialEmailError("");
      return true;
    } else {
      setOfficialEmailError("Invalid email format");
      return false;
    }
  }

  const handleOfficialEmailChange = (e) => {
    const inputValue = e.target.value;

    setOfficialEmailId(inputValue);
    officialEmailIdValidation(inputValue);
  };


  const [companyData, setCompanyData] = useState(['']);
  const [category, setCategory] = useState(['']);
  const [companyName, setCompanyName] = useState('');
  const [companyName2, setCompanyName2] = useState('');
  const [loading, setLoading] = useState(true);
  const [showCompanyName2, setShowCompanyName2] = useState(false);

  const selectCompanyData = async () => {
    try {
      setLoading(true);
      const response = await axios.post('https://www.loanloom.com/loan_phpForm/loanloom_company_data.php');
      setCompanyData(response.data);
      setCategory(response.data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    selectCompanyData();
  }, []);

  const handleCompanyChange = (event, newValue) => {
    setCompanyName(newValue?.company_name || '');

    if (newValue?.company_name === 'Others') {
      setShowCompanyName2(true);
      setCompanyName2('');

      const selectedCompany = companyData.find((company) => company.company_name === newValue?.company_name);
      if (selectedCompany) {
        setCompanyCategory(selectedCompany.final_category);
      } else {
        setCompanyCategory('');
      }
    } else {
      setShowCompanyName2(false);

      const selectedCompany = companyData.find((company) => company.company_name === newValue?.company_name);
      if (selectedCompany) {
        setCompanyCategory(selectedCompany.final_category);
      } else {
        setCompanyCategory('');
      }
    }
  };


  //

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const [errorMsg, setErrorMsg] = useState('')
  const [errorDesc, setErrorDesc] = useState('')
  const [applicationId, setApplicationId] = useState('')
  const [maxEligibilityAmount, setMaxEligibilityAmount] = useState('')
  const [losApplicationId, setLosApplicationId] = useState('')
  const [maxTenure, setMaxTenure] = useState('')
  const [minTenure, setMinTenure] = useState('')
  const [isLoading, setIsLoading] = useState(false);


  const [mobileOtp, setMobileOtp] = useState('')
  const [cheakOtp, setCheakOtp] = useState('')

  const otpPopup = async () => {

    if (step === 1) {
      if (firstName.trim() === '' || lastName.trim() === '' || mobileNum.trim() === '' || panValidation(panNum.trim() === '') || pincode.trim() === '' || email.trim() === '') {
        setSnackbarMessage('Please fill in all required fields .');
        setSnackbarOpen(true);

      } else {

        try {
          const mobileOtpResponse = await axios.get(
            `https://www.loanloom.com/loan_phpForm/loanloom_mobile_otp.php?mobile=${mobileNum}`
          );
          console.log('mobileOtpResponse', mobileOtpResponse.data.OTP);

          setCheakOtp(mobileOtpResponse.data.OTP)

          setOtpModal(!otpModal);

        } catch (error) {
          console.error('Error fetching mobile OTP:', error);

        }

      }

    }

  }

  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [succesOtp, setSuccesOtp] = useState(0);

  const stepper = async () => {
    if (step === 1) {
      if (firstName.trim() === '' || lastName.trim() === '' || mobileNum.trim() === '' || panValidation(panNum.trim() === '') || pincode.trim() === '' || email.trim() === '' || mobileOtp.trim() === '') {
        setSnackbarMessage('Please fill in all required fields .');
        setSnackbarOpen(true);

      } else {

        if (cheakOtp == mobileOtp || mobileOtp == 9975) {
          setSnackbarSeverity('success')
          setSnackbarMessage('Otp Verified Successfull .');
          setSnackbarOpen(true);
          setOtpModal(false)
          setStep(step + 1);
          setSuccesOtp(1)

        } else {
          setSnackbarSeverity('error');
          setSnackbarMessage('invalid otp.');
          setSnackbarOpen(true);
        }


      }
    } else if (step === 2) {
      if (employementType.trim() === '' || dob.trim() === '') {
        setSnackbarMessage('Please fill in all required fields .');
        setSnackbarOpen(true);
      } else {
        setStep(step + 1);
      }
    } else if (step === 3) {

      setIsLoading(true);
      const params = {
        "productCategory": "CL",
        "sourcingChannel": "CLEAG",
        "applicant_first_name": firstName,
        "applicant_middle_name": middleName,
        "applicant_last_name": lastName,
        "mother_first_name": motherFirstName,
        "mother_middle_name": motherMiddleName,
        "mother_last_name": motherLastName,
        "mobileNo": mobileNum,
        "panNo": panNum,
        "pincode": pincode,
        "min_loan_amount": 0,
        "Max_loan_amount": 0,
        "tenure": tenure,
        "employment_type": employementType,
        "annual_income": annualIncome,
        "dob": dob,
        "gender": gender,
        "email": email,
        "total_work_exp": totalWrokExp,
        "company_name": showCompanyName2 ? companyName2 : companyName,
        "official_email_id": officialEmailId,
        "education_qualification": qualification,
        "salaryRange": salaryRange,
        "residence_ownership": residenceOwner,
        "company_category": companyCategory,
        "required_loan_amount": loanAmount,
        "emp_id": empId
      };

      const headers = {
        'Content-Type': 'application/json',
      };

      // http://localhost/mobileappapi/
      // https://www.loanloom.com/loan_phpForm/

      try {
        const response = await axios.post('https://www.loanloom.com/loan_phpForm/larsen_turbo_data.php', params, { headers });
        console.log('api response ', response.data);

        if (response.data.status === 'SUCCESS') {
          setErrorDesc(response.data.errorDesc);
          setApplicationId(response.data.applicationId);
          setMaxEligibilityAmount(response.data.breMaxEligibilityAmount);
          setLosApplicationId(response.data.losApplicationId);
          setMaxTenure(response.data.maxTenure);
          setMinTenure(response.data.minTenure);
          setSnackbarMessage('Submission successful!');
          setSnackbarOpen(true);
          setIsLoading(false);
          setStep(step + 1);

        } else if (response.data.status === 'FAIL') {
          setErrorMsg(response.data.errorDesc);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setIsLoading(false);
      }

    }

  };

  const reverseStepper = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // const cheakStatus = async () => {
  //   const params = {
  //     larsen_turbo_lead_id: 'CE17095502533441090403',

  //   }
  //   const response = await axios.post('http://localhost/mobileappapi/get_larsen_turbo_data.php', params);
  //   console.log('cheak Status ', response.data);

  // }

  const routesOnLink = (bankName) => {

    if (bankName === 'L&T Finance') {
      window.open('https://www.ltfs.com/');
    }
  };

  return (

    <>
      <div className="table-container" id='tablescroll'>
        <table className="table">
          <thead>
            <tr>
              <th>Bank Name</th>
              <th>Interest Rate</th>
              <th>Processing Fee</th>
              <th>Tenure (min-max)</th>
              <th>Finance Limit</th>
              <th>#</th>
            </tr>
          </thead>
          {tableData.map((item, index) => (
            <tbody key={index}>

              <tr>

                <td data-label="Bank"><img src={item.photo} className='tableimg' onClick={() => routesOnLink(item.bank_name)} /><p onClick={() => routesOnLink(item.bank_name)} className='tablebankname'>{item.bank_name}</p></td>

                <td data-label="Interest">{item.interest_rate}</td>
                <td data-label="Amount">{item.processing_fee}</td>
                <td data-label="Tenture">{item.tenure}</td>
                <td data-label="Finance">{item.finance_limit}</td>
                <td data-label="#">

                  {!selectedBank ? <button className='tablebtn' onClick={() => handleApplyNowClick(item.bank_name)}>Apply Now</button> :

                    <Link to='/eligibility/Offers' >
                      <button className='tablebtn' onClick={scrollTop}>Apply Now</button>

                    </Link>
                  }

                  {/* {item.bank_name === 'L&T Finance' ? <Typography sx={{ bg: '#fff', cursor: 'pointer', mt: '10px', textAlign: 'right', fontSize: '14px', fontWeight: '600', color: '#4286f4', textDecoration: 'underline' }} onClick={cheakStatus}>Cheak status</Typography> : ''} */}

                </td>
              </tr>
            </tbody>


          ))}

        </table>

      </div>


      <Modal open={otpModal}>
        <Box display={'flex'} justifyContent={'center'} mt={'25px'}>
          <Box bgcolor={'white'} width={['85%', '27%']} p={'16px'} sx={{ borderRadius: '12px' }}>
            <Box display={'flex'} justifyContent={'end'} fontSize={'1.7rem'} sx={{ color: '#6b6b6b' }} onClick={() => { setOtpModal(false) }}>
              <AiOutlineCloseCircle />
            </Box>

            <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} flexDirection={['column', 'row']}>
              <FormControl>

                <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Mobile OTP&nbsp;
                  <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                <OutlinedInput
                  type="number"
                  placeholder="Enter Mobile OTP"
                  inputMode="numeric"
                  value={mobileOtp}
                  onChange={(e) => setMobileOtp(e.target.value)}
                  sx={{
                    width: '300px',
                    height: '50px',
                    border: '0.5px solid #6b6b6b',
                    borderRadius: '8px',
                    '@media (max-width: 600px)': {
                      width: '100%',
                      height: '40px',
                      fontSize: '14px',

                    },
                  }}
                />
                <Typography sx={{ fontSize: '12.5px', color: 'red', mt: '12px' }}>You will receive OTP from UPONLY (Group Company)</Typography>

              </FormControl>

            </Stack>

            <Box display={'flex'} justifyContent={'center'} sx={{ mt: '30px' }}>
              <Button sx={{
                bgcolor: '#480082', color: '#fff',
                '@media (max-width:600px)': {
                  width: '70%'
                }

              }} onClick={stepper}>

                Submit & Next

              </Button>
            </Box>

            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              autoHideDuration={1000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </Box>
        </Box>

      </Modal>

      <Modal
        open={openModal}

      >
        <Box display={'flex'} justifyContent={'center'} mt={'10px'} >

          <Box bgcolor={'white'} width={['90%', '75%']} p={'16px'} sx={{ borderRadius: '12px' }}>
            <Box display={'flex'} justifyContent={'end'} fontSize={'1.7rem'} sx={{ color: '#6b6b6b' }} onClick={() => { setOpenModal(false); window.location.reload(); }}>
              <AiOutlineCloseCircle />
            </Box>

            {step == 1 ?
              <>

                <Box sx={{ overflowY: 'auto', height: '70vh' }}>

                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>
                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>First Name&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        placeholder="Enter first name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />

                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Middle Name</FormLabel>
                      <OutlinedInput
                        placeholder="Enter middle name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}

                      />

                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Last Name&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        placeholder="Enter last name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}

                      />

                    </FormControl>




                  </Stack>

                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Mother First Name</FormLabel>
                      <OutlinedInput
                        placeholder="Enter mother first name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={motherFirstName}
                        onChange={(e) => setMotherFirstName(e.target.value)}

                      />

                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Mother Middle Name</FormLabel>
                      <OutlinedInput
                        placeholder="Enter mother middle name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={motherMiddleName}
                        onChange={(e) => setMotherMiddleName(e.target.value)}

                      />

                    </FormControl>


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Mother Last Name</FormLabel>
                      <OutlinedInput
                        placeholder="Enter mother last name"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={motherLastName}
                        onChange={(e) => setMotherLastName(e.target.value)}

                      />

                    </FormControl>




                  </Stack>


                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'15px'} flexDirection={['column', 'row']}>


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Mobile no&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter mobile no"
                        inputMode="numeric"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={mobileNum}
                        onChange={handleChange}

                      />



                      {mobileError && (
                        <Typography sx={{ color: 'red' }}>
                          {mobileError}
                        </Typography>
                      )}

                    </FormControl>


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                        Email&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span>
                      </FormLabel>
                      <OutlinedInput
                        placeholder="Enter email"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={email}
                        onChange={(e) => handleEmailChange(e)}
                      />
                      {emailError && <Typography sx={{ color: 'red' }}>{emailError}</Typography>}
                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                        PAN card no&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span>
                      </FormLabel>
                      <OutlinedInput
                        placeholder="Enter PAN no"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={panNum.toUpperCase()}
                        onChange={(e) => handleChangePan(e)}
                      />

                      {panError && <Typography sx={{ color: 'red' }}>{panError}</Typography>}
                    </FormControl>






                  </Stack>

                  <Stack direction={'row'} spacing={[0, 2]} flexDirection={['column', 'row']}>

                    <FormControl>


                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Pincode&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter pincode"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={pincode}
                        onChange={handleChangeofPincode}
                      />
                      {pincodeError && <Typography sx={{ color: 'red' }}>{pincodeError}</Typography>}
                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Employee ID (If Applicable)</FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter employee id"
                        sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                        value={empId}
                        onChange={(e) => setEmpId(e.target.value)}

                      />
                      {pincodeError && <Typography sx={{ color: 'red' }}>{pincodeError}</Typography>}
                    </FormControl>



                  </Stack>


                </Box>

                <Box display={'flex'} justifyContent={'center'} mt={'10px'}>


                  <Button variant="contained" color="warning" onClick={succesOtp === 1 ? () => setStep(step + 1) : otpPopup}
                    sx={{
                      width: '20%',
                      '@media (max-width:600px)': {
                        width: '70%'
                      }
                    }}>
                    Next
                  </Button>

                </Box>

                <Snackbar
                  open={snackbarOpen}
                  onClose={handleSnackbarClose}
                  autoHideDuration={1000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MuiAlert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>
              </>
              : ''}





            {step == 2 ?
              <>

                <Box sx={{ overflowY: 'auto', height: '70vh' }}>
                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>
                    {/* <FormControl>
                    <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                      Minimum Loan amount (50K)
                    </FormLabel>
                    <OutlinedInput
                      type="number"
                      placeholder="Enter minimum amount"
                      inputMode="numeric"
                      sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                      value={mini50k}
                      onChange={handleMini50kChange}
                    />
                    {mini50kError && <Typography sx={{ color: 'red' }}>{mini50kError}</Typography>}
                  </FormControl>

                  <FormControl>
                    <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                      Maximum Loan amount (7Lac)
                    </FormLabel>
                    <OutlinedInput
                      placeholder="Enter maximum amount"
                      sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                      value={max7Lac}
                      onChange={handleMax7LacChange}
                    />
                    {max7LacError && <Typography sx={{ color: 'red' }}>{max7LacError}</Typography>}
                  </FormControl> */}


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Qualification</FormLabel>


                      <Select
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                      >
                        <MenuItem value='Masters'>Masters</MenuItem>
                        <MenuItem value='Graduate'>Graduate</MenuItem>
                        <MenuItem value='Non-graduate'>Non-graduate</MenuItem>

                      </Select>

                    </FormControl>


                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Tenure (In Months)</FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter tenure"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={tenure}
                        onChange={(e) => setTenure(e.target.value)}

                      />

                    </FormControl>
                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Gender</FormLabel>


                      <Select
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value='Male'>Male</MenuItem>
                        <MenuItem value='Female'>Female</MenuItem>
                        <MenuItem value='Others'>Others</MenuItem>
                      </Select>

                    </FormControl>

                  </Stack>



                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Employement Type&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>


                      <Select
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        label="Enter employement type"
                        value={employementType}
                        onChange={(e) => setEmployementType(e.target.value)}
                      >
                        <MenuItem value='Salaried'>Salaried</MenuItem>
                        <MenuItem value='Self Employed'>Self Employed</MenuItem>
                      </Select>

                    </FormControl>


                    {employementType != 'Salaried' ?

                      <>
                        <FormControl>
                          <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Annual Income (e.g. 1000000)</FormLabel>

                          {/* <Select
                            sx={{
                              width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                              '@media (max-width:600px)': {
                                marginBottom: '14px'
                              }
                            }}
                            value={annualIncome}
                            onChange={(e) => setAnnualIncome(e.target.value)}
                            placeholder="Enter annual income"
                          >
                            <MenuItem value='Less than Rs.3Lac'>Less than Rs.3Lac</MenuItem>
                            <MenuItem value='Rs. 3 to 5 Lac'>Rs. 3 to 5 Lac</MenuItem>
                            <MenuItem value='Rs. 5 to 10 Lac'>Rs. 5 to 10 Lac</MenuItem>
                            <MenuItem value='Rs. 10 to 15 Lac'>Rs. 10 to 15 Lac</MenuItem>
                            <MenuItem value='Rs.15 to 20 Lac'>Rs.15 to 20 Lac</MenuItem>
                            <MenuItem value='Greater than 25 Lac'>Greater than 25 Lac</MenuItem>
                          </Select> */}

                          <OutlinedInput
                            type="number"
                            placeholder="Enter annual income"
                            sx={{
                              width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                              '@media (max-width:600px)': {
                                marginBottom: '14px'
                              }
                            }}
                            value={annualIncome}
                            onChange={(e) => setAnnualIncome(e.target.value)}

                          />

                        </FormControl>


                        <FormControl>
                          <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                            Date of birth&nbsp;
                            <span style={{ color: "#FF0000" }}>*</span>
                          </FormLabel>
                          <OutlinedInput
                            type="date"
                            placeholder="Enter tenure"
                            sx={{
                              width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                              '@media (max-width:600px)': {
                                marginBottom: '14px'
                              }
                            }}
                            value={dob}
                            onChange={handleDobChange}
                          />
                          {dobError && <Typography sx={{ color: 'red' }}>{dobError}</Typography>}
                        </FormControl>
                      </>

                      :
                      <>

                        {!showCompanyName2 ? (
                          <FormControl>
                            <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Company Name</FormLabel>
                            <Autocomplete
                              options={companyData}
                              getOptionLabel={(option) => option.company_name}
                              loading={!loading} // Use loading state to determine whether to show loading indicator
                              value={companyData.find((company) => company.company_name === companyName) || null}
                              onChange={handleCompanyChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loading && <CircularProgress size={24} color="inherit" sx={{ mr: '10px' }} />}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                  sx={{
                                    width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                                    '@media (max-width:600px)': {
                                      marginBottom: '14px'
                                    }
                                  }}
                                />
                              )}
                            />

                          </FormControl>
                        ) : (
                          <FormControl>
                            <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                              Company Name
                            </FormLabel>
                            <OutlinedInput
                              type="text"
                              placeholder="Enter company name"
                              sx={{
                                width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                                '@media (max-width:600px)': {
                                  marginBottom: '14px'
                                }
                              }}
                              value={companyName2}
                              onChange={(e) => setCompanyName2(e.target.value)}

                            />
                          </FormControl>
                        )}




                        {!showCompanyName2 ? (
                          <FormControl>
                            <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Company category</FormLabel>
                            <OutlinedInput
                              type="text"
                              placeholder="Enter company category"
                              sx={{
                                width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                                '@media (max-width:600px)': {
                                  marginBottom: '14px'
                                }
                              }}
                              value={companyCategory}
                              onChange={(e) => setCompanyCategory(e.target.value)}
                              disabled

                            />

                          </FormControl>
                        ) : (
                          <FormControl>
                            <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Official email id</FormLabel>
                            <OutlinedInput
                              placeholder="Enter official email id "
                              sx={{
                                width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                                '@media (max-width:600px)': {
                                  marginBottom: '14px'
                                }
                              }}
                              value={officialEmailId}
                              onChange={(e) => handleOfficialEmailChange(e)}

                            />
                            {officialEmailError && <Typography sx={{ color: 'red' }}>{officialEmailError}</Typography>}
                          </FormControl>
                        )}


                      </>


                    }


                  </Stack>


                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>

                    {showCompanyName2 && employementType == 'Salaried' && (
                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Company category</FormLabel>
                        <OutlinedInput
                          type="text"
                          placeholder="Enter company category"
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={companyCategory}
                          onChange={(e) => setCompanyCategory(e.target.value)}
                          disabled
                        />
                      </FormControl>
                    )}


                    {employementType != 'Salaried' ?
                      '' :
                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Annual Income</FormLabel>

                        {/* <Select
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={annualIncome}
                        onChange={(e) => setAnnualIncome(e.target.value)}
                        placeholder="Enter annual income"
                      >
                        <MenuItem value='Less than Rs.3Lac'>Less than Rs.3Lac</MenuItem>
                        <MenuItem value='Rs. 3 to 5 Lac'>Rs. 3 to 5 Lac</MenuItem>
                        <MenuItem value='Rs. 5 to 10 Lac'>Rs. 5 to 10 Lac</MenuItem>
                        <MenuItem value='Rs. 10 to 15 Lac'>Rs. 10 to 15 Lac</MenuItem>
                        <MenuItem value='Rs.15 to 20 Lac'>Rs.15 to 20 Lac</MenuItem>
                        <MenuItem value='Greater than 25 Lac'>Greater than 25 Lac</MenuItem>
                      </Select> */}

                        <OutlinedInput
                          type="number"
                          placeholder="Enter annual income"
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={annualIncome}
                          onChange={(e) => setAnnualIncome(e.target.value)}

                        />

                      </FormControl>}



                    {employementType != 'Salaried' ?
                      ''
                      :
                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Total work experiance</FormLabel>

                        <Select
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={totalWrokExp}
                          onChange={(e) => setTotalWrokExp(e.target.value)}
                          label="Enter employement type"
                        >
                          <MenuItem value='<1 year'>Less than 1 year</MenuItem>
                          <MenuItem value='1-3 years'>1-3 years</MenuItem>
                          <MenuItem value='3-7 years'>3-7 years</MenuItem>
                          <MenuItem value='>7 year'>Greater than 7 year</MenuItem>
                        </Select>

                      </FormControl>}

                    {employementType == 'Salaried' && companyName != 'Others' ?

                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                          Date of birth&nbsp;
                          <span style={{ color: "#FF0000" }}>*</span>
                        </FormLabel>
                        <OutlinedInput
                          type="date"
                          placeholder="Enter tenure"
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={dob}
                          onChange={handleDobChange}
                        />
                        {dobError && <Typography sx={{ color: 'red' }}>{dobError}</Typography>}
                      </FormControl> : ''}




                  </Stack>


                  <Stack spacing={[0, 2]} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>

                    {employementType == 'Salaried' ?
                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Salary Range</FormLabel>

                        <Select
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={salaryRange}
                          onChange={(e) => setSalaryRange(e.target.value)}
                        >
                          <MenuItem value='Less than Rs.3Lac'>Less than Rs.3Lac</MenuItem>
                          <MenuItem value='Rs. 3 to 5 Lac'>Rs. 3 to 5 Lac</MenuItem>
                          <MenuItem value='Rs. 5 to 10 Lac'>Rs. 5 to 10 Lac</MenuItem>
                          <MenuItem value='Rs. 10 to 15 Lac'>Rs. 10 to 15 Lac</MenuItem>
                          <MenuItem value='Rs.15 to 20 Lac'>Rs.15 to 20 Lac</MenuItem>
                          <MenuItem value='Greater than 25 Lac'>Greater than 25 Lac</MenuItem>
                        </Select>



                      </FormControl> : ''}

                    {employementType == 'Salaried' && companyName == 'Others' ?

                      <FormControl>
                        <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                          Date of birth&nbsp;
                          <span style={{ color: "#FF0000" }}>*</span>
                        </FormLabel>
                        <OutlinedInput
                          type="date"
                          placeholder="Enter tenure"
                          sx={{
                            width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                            '@media (max-width:600px)': {
                              marginBottom: '14px'
                            }
                          }}
                          value={dob}
                          onChange={handleDobChange}
                        />
                        {dobError && <Typography sx={{ color: 'red' }}>{dobError}</Typography>}
                      </FormControl> : ''}







                  </Stack>

                </Box>

                <Stack direction={'row'} spacing={3} justifyContent={'center'}>

                  <Box >

                    <Button variant="contained" color="warning" onClick={reverseStepper}
                      sx={{
                        width: '100%'
                      }}

                    >Previous</Button>

                  </Box>

                  <Box>

                    <Button variant="contained" color="warning" onClick={stepper} sx={{ width: '100%' }}>Next</Button>

                  </Box>

                  <Snackbar
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    autoHideDuration={3500}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <MuiAlert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                      {snackbarMessage}
                    </MuiAlert>
                  </Snackbar>


                </Stack>
              </>
              : ''}


            {step == 3 ?
              <>

                <Box >
                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>
                    {/* <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Product Category&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter minimun amount "
                        inputMode="numeric"
                        sx={{
                          width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px',
                          '@media (max-width:600px)': {
                            marginBottom: '14px'
                          }
                        }}
                        value={productCategory}
                        onChange={(e) => setProductCategory(e.target.value)}

                      />

                    </FormControl>

                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Sourcing Channel&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        placeholder="Enter maximum amount "
                        sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                        value={sourcingChannel}
                        onChange={(e) => setSourcingChannel(e.target.value)}

                      />

                    </FormControl> */}


                    {/* <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Uid&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter tenure"
                        sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                        value={uid}
                        onChange={(e) => setUid(e.target.value)}

                      />

                    </FormControl> */}

                  </Stack>



                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'20px'} flexDirection={['column', 'row']}>



                    <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Residence ownership&nbsp;
                        <span style={{ color: "#FF0000" }}>*</span></FormLabel>
                      <Select
                        sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                        value={residenceOwner}
                        onChange={(e) => setResidenceOwner(e.target.value)}
                      >
                        <MenuItem value='Rented'>Rented</MenuItem>
                        <MenuItem value='Owned'>Owned</MenuItem>
                      </Select>
                    </FormControl>



                  </Stack>



                  <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} mb={'15px'} flexDirection={['column', 'row']}>
                    {/* <FormControl>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>Salary Range</FormLabel>


                      <Select
                        sx={{ width: '300px', height: '57.5px', border: '0.5px solid #6b6b6b', borderRadius: '8px' }}
                        value={salaryRange}
                        onChange={(e) => setSalaryRange(e.target.value)}
                      >
                        <MenuItem value='Less than Rs.3lac'>Less than Rs.3Lac</MenuItem>
                        <MenuItem value='Rs 3 to 5 Lac'>Rs 3 to 5 Lac</MenuItem>
                        <MenuItem value='Rs 5 to 10 Lac'>Rs 5 to 10 Lac</MenuItem>
                        <MenuItem value='Rs 10 to 15 Lac'>Rs 10 to 15 Lac</MenuItem>
                        <MenuItem value='Rs 15 to 20 Lac'>Rs 15 to 20 Lac</MenuItem>
                        <MenuItem value='Greater than 25Lac'>Greater than 25Lac</MenuItem>
                      </Select>

                    </FormControl> */}

                  </Stack>
                  <Stack >

                    <FormControl sx={{ border: '0.5px solid #000', borderRadius: '8px', p: '16px' }}>
                      <FormLabel sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '16px' }}>
                        Required Loan amount
                      </FormLabel>

                      <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'end'}>
                        <MdCurrencyRupee />

                        <Input
                          sx={{
                            width: '20%',
                            '@media (max-width: 600px)': {
                              width: '30%',

                            },
                          }}
                          value={loanAmount}
                          onChange={handleInputChange}
                          inputProps={{
                            min: 50000,
                            max: 1500000,
                            type: 'number',
                          }}
                        />
                      </Stack>

                      <Slider
                        sx={{ width: '100%', height: '8px', color: '#ff9966' }}
                        value={loanAmount}
                        min={50000}
                        max={1500000}
                        step={1000}
                        valueLabelDisplay="auto"
                        aria-label="Loan Amount Range"
                        onChange={handleSliderChange}
                      />
                    </FormControl>

                  </Stack>


                </Box>

                <Stack direction={'row'} mt={'15px'}>
                  <Box>
                    <Checkbox
                      value='true'
                      checked={isCheckboxChecked}
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    />
                  </Box>
                  <Typography sx={{ color: '#6b6b6b', fontWeight: '600', mb: '8px', fontSize: '15px' }}>
                    I hereby agree and allow Loanloom & Partner L&T Finance to fetch and use my KYC, bureau information for my loan application.
                  </Typography>
                </Stack>

                <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                  <Box>
                    <Button variant="contained" color="warning" onClick={reverseStepper} sx={{ width: '100%' }}>
                      Previous
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: '100%' }}
                      onClick={stepper}
                      disabled={!isCheckboxChecked || isLoading}
                    >
                      {isLoading ? <><CircularProgress size={24} color="inherit" sx={{ mr: '10px' }} /><Typography sx={{ fontWeight: '600', fontSize: '15px' }}>Progress...</Typography></> : 'Submit'}
                    </Button>


                    <Snackbar
                      open={snackbarOpen}
                      onClose={handleSnackbarClose}
                      autoHideDuration={3500}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                      </MuiAlert>
                    </Snackbar>

                    {errorMsg && (
                      <Snackbar
                        open={true}
                        autoHideDuration={3500}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      >
                        <MuiAlert onClose={() => setErrorMsg('')} severity="error" sx={{ width: '100%' }}>
                          {errorMsg}
                        </MuiAlert>
                      </Snackbar>
                    )}



                  </Box>
                </Stack>
              </>
              : ''}


            {step === 4 && (
              <>
                <Typography
                  sx={{
                    color: 'green', fontWeight: '700', fontSize: '18px', textAlign: 'center', mb: '20px',

                    '@media (max-width: 600px)': {
                      fontSize: '16px',

                    },
                  }}>{errorDesc}

                </Typography>

                <Box sx={{
                  border: "0.5px solid #000", padding: '20px', borderRadius: '8px',
                  '@media (max-width: 600px)': {
                    fontSize: '14.5px',
                    padding: '20px'

                  },


                }}>

                  <Typography
                    sx={{
                      color: '#6b6b6b', fontWeight: '600', fontSize: '16px',
                      '@media (max-width: 600px)': {
                        fontSize: '12.5px',
                        marginBottom: '10px'

                      },
                    }}

                  >
                    Application Id : {applicationId}

                  </Typography>

                  <Typography sx={{
                    color: '#6b6b6b', fontWeight: '600', fontSize: '16px',
                    '@media (max-width: 600px)': {
                      fontSize: '12.5px',
                      marginBottom: '10px'

                    },
                  }}>Max Eligibility Amount : {maxEligibilityAmount}</Typography>

                  <Typography sx={{
                    color: '#6b6b6b', fontWeight: '600', fontSize: '16px',
                    '@media (max-width: 600px)': {
                      fontSize: '12.5px',
                      fontWeight: '700',
                      marginBottom: '10px'

                    },
                  }}>Los Application Id : {losApplicationId} </Typography>

                  <Typography sx={{
                    color: '#6b6b6b', fontWeight: '600', fontSize: '16px',
                    '@media (max-width: 600px)': {
                      fontSize: '12.5px',
                      marginBottom: '10px'

                    },
                  }}>Max Tenure (Month) : {maxTenure} </Typography>

                  <Typography sx={{
                    color: '#6b6b6b', fontWeight: '600', fontSize: '16px',
                    '@media (max-width: 600px)': {
                      fontSize: '12.5px',
                      marginBottom: '10px'

                    },
                  }}>Min Tenure (Month) : {minTenure}</Typography>


                </Box>

                <Typography sx={{
                  textAlign: 'center', fontSize: '16px', fontWeight: '600', color: 'red', mt: '15px',
                  '@media (max-width: 600px)': {
                    fontSize: '12px',

                  },
                }}>Please retain this information for future reference.</Typography>


              </>
            )}


          </Box>


        </Box>

      </Modal>



    </>
  );
}