  import { useEffect, useState } from "react";
  import axios from "axios";
  import Table from "../Table";
  import "../Table/table.css";
  import Search from "../Search";
  import { Grid } from "@mui/material";
  import Pagination from "../Pagination";
  import "bootstrap/dist/css/bootstrap.css";

  const base_url='https://www.loanloom.com/loan_phpForm/automobile_loan_table_api.php'

  function AutomobileLoanTable() {
    const [obj, setObj] = useState({});
    const [sort, setSort] = useState({
      sort: "interest_rate" || "processing_fee",
      order: "desc",
    });
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");

    useEffect(() => {
      const getAllMovies = async () => {
        try {
          // const url = `${base_url}?page=${page}&sort=${sort.sort},${sort.order}&search=${search}`;
          const url = `${base_url}?page=${page}&search=${search}`;
          const { data } = await axios.get(url);
          setObj(data);
        } catch (err) {
          console.log(err);
        }
      };

      getAllMovies();
    }, [sort, page,search]);
    
    // useEffect(() => {
    //   setPage(1)
    //   const getAllMovies = async () => {
    //     try {
    //       // const url = `${base_url}?page=${page}&sort=${sort.sort},${sort.order}&search=${search}`;
    //       const url = `${base_url}?page=${page}&search=${search}`;
    //       const { data } = await axios.get(url);
    //       setObj(data);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   };

    //   getAllMovies();
    // }, [search]);

  

    return (
      <>
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }} padding={3}>
          <Grid item xs={6}>
            {/* <Sort sort={sort} setSort={(sort) => setSort(sort)} /> */}
          </Grid>
          <Grid item xs={6}>
            <Search setSearch={(search) => setSearch(search)} />
          </Grid>
        </Grid>
        <div className="table_container">
          <Table tableData={obj.tableData ? obj.tableData : []} />
          <Pagination
            page={page}
            limit={obj.limit ? obj.limit : 0}
            total={obj.total ? obj.total : 0}
            setPage={(page) => setPage(page)}
          />
        </div>
      </>
    );
  }

  export default AutomobileLoanTable;
