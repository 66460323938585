
  import React from 'react';
import Grid from '@mui/material/Grid';
import img from "../../../../../Assets/hero-calculator.jpg";
import './Calculator_hero.css';

function Calculator_hero() {
  return (
    <div className='calculator-main'>
      <span className="calciHead">EMI Calculator</span>
      <Grid container padding={2}>
        <Grid item lg={6} xs={12} md={6}>
          <img src={img} className="calculator-img" alt='blimg' />
        </Grid>

        <Grid item lg={6} xs={12} md={6}>
          <h1 className='calculator-title'>A loan for multiple purposes!</h1>
          <p className="calculator-para">
            A loan calculator is a tool used to determine the monthly payment and total interest cost of a loan. This tool can be used to calculate payments for various types of loans, including Home loan, Personal loan, Automobile loan, Business loan, and Education loan.
          </p>
          <p className="calculator-para1">A loan calculator is a valuable resource for anyone who wants to understand the financial implications of taking out a loan.</p>
          <p className="calculator-para1">An EMI loan calculator is a helpful tool that helps you calculate the monthly installment amount for a loan. It takes into account the principal amount, the interest rate, and the loan tenure to calculate the EMI amount. Using an EMI loan calculator can help you plan your loan repayments and ensure that you can pay off your loan on time.</p>
          <a href='#Calculator'>
            <button className='calculator-firstbtn'>Loan Emi Calculator</button>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default Calculator_hero;
