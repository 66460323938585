const HomeSlider = {
    marksAmt: [
      { value: 0, label: "0" },
      { value: 2500000, label: "25L" },
      { value: 5000000, label: "50L" },
      { value: 7500000, label: "75L" },
      { value: 10000000, label: ">100L" },
    ],
    marksTenture: [
      { value: 0, label: "0" },
      { value: 90, label: "90" },
      { value: 180, label: "180" },
      { value: 270, label: "270" },
      { value: 360, label: "360" },
    ],
    marksInt: [
      { value: 0, label: "0" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
  };
  const PersonalLoanSlider= {
    marksAmt: [
      { value: 0, label: "0" },
      { value: 1500000, label: "15L" },
      { value: 3000000, label: "30L" },
      { value: 4500000, label: "45L" },
      { value: 6000000, label: "60L" },
    ],
    marksTenture: [
      { value: 0, label: "0" },
      { value: 90, label: "90" },
      { value: 180, label: "180" },
      { value: 270, label: "270" },
      { value: 360, label: "360" },
    ],
    marksInt: [
      { value: 0, label: "0" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
  };
  const CarLoanSlider= {
    marksAmt: [
      { value: 0, label: "0" },
      { value: 1500000, label: "15L" },
      { value: 3000000, label: "30L" },
      { value: 4500000, label: "45L" },
      { value: 6000000, label: "60L" },
      { value: 6000000, label: "60L" },
    ],
    marksTenture: [
      { value: 0, label: "0" },
      { value: 90, label: "90" },
      { value: 180, label: "180" },
      { value: 270, label: "270" },
      { value: 360, label: "360" },
    ],
    marksInt: [
      { value: 0, label: "0" },
      { value: 5, label: "5" },
      { value: 10, label: "10" },
      { value: 15, label: "15" },
      { value: 20, label: "20" },
    ],
  };
  export {HomeSlider, PersonalLoanSlider ,CarLoanSlider}
  