import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import FooterCol from '../FootercolInfo/FooterCol';
import './Footer.css';
import { usefulLink, ourServices, otherLinks, footerInfo } from '../../../MainPart/FooterData';
import FooterInfo from '../FootercolInfo/FooterInfo';
import Img from '../../../../Assets/logo192.png'

const Footer = () => {
    return (
        <section className='row footer'>
            <Row className="col-md-11 mx-auto">
                <Row className="align-items-center footerInfo">
                    {
                        footerInfo.map(data => <FooterInfo data={data} key={data.id}/>)
                    }
                </Row>
                <Col md={6} lg={3} className="fAboutUs">
                    <h2 className='footertitle'>ABOUT US</h2>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">Loanloom is a financial aggregator that provides you with attractive loan offers according to your needs,
                    </p>
                    <ul className="socialIcons">
                        <li>
                        <a href="https://www.facebook.com/profile.php?id=100090455449775" aria-label="Facebook profile">
  <FontAwesomeIcon icon={faFacebook} style={{ color: '#3b5998', fontSize: "1.5rem" }} />
</a>

                        </li>
                        <li>
                        <a href="https://instagram.com/uponly.technologies?igshid=YmMyMTA2M2Y=" aria-label="Instagram profile">
  <FontAwesomeIcon icon={faInstagram} style={{ color: '#d6249f', fontSize: "1.5rem" }} />
</a>

                        </li>
                        <li>
                        <a href="https://www.linkedin.com/company/uponly-technologies/?viewAsMember=true" aria-label="LinkedIn page">
  <FontAwesomeIcon icon={faLinkedinIn} style={{ color: 'rgb(10,102,194)', fontSize: "1.5rem" }} />
</a>

                        </li>
                    </ul>
                </Col>
                <FooterCol key="2" menuItems={usefulLink} title="USEFUL LINK"/>
                <FooterCol key="3" menuItems={ourServices} title="OUR SERVICES"/>
                <FooterCol key="4" menuItems={otherLinks} title="OTHER LINKS"/>

             
                
            </Row>
        


            <p className="copyRight">Copyright &copy; 2023 <span className="fHighlight"> <img src={Img} alt='footerImg' style={{width:"24px"}} width={24} height={23}/> Loan <span>Loom</span></span>. All rights reserved.</p>
        </section>
    );
};

export default Footer;