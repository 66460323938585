import * as Yup from "yup";
const phoneRegExp =/^[23456789]\d{9,9}$/;
const nameregex = /^[a-zA-Z\\s]+/;

export const contactForm = Yup.object({
  fullName: Yup.string()
    .matches(nameregex, "Invalid ! input")
    .required("Full_Name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Enter a valid phone number")
    .required("Mobile number is required"),
  message: Yup.string().matches(nameregex, "Invalid ! input"),
});
